import { Entitlement } from "@src/api/graphql";
import useCurrentStaff from "./useCurrentStaff";

const useEntitlement = (entitlement: Entitlement) => {
  const staff = useCurrentStaff();
  return staff.staffEntitlements.some(
    (se) => se.isActive && se.entitlement === entitlement,
  );
};
export default useEntitlement;
