import { belowLargeTablet, IconButton } from "@ameelio/ui";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  useMediaQuery as measureScreenWidth,
  Paper,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import { BASE_BACKGROUND_COLOR, MENU_BACKGROUND_COLOR } from "@src/theme";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

const DRAWER_WIDTH = 280;

type LayoutProps = {
  menu: React.ReactNode;
  children: React.ReactNode;
};

function TopMenuLayout({ menu, children }: LayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  // close the menu when the route changes
  const { pathname } = useLocation();
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        width: 1,
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: 1,
          minHeight: theme.spacing(8),
          px: 2,
          backgroundColor: MENU_BACKGROUND_COLOR,
        }}
      >
        <IconButton
          sx={{
            color: common.white,
          }}
          ariaLabel={t("Menu")}
          size="large"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      {menuOpen && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 1,
              width: 1,
              zIndex: 1101,
              backgroundColor: "rgba(255,255,255, 0.6)",
            }}
            onClick={() => setMenuOpen(false)}
          />
          <Paper
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              height: 1,
              borderRadius: 0,
              display: "flex",
              minWidth: DRAWER_WIDTH,
              flexDirection: "column",
              maxHeight: "100%",
              overflow: "auto",
              backgroundColor: MENU_BACKGROUND_COLOR,
              zIndex: "modal",
            }}
          >
            {menu}
          </Paper>
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: 1,
          maxWidth: "100%",
          backgroundColor: BASE_BACKGROUND_COLOR,
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

function SideMenuLayout({ menu, children }: LayoutProps) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: 0,
        height: 1,
        backgroundColor: BASE_BACKGROUND_COLOR,
      }}
    >
      <Paper
        sx={{
          minWidth: DRAWER_WIDTH,
          maxWidth: DRAWER_WIDTH,
          display: "flex",
          flexDirection: "column",
          height: "100h",
          minHeight: "100vh",
          maxHeight: "100%",
          overflow: "auto",
          borderRadius: 0,
          backgroundColor: MENU_BACKGROUND_COLOR,
        }}
      >
        {menu}
      </Paper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
      >
        {children || <Outlet />}
      </Box>
    </Box>
  );
}

export default function MenuLayout({ menu, children }: LayoutProps) {
  const isMobileOrSmallTablet = belowLargeTablet(measureScreenWidth);

  if (isMobileOrSmallTablet) {
    return <TopMenuLayout menu={menu}>{children}</TopMenuLayout>;
  }

  return <SideMenuLayout menu={menu}>{children}</SideMenuLayout>;
}
