import { useSnackbarContext } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { System } from "@src/api/graphql";
import { useAuthContext } from "@src/AuthProvider";
import { useFacilityContext } from "@src/FacilityProvider";
import {
  CurrentStaffDocument,
  CurrentStaffQuery,
} from "@src/graphql/CurrentStaff.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { getSelectedFacility } from "@src/lib/selectedFacility";
import { Facility } from "@src/typings/Facility";
import { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import PageLoader from "./PageLoader";

type Props = {
  children: JSX.Element;
};

// This will return false for visitors and inactive staff
function isActiveStaff(user: CurrentStaffQuery["currentStaff"]) {
  if (!user) return false;
  return user.staffPositions.length > 0;
}

// This will return false for visitors only
function isSupportedUser(user: CurrentStaffQuery["currentStaff"]) {
  if (!user) return false;
  return user.__typename === "Staff";
}

export type GuaranteedFacilityContextType = {
  facility: Facility;
  system: Pick<
    System,
    "id" | "recordingRetention" | "code" | "rosterUpdatedAt"
  >;
  selectFacility: (facility: Facility) => void;
};

export const GuaranteedFacilityContext =
  createContext<GuaranteedFacilityContextType>(
    {} as GuaranteedFacilityContextType,
  );

export function useGuaranteedFacilityContext() {
  return useContext(GuaranteedFacilityContext);
}

export default function SessionBoundary({ children }: Props) {
  const { pathname } = useLocation();
  const { selectFacility, facility } = useFacilityContext();
  const { isLoggedIn } = useAuthContext();
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();

  const { data, loading } = useQuery(CurrentStaffDocument, {
    skip: !isLoggedIn,
    onError: handleApolloError,
  });

  useEffect(() => {
    if (!data?.currentStaff) return;
    if (facility) return;
    if (!isActiveStaff(data.currentStaff)) return;

    // recover facility from session storage. this is likely a tab refresh
    // and not a login.
    const storedFacilityId = getSelectedFacility();
    if (storedFacilityId) {
      const matchingPosition = data.currentStaff.staffPositions.find(
        (p) => p.facility.id === storedFacilityId,
      );
      if (matchingPosition) selectFacility(matchingPosition.facility);
      return;
    }

    selectFacility(data.currentStaff.staffPositions[0].facility);
    snackbarContext.alert(
      "success",
      t("Welcome back, {{name}}!", { name: data.currentStaff.firstName }),
    );
  }, [data, facility, selectFacility, snackbarContext, t]);

  if (!isLoggedIn) {
    return <Navigate to={{ pathname: "/login" }} state={{ from: pathname }} />;
  }
  if (loading || !data) {
    return (
      <Box style={{ height: "100vh" }}>
        <PageLoader />
      </Box>
    );
  }
  if (!isActiveStaff(data.currentStaff)) {
    snackbarContext.alert(
      "error",
      !isSupportedUser(data.currentStaff)
        ? t("Your account is not supported by this application")
        : t("This account has no active staff positions"),
    );
    return <Navigate to={{ pathname: "/logout" }} />;
  }
  if (!facility) {
    return (
      <Box style={{ height: "100vh" }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <GuaranteedFacilityContext.Provider
      value={{ selectFacility, facility, system: facility.system }}
    >
      {children}
    </GuaranteedFacilityContext.Provider>
  );
}
