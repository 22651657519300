import { Address } from "@src/api/graphql";

export type AddressProps = {
  address: Pick<
    Address,
    "lineOne" | "lineTwo" | "city" | "province" | "country" | "postalCode"
  >;
};

export function addressLastLine(
  address: Pick<Address, "city" | "province" | "country" | "postalCode">,
) {
  return `${address.city}, ${address.province}, ${address.postalCode}`;
}

export default function AddressLine({ address }: AddressProps) {
  return (
    <>
      {address.lineOne}
      {", "}
      {address.lineTwo && (
        <>
          {address.lineTwo}
          {", "}
        </>
      )}
      {addressLastLine(address)}
    </>
  );
}
