import { Button, ButtonProps, useSnackbarContext } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Call,
  Meeting,
  MeetingStatus,
  Recording,
  RecordingStatus,
  Staff,
} from "@src/api/graphql";
import { CreateRecordingDocument } from "@src/graphql/CreateRecording.generated";
import ContextMenuItem from "@src/lib/ContextMenuItem";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import ViewRecordsButton from "@src/lib/ViewRecordsButton";
import { useTranslation } from "react-i18next";

type Props = ButtonProps & {
  showView?: boolean;
  displayAsMenuItem?: boolean;
  meeting: Pick<Meeting, "id" | "status" | "privacyLevel" | "meetingType"> & {
    call?:
      | (Pick<Call, "id" | "screenshotsExpireAt" | "artifactsExpireAt"> & {
          recording?:
            | (Pick<Recording, "url" | "status"> & {
                requester?: Pick<Staff, "id" | "fullName"> | null;
              })
            | null;
        })
      | null;
  };
};

export default function RequestRecordingButton({
  meeting,
  displayAsMenuItem,
  showView,
  ...rest
}: Props) {
  const user = useCurrentStaff();
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const handleApolloError = useApolloErrorHandler();

  const [submitRequestMutation] = useMutation(CreateRecordingDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert(
        "success",
        t(
          "Your request for a recording has been received. We will email you when it's ready for viewing, usually within 48 hours.",
        ),
      );
    },
  });

  async function submitRequest() {
    await submitRequestMutation({
      variables: {
        input: {
          meetingId: meeting.id,
        },
      },
    });
  }

  const ready =
    meeting.call?.recording &&
    meeting.call.recording.status &&
    [RecordingStatus.Available].includes(meeting.call.recording.status);

  if (ready && showView) {
    // This button is not needed if the recording is already available
    // We're going to show the View Records button instead
    return (
      <ViewRecordsButton displayAsMenuItem meeting={meeting} size="small" />
    );
  }

  const now = new Date().getTime();

  let disabledReason = "";

  if (!meeting.call || [MeetingStatus.Cancelled].includes(meeting.status)) {
    disabledReason = t("This meeting never took place.");
  } else if (
    meeting.call.artifactsExpireAt &&
    meeting.call.artifactsExpireAt <= now
  ) {
    disabledReason = t(
      "This recording is no longer available. All recordings expire {{days}} days after the meeting date.",
      { days: facility.system.recordingRetention },
    );
  } else if (
    meeting.call.recording &&
    meeting.call.recording.status !== RecordingStatus.NotStarted &&
    meeting.call.recording.requester
  ) {
    const firstRequester = meeting.call.recording.requester;
    if (firstRequester.id === user.id) {
      disabledReason = t("You will receive an email once ready to view.");
    } else {
      disabledReason = t(
        "{{requesterName}} requested this recording. You'll be able to view it shortly.",
        {
          requesterName: firstRequester.fullName,
        },
      );
    }
  }
  return displayAsMenuItem ? (
    <ContextMenuItem
      key="request-recording"
      onClick={submitRequest}
      disabled={Boolean(disabledReason) || rest.disabled}
      disabledReason={disabledReason}
    >
      {t("Request recording")}
    </ContextMenuItem>
  ) : (
    <Button
      variant="outlined"
      onClick={submitRequest}
      disabled={Boolean(disabledReason) || rest.disabled}
      disabledReason={disabledReason}
      {...rest}
    >
      {t("Request recording")}
    </Button>
  );
}
