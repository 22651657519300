import {
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";

export default function mayHaveScreenshots(
  meeting: Pick<Meeting, "meetingType" | "privacyLevel" | "status">,
) {
  return (
    meeting.privacyLevel === PrivacyLevel.UnmonitoredLogged &&
    meeting.meetingType === MeetingType.VideoCall &&
    [
      MeetingStatus.Ended,
      MeetingStatus.Terminated,
      MeetingStatus.NoShow,
    ].includes(meeting.status)
  );
}
