import { sortByNumbers } from "@ameelio/core";
import * as gql from "@src/api/graphql";
import { Group, Inmate } from "@src/api/graphql";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import statusDetailsLabel from "@src/lib/statusDetailsLabel";
import i18n from "i18next";
import { getFullNameWithId } from "./Common";
import { labelMeetingType } from "./meeting";
import { formatDate, formatTimeRange } from "./Time";

export function mondayMorning(date: Date): Date {
  const day = date.getDay();
  date.setDate(date.getDate() - day + 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export type CSVMeeting = Pick<
  gql.Meeting,
  | "id"
  | "statusDetails"
  | "notes"
  | "meetingType"
  | "unregisteredGuests"
  | "status"
> & {
  primaryVisitor?: Pick<gql.Visitor, "id" | "email"> | null;
  kiosk?: Pick<gql.Kiosk, "name"> | null;
  interval: Pick<gql.Interval, "startAt" | "endAt">;
  visitors: Pick<gql.Visitor, "id" | "email" | "phone" | "fullName">[];
  inmates: (Pick<gql.Inmate, "id" | "inmateIdentification" | "fullName"> & {
    group: Pick<gql.Group, "name">;
  })[];
};

export enum ExportableField {
  resident = "resident",
  group = "group",
  kiosk = "kiosk",
  date = "date",
  time = "time",
  meetingType = "meetingType",
  meetingNotes = "meetingNotes",
  visitors = "visitors",
  minors = "minors",
  primaryVisitorEmail = "primaryVisitorEmail",
  status = "status",
  statusDetails = "statusDetails",
}

export function exportableFieldLabel(field: ExportableField) {
  const reportFieldLabels: Record<ExportableField, string> = {
    resident: i18n.t("Resident"),
    group: i18n.t("Group"),
    kiosk: i18n.t("Kiosk"),
    date: i18n.t("Date"),
    time: i18n.t("Time"),
    meetingType: i18n.t("Meeting Type"),
    meetingNotes: i18n.t("Meeting Notes"),
    visitors: i18n.t("Visitors"),
    minors: i18n.t("Minors"),
    primaryVisitorEmail: i18n.t("Primary Visitor Email"),
    status: i18n.t("Status"),
    statusDetails: i18n.t("Status Details"),
  };
  return reportFieldLabels[field] || field;
}

export const BASE_REPORT_FIELDS = [
  ExportableField.resident,
  ExportableField.group,
  ExportableField.kiosk,
  ExportableField.date,
  ExportableField.time,
  ExportableField.meetingType,
  // ExportableField.primaryVisitorEmail,
];

export const BASE_HISTORY_FIELDS = [
  ExportableField.date,
  ExportableField.time,
  ExportableField.resident,
  ExportableField.kiosk,
  ExportableField.visitors,
  ExportableField.meetingType,
  // ExportableField.primaryVisitorEmail,
  ExportableField.status,
  ExportableField.statusDetails,
];

function getFieldValue(
  field: ExportableField,
  meeting: CSVMeeting,
  i: Pick<Inmate, "id" | "fullName" | "inmateIdentification"> & {
    group: Pick<Group, "name">;
  },
) {
  switch (field) {
    case ExportableField.resident:
      return getFullNameWithId(i);
    case ExportableField.group:
      return i.group.name || "";
    case ExportableField.kiosk:
      return meeting.kiosk?.name || "";
    case ExportableField.date:
      return formatDate(new Date(meeting.interval.startAt), "date");
    case ExportableField.time:
      return formatTimeRange(meeting.interval.startAt, meeting.interval.endAt);
    case ExportableField.meetingType:
      return labelMeetingType(meeting.meetingType);
    case ExportableField.meetingNotes:
      return meeting.notes || meeting.statusDetails || "";
    case ExportableField.visitors:
      return meeting.visitors.map((v) => v.fullName).join(", ");
    case ExportableField.minors:
      return meeting.unregisteredGuests?.join(", ") || "";
    case ExportableField.primaryVisitorEmail:
      return meeting.primaryVisitor?.email || "";
    case ExportableField.status:
      return getMeetingStatusLabelByType(meeting.status);
    case ExportableField.statusDetails:
      return meeting.statusDetails
        ? statusDetailsLabel(meeting.statusDetails)
        : "";
    default:
      return "";
  }
}

// should match: ameelio-api:src/services/sendFacilitySchedules.ts
export const meetingsToCsvLogs = (
  meetings: CSVMeeting[],
  fields: ExportableField[],
) => {
  return sortByNumbers(meetings, (m) => m.interval.startAt).flatMap(
    (meeting) => {
      return meeting.inmates.map((i) =>
        fields.reduce<{ [key in ExportableField]?: string }>(
          (baseLogs, field) => {
            baseLogs[field] = getFieldValue(field, meeting, i);
            return baseLogs;
          },
          {},
        ),
      );
    },
  );
};

export function getFullNames(
  people: {
    fullName: string;
    inmateIdentification?: string;
  }[],
) {
  return people.map((p) => p.fullName).join(", ");
}

export function getFirstNames(people: { firstName: string }[]) {
  return people.map((person) => person.firstName).join(", ");
}
