import {
  Connection,
  Message as GqlMessage,
  Inmate,
  MessageDetectedKeyword,
  MessageKeyword,
  MessageStatus,
  StaffPosition,
  Visitor,
} from "@src/api/graphql";
import { format } from "date-fns";
import i18n from "i18next";
import { capitalize } from "./Common";

export type Message = Pick<
  GqlMessage,
  | "id"
  | "type"
  | "status"
  | "statusDetails"
  | "internalComments"
  | "createdAt"
  | "availableAt"
  | "reviewedAt"
  | "fileUrl"
  | "content"
> & {
  connection: Pick<Connection, "id"> & {
    inmate: Pick<Inmate, "id" | "fullName">;
    visitor: Pick<Visitor, "id" | "fullName">;
  };
  detectedKeywords: (Pick<MessageDetectedKeyword, "id" | "spelling"> & {
    keyword: Pick<
      MessageKeyword,
      | "id"
      | "name"
      | "description"
      | "spellings"
      | "category"
      | "isGlobal"
      | "isActive"
      | "updatedAt"
      | "createdAt"
    > & {
      createdBy?:
        | null
        | (Pick<StaffPosition, "id"> & {
            staff: Pick<Staff, "id" | "fullName">;
          });
      lastUpdatedBy?:
        | null
        | (Pick<StaffPosition, "id"> & {
            staff: Pick<Staff, "id" | "fullName">;
          });
    };
  })[];
  reviewedBy?:
    | null
    | (Pick<StaffPosition, "id"> & {
        staff: Pick<Staff, "id" | "fullName">;
      });
  sender:
    | Pick<Inmate, "__typename" | "id" | "inmateIdentification" | "fullName">
    | Pick<Visitor, "__typename" | "id" | "fullName">;
};

const formatMessageTimestamp = (message: Message) => {
  let timestamp = `Sent ${format(
    new Date(message.createdAt),
    "MM/dd/yy H:mm",
  )}`;

  if (message.availableAt) {
    timestamp += `\nDelivered ${format(
      new Date(message.availableAt),
      "MM/dd/yy H:mm",
    )}`;
  } else {
    timestamp += `\nNot delivered`;
  }
  return timestamp;
};

const formatMessageContent = (message: Message) => {
  let messageContent = `${
    message.sender.__typename === "Inmate"
      ? message.sender.inmateIdentification
      : ""
  } ${message.sender.fullName.toUpperCase()}\n`;

  if (message.status === MessageStatus.Queued) {
    messageContent += "***DELAYED DELIVERY***";
  } else if (
    [MessageStatus.PendingReview, MessageStatus.Rejected].includes(
      message.status,
    )
  ) {
    messageContent += `***${message.status.toUpperCase().replace("_", " ")}***`;
  }
  messageContent += message.content;
  return messageContent;
};

const formatMessageDetails = (message: Message) => {
  let details = "";
  if (message.detectedKeywords.length) {
    details += `Keyword(s): ${message.detectedKeywords
      .map((detectedKeyword) => detectedKeyword.keyword.name)
      .join(", ")}`;
  }
  if (
    ![MessageStatus.SkippedReview, MessageStatus.Queued].includes(
      message.status,
    )
  ) {
    details += `\nStatus: ${capitalize(
      message.status.toLowerCase().replace("_", " "),
    )}`;
  }
  if (message.reviewedBy && message.reviewedAt) {
    details += ` by ${message.reviewedBy.staff.fullName} ${format(
      new Date(message.reviewedAt),
      "MM/dd/yy H:mm",
    )}`;
  }
  if (message.statusDetails) {
    details += `\nMessage to sender: ${message.statusDetails}`;
  }
  if (message.internalComments) {
    details += `\nInternal comments: ${message.internalComments}`;
  }
  if (details) return details;
  return "-";
};

export const messagesToCsv = (messages: Message[]) => {
  return messages.map((message) => {
    return {
      Timestamp: formatMessageTimestamp(message),
      Message: formatMessageContent(message),
      "Details (if flagged for keyword review)": formatMessageDetails(message),
    };
  });
};

export function getMessageStatusColor(status: MessageStatus) {
  switch (status) {
    case MessageStatus.Approved:
      return "primary.main";
    case MessageStatus.PendingReview:
    case MessageStatus.Rejected:
      return "error.dark";
    default:
      return "text.secondary";
  }
}

export function getMessageStatusLabel(status: MessageStatus) {
  switch (status) {
    case MessageStatus.Approved:
      return i18n.t("Approved");
    case MessageStatus.PendingReview:
      return i18n.t("Review Required");
    case MessageStatus.Queued:
      return i18n.t("Delayed Delivery");
    case MessageStatus.Rejected:
      return i18n.t("Rejected");
    case MessageStatus.SkippedReview:
      return "";
  }
}

export function getMessageSenderType(message: Message): "inmate" | "visitor" {
  return message.sender.__typename === "Visitor" ? "visitor" : "inmate";
}
