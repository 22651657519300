import { Stack, Typography } from "@mui/material";

export function TranscriptSegment({
  segment,
  query,
}: {
  segment: string;
  query?: string;
}) {
  return (
    <Typography variant="body2" color="text.primary">
      {segment.split(" ").map((word, idx) => (
        <span key={`${word}_${idx}`}>
          <span
            style={
              query?.length && query.split(" ").find((term) => term === word)
                ? {
                    backgroundColor: "#FFFDBA",
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {word}
          </span>{" "}
        </span>
      ))}
    </Typography>
  );
}

export default function TranscriptPreview({
  segments,
  query,
}: {
  segments: string[];
  query?: string;
}) {
  const firstSegmentQueryMatch = segments.findIndex((segment: string) =>
    segment
      .split(" ")
      .find(
        (word: string) =>
          query?.length && query.split(" ").find((term) => term === word),
      ),
  );
  // Used to center the segment containing the first "match" to the query
  // in the preview cell in the table row
  const previewSegments =
    firstSegmentQueryMatch > 0
      ? segments.slice(firstSegmentQueryMatch - 1, firstSegmentQueryMatch + 2) // center it
      : segments.slice(0, 3);
  return (
    <Stack spacing={1}>
      {previewSegments.map((segment, idx) => (
        <TranscriptSegment
          key={`${segment}_${idx}`}
          segment={segment}
          query={query}
        />
      ))}
    </Stack>
  );
}
