import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeactivateLocalMessageKeywordMutationVariables = Types.Exact<{
  input: Types.DeactivateLocalMessageKeywordInput;
}>;


export type DeactivateLocalMessageKeywordMutation = { __typename: 'Mutation', deactivateLocalMessageKeyword: { __typename: 'DeactivateLocalMessageKeywordResponse', messageKeyword: { __typename: 'MessageKeyword', id: Types.Scalars['ID']['input'], isActive: boolean } } };


export const DeactivateLocalMessageKeywordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeactivateLocalMessageKeyword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeactivateLocalMessageKeywordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deactivateLocalMessageKeyword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageKeyword"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}}]}}]}}]} as unknown as DocumentNode<DeactivateLocalMessageKeywordMutation, DeactivateLocalMessageKeywordMutationVariables>;