import { useEffect } from "react";

/**
 * useMountedPolling will only poll while the hook is mounted. this is in
 * contrast to useQuery's pollInterval behavior, which can't be stopped by
 * unmounting, setting `skip` to true, or calling `stopPolling`!
 */
export default function useMountedPolling(
  pollInterval: number | undefined,
  startPolling: (n: number) => void,
  stopPolling: () => void,
) {
  useEffect(() => {
    if (!pollInterval) return;
    startPolling(pollInterval);
    return () => {
      stopPolling();
    };
  }, [pollInterval, startPolling, stopPolling]);
}
