import {
  DialogProps as BaseProps,
  Dialog,
  DialogActions,
  DialogContent,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Button, List, ListItem, Typography } from "@mui/material";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import { BaseSkeleton } from "@src/lib/closet";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useTranslation } from "react-i18next";
import { UpdateKioskDocument } from "./UpdateKiosk.generated";

type Props = Omit<BaseProps, "open" | "onSubmit" | "title"> & {
  kiosk: {
    id: string;
    name: string;
    enabled: boolean;
  };
  onClose: () => void;
};

export default function DisableEnableKioskModal({ kiosk, onClose }: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const [updateKiosk] = useMutation(UpdateKioskDocument, {
    onError: handleApolloError,
    variables: {
      input: {
        kioskId: kiosk.id,
        enabled: !kiosk.enabled,
      },
    },
  });

  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const affectedSchedules = data
    ? data.facility.schedules.filter((schedule) =>
        schedule.kiosks.some((k) => k.id === kiosk.id),
      )
    : null;

  return (
    <Dialog
      title={
        kiosk.enabled
          ? t("Are you sure you want to disable {{kioskName}}?", {
              kioskName: kiosk.name || t("Kiosk"),
            })
          : t("Are you sure you want to enable {{kioskName}}?", {
              kioskName: kiosk.name || t("Kiosk"),
            })
      }
      onClose={onClose}
      fullWidth
    >
      <DialogContent>
        <Typography>
          {kiosk.enabled
            ? t(
                "Video calls that were scheduled on this computer will be cancelled if this is not reactivated the morning of those meetings.",
              )
            : t(
                "People will be able to immediately book meetings with this kiosk if it is available in a schedule.",
              )}
        </Typography>
        <Typography sx={{ pt: 1 }}>
          {t("The schedules that will be impacted by this change are:")}
          <List sx={{ listStyleType: "disc", pl: 3 }}>
            {affectedSchedules ? (
              affectedSchedules.length === 0 ? (
                <ListItem
                  key={"schedule-none"}
                  sx={{ display: "list-item", p: 0 }}
                >
                  {t("No affected schedules")}
                </ListItem>
              ) : (
                affectedSchedules.map((schedule) => (
                  <ListItem
                    key={schedule.id}
                    sx={{ display: "list-item", p: 0 }}
                  >
                    {schedule.name}
                  </ListItem>
                ))
              )
            ) : (
              <ListItem sx={{ display: "list-item", p: 0 }}>
                <BaseSkeleton />
              </ListItem>
            )}
          </List>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => updateKiosk().then(onClose)}
          color={kiosk.enabled ? "error" : "primary"}
        >
          {kiosk.enabled ? t("Yes, disable kiosk") : t("Yes, enable kiosk")}
        </Button>
        <Button autoFocus variant="contained" onClick={onClose}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
