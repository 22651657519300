import { InlineBadge, Media } from "@ameelio/ui";
import ChevronRight from "@mui/icons-material/ChevronRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Link, { LinkProps } from "@mui/material/Link";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { MENU_BACKGROUND_COLOR, MENU_SECONDARY_COLOR } from "@src/theme";
import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MenuTab, Path } from "../pages/routes";

export { MenuList };

type MenuEntryProps = LinkProps & {
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  selected?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  path?: Path;
  submenu?: boolean;
  children: React.ReactNode;
};

const MenuEntry = React.forwardRef<HTMLAnchorElement, MenuEntryProps>(
  (
    {
      icon,
      selectedIcon,
      selected,
      hovered,
      disabled,
      path,
      children,
      submenu,
      ...linkProps
    },
    ref,
  ) => {
    const routingProps =
      path && !disabled
        ? {
            component: RouterLink,
            to: path,
          }
        : {};

    const color = disabled
      ? "rgba(255,255,255,0.5)" // dark.text.disabled
      : "common.white";

    return (
      <Link
        {...routingProps}
        {...linkProps}
        variant="body1"
        color={color}
        underline="none"
        py={1.5}
        pl={2}
        pr={1}
        sx={{
          display: "block",
          backgroundColor: selected
            ? "primary.main"
            : hovered
              ? "rgba(255,255,255,0.08)" // dark.action.hover
              : submenu
                ? MENU_SECONDARY_COLOR
                : undefined,
          "&:hover": {
            color,
            backgroundColor: selected ? undefined : "rgba(255,255,255,0.08)", // dark.action.hover
          },
          ...(submenu ? { pl: 4, pr: 2 } : {}),
        }}
        ref={ref}
      >
        <Media image={selected ? selectedIcon : icon} gap={1}>
          {children}
        </Media>
      </Link>
    );
  },
);
MenuEntry.displayName = "MenuEntry";

export const MenuLink = function ({
  tab,
  selectedTab,
  disabledReason,
  badgeCount,
  children,
  submenu,
  subtitle,
  ...rest
}: Omit<MenuEntryProps, "selected" | "disabled"> & {
  tab: MenuTab;
  selectedTab: MenuTab;
  disabledReason?: string;
  badgeCount?: number;
  submenu?: boolean;
  subtitle?: string;
}) {
  return (
    <Tooltip title={disabledReason} placement="right" arrow>
      <MenuEntry
        {...rest}
        selected={tab === selectedTab}
        disabled={!!disabledReason}
        tabIndex={0}
        submenu={submenu}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              variant="body1"
              color={
                disabledReason
                  ? "rgba(255,255,255,0.5)" // dark.text.disabled
                  : "common.white"
              }
              sx={{ flex: 1 }}
            >
              {children}
            </Typography>
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </Box>
          <InlineBadge
            badgeContent={badgeCount}
            max={9}
            color="error"
            inverted
          />
        </Box>
      </MenuEntry>
    </Tooltip>
  );
};

export function MenuGroup({
  containedTabs,
  selectedTab,
  icon,
  selectedIcon,
  label,
  badgeCount,
  children,
}: {
  containedTabs: MenuTab[];
  selectedTab: MenuTab;
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  label: string;
  badgeCount?: number;
  children: React.ReactNode;
}) {
  const openByContainer = containedTabs.includes(selectedTab);
  // Using openByContainer as the initial status will automatically open the menu on a page refresh.

  const [openByClick, setOpenByClick] = useState(openByContainer);
  const openMenu = useCallback(() => setOpenByClick(true), []);
  const closeMenu = useCallback(() => setOpenByClick(false), []);

  /*
    Why are we doing this? We need to close the menu if someone clicks on
    a MenuLink on a different Menu Group.
    Ideally we'd need to pass the containedTabs array to the useEffect,
    but it gets constantly recreated. A solution, proposed by Dan Abramov himself,
    would be to use JSON.stringify (in rare cases, but this is a rare case).
    I'd go with join and then split back inside.
    https://github.com/facebook/react/issues/14476#issuecomment-471199055
   */
  const tabsStr = containedTabs.join(";;");
  useEffect(() => {
    tabsStr.split(";;").includes(selectedTab) ? openMenu() : closeMenu();
  }, [selectedTab, tabsStr, openMenu, closeMenu]);

  return (
    <>
      <MenuEntry
        icon={icon}
        selectedIcon={selectedIcon}
        selected={openByClick ? false : containedTabs.includes(selectedTab)}
        tabIndex={0}
        onClick={openByClick ? closeMenu : openMenu}
        href="#"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>{label}</Box>
          <InlineBadge badgeContent={badgeCount} max={9} inverted />
          {openByClick ? (
            <KeyboardArrowDown fontSize="small" style={{ marginLeft: 1 }} />
          ) : (
            <ChevronRight fontSize="small" style={{ marginLeft: 1 }} />
          )}
        </Box>
      </MenuEntry>
      {openByClick ? children : null}
    </>
  );
}

export function MenuPaper({ children }: { children: React.ReactNode }) {
  return (
    <Paper
      className="hide-for-print"
      sx={{
        minWidth: 280,
        maxWidth: 280,
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        overflow: "auto",
        backgroundColor: MENU_BACKGROUND_COLOR,
        borderRadius: 0,
      }}
    >
      {children}
    </Paper>
  );
}
