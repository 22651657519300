import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateConnectionRestrictionMutationVariables = Types.Exact<{
  input: Types.UpdateConnectionRestrictionInput;
}>;


export type UpdateConnectionRestrictionMutation = { __typename: 'Mutation', updateConnectionRestriction: { __typename: 'UpdateConnectionRestrictionResponse', connectionRestriction: { __typename: 'ConnectionRestriction', id: Types.Scalars['ID']['input'], service: Types.FacilityService, createdAt: Types.Scalars['Date']['input'], expiresAt: Types.Scalars['Date']['input'] | null, reason: string, isActive: boolean } } };


export const UpdateConnectionRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateConnectionRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConnectionRestrictionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateConnectionRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionRestrictionFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConnectionRestrictionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConnectionRestriction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"service"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}}]}}]} as unknown as DocumentNode<UpdateConnectionRestrictionMutation, UpdateConnectionRestrictionMutationVariables>;