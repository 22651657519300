import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  Tooltip,
  usePagination,
} from "@ameelio/ui";
import { Stack, Tab, Tabs } from "@mui/material";
import {
  Entitlement,
  Facility,
  Staff,
  StaffEntitlement,
} from "@src/api/graphql";
import entitlementLabel from "@src/lib/entitlementLabel";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditStaffModal from "./AddEditStaffModal";

type StaffRecord = Pick<
  Staff,
  "id" | "firstName" | "lastName" | "fullName" | "email" | "phone"
> & {
  staffEntitlements: Pick<StaffEntitlement, "entitlement">[];
  staffPositions: {
    facility: Pick<Facility, "id" | "publicId">;
  }[];
};

type Props = {
  staff: StaffRecord[];
  deactivatedStaff: StaffRecord[];
  loading: boolean;
  filters: Record<string, unknown>;
};

function ActualStaffTable({
  records,
  onEdit,
  ariaLabel,
}: {
  records: StaffRecord[];
  onEdit: (record: StaffRecord) => void;
  ariaLabel: string;
}) {
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const canManageStaff = useEntitlement(Entitlement.ManageStaff);

  return (
    <Table aria-label={ariaLabel}>
      <TableHead>
        <TableRow>
          <TableCell>{t("Name")}</TableCell>
          <TableCell>{t("Email")}</TableCell>
          <TableCell>{t("Facilities")}</TableCell>
          <TableCell>{t("Entitlements")}</TableCell>
          <TableCell>{t("Actions")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((staff) => {
          const renderedEntitlements =
            staff.staffEntitlements.length > 3
              ? [
                  ...staff.staffEntitlements
                    .slice(0, 2)
                    .map((se) => entitlementLabel(se.entitlement)),
                  `+${staff.staffEntitlements.length - 2} more`,
                ]
              : staff.staffEntitlements.map((se) =>
                  entitlementLabel(se.entitlement),
                );
          return (
            <TableRow key={staff.id}>
              <TableCell>{staff.fullName}</TableCell>
              <TableCell>{staff.email}</TableCell>
              <TableCell>
                {staff.staffPositions.map((sp) => (
                  <Chip
                    style={{ margin: 3 }}
                    color="grey"
                    key={sp.facility.id}
                    label={sp.facility.publicId}
                  />
                ))}
              </TableCell>
              <TableCell>
                {renderedEntitlements.map((re, i) => (
                  <Tooltip
                    key={`${staff.id}_${re}`}
                    title={
                      // This tooltip appears on the "+X more" chip if there are >3 entitlements.
                      staff.staffEntitlements.length > 3 && i > 1
                        ? staff.staffEntitlements
                            .slice(2)
                            .map((se) => entitlementLabel(se.entitlement))
                            .join(", ")
                        : null
                    }
                  >
                    <div>
                      <Chip color="grey" style={{ margin: 3 }} label={re} />
                    </div>
                  </Tooltip>
                ))}
              </TableCell>
              <TableCell>
                <Stack direction="row" gap={2}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/system/staff/${staff.id}`)}
                  >
                    {t("View")}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={!canManageStaff}
                    disabledReason={t(
                      "You do not have permission to perform this function.",
                    )}
                    onClick={() => onEdit(staff)}
                  >
                    {t("Edit")}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default function StaffTable({
  staff,
  deactivatedStaff,
  loading,
  filters,
}: Props) {
  const { system } = useGuaranteedFacilityContext();

  const { t } = useTranslation();
  const [editingStaff, setEditingStaff] = useState<StaffRecord>();

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: staff,
    resetOnChange: [filters],
  });

  const {
    currentPageData: deactivatedStaffPageData,
    page: deactivatedStaffPage,
    rowsPerPage: deactivatedStaffRowsPerPage,
    onPageChange: onDeactivatedStaffPageChange,
  } = usePagination({
    data: deactivatedStaff,
    resetOnChange: [filters],
  });

  const [activeTab, setActiveTab] = useState<"active" | "deactivated">(
    "active",
  );

  if (loading) return <PageLoader />;

  return (
    <>
      {!!editingStaff && (
        <AddEditStaffModal
          onClose={() => setEditingStaff(undefined)}
          systemId={system.id}
          staff={editingStaff}
        />
      )}
      <Tabs
        value={activeTab}
        onChange={(_, newValue: typeof activeTab) => setActiveTab(newValue)}
        aria-label={t("System tabs")}
        sx={{ mb: 4 }}
      >
        <Tab label={t("Active staff")} value="active" />
        <Tab label={t("Deactivated staff")} value="deactivated" />
      </Tabs>
      {activeTab === "active" && (
        <TableContainer>
          {staff.length === 0 ? (
            <Table>
              <TableNoData />
            </Table>
          ) : (
            <ActualStaffTable
              ariaLabel={t("System staff table")}
              records={currentPageData}
              onEdit={(staff) => setEditingStaff(staff)}
            />
          )}
          <TablePagination
            key="filteredStaff-pagination"
            totalCount={staff.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </TableContainer>
      )}
      {activeTab === "deactivated" && (
        <TableContainer>
          {deactivatedStaff.length === 0 ? (
            <Table>
              <TableNoData />
            </Table>
          ) : (
            <ActualStaffTable
              ariaLabel={t("Deactivated system staff table")}
              records={deactivatedStaffPageData}
              onEdit={(staff) => setEditingStaff(staff)}
            />
          )}
          <TablePagination
            key="deactivatedStaff-pagination"
            totalCount={deactivatedStaff.length}
            rowsPerPage={deactivatedStaffRowsPerPage}
            page={deactivatedStaffPage}
            onPageChange={onDeactivatedStaffPageChange}
          />
        </TableContainer>
      )}
    </>
  );
}
