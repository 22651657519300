import { memoryStorage, Storage } from "./authToken";

const KEY = "selectedFacility";

// if the browser does not allow access to sessionStorage
// then degrade to javascript memory storage
let storage: Storage;
try {
  sessionStorage.getItem("test");
  storage = sessionStorage;
} catch {
  storage = memoryStorage();
}

export const getSelectedFacility = (): string => storage.getItem(KEY) || "";

export const storeSelectedFacility = (value: string | undefined) => {
  value ? storage.setItem(KEY, value) : storage.removeItem(KEY);
};
