import { ContextMenu } from "@ameelio/ui";
import { Block } from "@mui/icons-material";
import { Box, Card, MenuItem, Typography } from "@mui/material";
import {
  ConnectionRestriction,
  Entitlement,
  Staff,
  StaffPosition,
} from "@src/api/graphql";
import facilityServiceLabel from "@src/lib/facilityServiceLabel";
import StaffLink from "@src/lib/StaffLink";
import { formatDate } from "@src/lib/Time";
import useBoolean from "@src/lib/useBoolean";
import useEntitlement from "@src/lib/useEntitlement";
import { useTranslation } from "react-i18next";
import DetailsStack, { DetailsStackItem } from "../../DetailsStack";
import EditRestrictionDialog from "./EditRestrictionDialog";
import RemoveRestrictionDialog from "./RemoveRestrictionDialog";

type Props = {
  restriction: Pick<
    ConnectionRestriction,
    "id" | "service" | "createdAt" | "reason" | "expiresAt"
  > & {
    createdBy: Pick<StaffPosition, "id"> & {
      staff: Pick<Staff, "id" | "fullName">;
    };
  };
};

export default function ConnectionRestrictionCard({ restriction }: Props) {
  const { t } = useTranslation();

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  const editRestrictionDialog = useBoolean();
  const removeRestrictionDialog = useBoolean();

  return (
    <Card variant="outlined" sx={{ px: 2, py: 1.5 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" color="error">
          <Block sx={{ width: 18, height: 18 }} />
          &nbsp;
          {facilityServiceLabel(restriction.service)}
        </Typography>
        {canManageVisitorsAndMeetings && (
          <ContextMenu id={`edit-${restriction.id}`}>
            <MenuItem onClick={editRestrictionDialog.enable}>
              {t("Edit")}
            </MenuItem>
            <MenuItem onClick={removeRestrictionDialog.enable}>
              {t("Remove")}
            </MenuItem>
          </ContextMenu>
        )}
      </Box>
      <DetailsStack>
        <DetailsStackItem label={t("Disabled by")}>
          <StaffLink staff={restriction.createdBy.staff} />
        </DetailsStackItem>
        <DetailsStackItem label={t("Disabled on")}>
          {formatDate(new Date(restriction.createdAt), "datetime")}
        </DetailsStackItem>
        <DetailsStackItem label={t("Duration")}>
          {restriction.expiresAt
            ? t("Through {{date}}", {
                date: formatDate(new Date(restriction.expiresAt), "datetime"),
              })
            : t("Indefinite")}
        </DetailsStackItem>
        <DetailsStackItem label={t("Explanation")}>
          "{restriction.reason}"
        </DetailsStackItem>
      </DetailsStack>
      {editRestrictionDialog.current && (
        <EditRestrictionDialog
          restriction={restriction}
          onClose={editRestrictionDialog.disable}
        />
      )}
      {removeRestrictionDialog.current && (
        <RemoveRestrictionDialog
          restriction={restriction}
          onClose={removeRestrictionDialog.disable}
        />
      )}
    </Card>
  );
}
