import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
} from "@ameelio/ui";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "./ConditionalTooltip";
import InputDialog from "./InputDialog";
import LightbulbCard from "./LightbulbCard";

type InputType = "text" | "email" | "tel";

type SelectProps<T> = {
  label: string;
  description: string;
  /** Prompt appears in the dialog. Lydia would like to transition away from reusing description. */
  prompt?: string;
  value: T;
  canEdit: boolean;
  render: (v: T) => string;
  onChange: (v: T) => Promise<unknown>;
  selectOptions: { value: T; name: string }[];
};
export function SelectSettingField<T>({
  label,
  description,
  prompt,
  value,
  canEdit,
  render,
  onChange,
  selectOptions,
}: SelectProps<T>) {
  const indexMap = selectOptions.map(({ value }) => value);
  return (
    <SettingField
      label={label}
      description={description}
      value={render(value)}
      canEdit={canEdit}
    >
      {({ onClose }) => (
        <InputDialog
          fullWidth
          maxWidth="sm"
          title={label}
          value={indexMap.indexOf(value).toString()}
          description={prompt || description}
          selectOptions={selectOptions.map(({ name }, i) => ({
            value: i.toString(),
            name,
          }))}
          onCancel={() => onClose()}
          onSubmit={async ({ value }) => {
            await onChange(indexMap[Number(value)]);
            onClose();
          }}
        />
      )}
    </SettingField>
  );
}

type RadioProps<T> = {
  label: string;
  description: string;
  value: T;
  canEdit: boolean;
  render: (v: T) => string;
  onChange: (v: T) => Promise<void> | void;
  radioOptions: { value: T; label: ReactNode }[];
};
type IndexFormData = {
  value: number;
};

export function RadioSettingField<T>({
  label,
  description,
  value,
  canEdit,
  render,
  onChange,
  radioOptions,
}: RadioProps<T>) {
  const indexMap = radioOptions.map(({ value }) => value);
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<IndexFormData>({
    mode: "onChange",
    defaultValues: {
      value: indexMap.indexOf(value),
    },
  });
  return (
    <SettingField
      label={label}
      description={description}
      value={render(value)}
      canEdit={canEdit}
    >
      {({ onClose }) => (
        <Dialog title={label} onClose={onClose} fullWidth>
          <form
            onSubmit={handleSubmit(async (data) => {
              await onChange(indexMap[data.value]);
              onClose();
            })}
          >
            <DialogContent>
              {description && (
                <Typography variant="body1">{description}</Typography>
              )}
              <Controller
                name="value"
                control={control}
                render={({ field }) => {
                  return (
                    <RadioGroup {...field}>
                      {radioOptions.map(({ label }, i) => (
                        <FormControlLabel
                          key={i}
                          sx={{ alignItems: "flex-start" }}
                          value={i}
                          control={<Radio />}
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" autoFocus onClick={onClose}>
                {t("Cancel")}
              </Button>
              <SubmitButton disabled={!isValid} submitting={isSubmitting}>
                {t("Save")}
              </SubmitButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </SettingField>
  );
}

type BooleanProps = {
  label: string;
  description: string;
  prompt?: string;
  value: boolean;
  canEdit: boolean;
  onChange: (v: boolean) => Promise<unknown>;
};
export function BooleanSettingField({
  label,
  description,
  prompt,
  value,
  canEdit,
  onChange,
}: BooleanProps) {
  const { t } = useTranslation();
  return (
    <SelectSettingField
      label={label}
      description={description}
      prompt={prompt}
      render={(v: string) => v}
      value={value ? "Yes" : "No"}
      canEdit={canEdit}
      onChange={(v: string) => onChange(v === "Yes")}
      selectOptions={[
        { name: t("Yes"), value: "Yes" },
        { name: t("No"), value: "No" },
      ]}
    />
  );
}

type TextProps = {
  label: string;
  description: string;
  value: string;
  canEdit: boolean;
  inputType?: InputType;
  render?: (v: string) => string | ReactElement;
  validate?: { [n: string]: (v: string) => boolean };
  errorMessages?: { [n: string]: string };
  onChange: (v: string) => Promise<void> | void;
  endAdornment?: string;
};
export function TextSettingField({
  label,
  description,
  value,
  canEdit,
  render,
  inputType,
  errorMessages,
  validate,
  endAdornment,
  onChange,
}: TextProps) {
  return (
    <SettingField
      label={label}
      description={description}
      value={render ? render(value) : value}
      canEdit={canEdit}
    >
      {({ onClose }) => (
        <InputDialog
          fullWidth
          maxWidth="sm"
          title={label}
          value={value}
          inputType={inputType}
          description={description}
          onCancel={() => onClose()}
          errorMessages={errorMessages}
          endAdornment={endAdornment}
          rules={{
            validate,
          }}
          onSubmit={async ({ value }) => {
            await onChange(value);
            onClose();
          }}
        />
      )}
    </SettingField>
  );
}

type NumberProps = {
  label: string;
  description: string;
  value: number;
  canEdit: boolean;

  render?: (v: number) => string;
  validate?: { [n: string]: (v: number) => boolean };
  errorMessages?: { [n: string]: string };
  endAdornment?: string;
  onChange: (v: number) => Promise<void> | void;
};
export function NumberSettingField({
  value,
  canEdit,
  render,
  onChange,
  label,
  validate,
  errorMessages,
  description,
  endAdornment,
}: NumberProps) {
  const { t } = useTranslation();
  const stringValidate: Record<string, (s: string) => boolean> = {
    isNumber: (s: string) =>
      !isNaN(s as unknown as number) && !isNaN(parseInt(s)),
    isInt: (s: string) => Number(s) === Math.ceil(Number(s)),
  };
  const stringErrorMessages = {
    isNumber: t("Must be a number."),
    isInt: t("Must be a whole number."),
    ...(errorMessages || {}),
  };
  if (validate) {
    Object.keys(validate).forEach((key) => {
      stringValidate[key] = (s) => validate[key](parseInt(s));
    });
  }

  return (
    <TextSettingField
      label={label}
      description={description}
      value={value.toString()}
      canEdit={canEdit}
      render={(v) => (render ? render(parseInt(v)) : v)}
      onChange={(v) => onChange(parseInt(v))}
      errorMessages={stringErrorMessages}
      validate={stringValidate}
      endAdornment={endAdornment}
    />
  );
}

type SettingFieldProps = {
  label: string;
  description: string;
  currentDescription?: string;
  value: number | string | ReactElement;
  canEdit: boolean;
  children?: (props: { onClose: () => void }) => ReactElement;
};
export default function SettingField({
  label,
  description,
  currentDescription,
  value,
  canEdit,
  children,
}: SettingFieldProps) {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: "flex-start", sm: "center" }}
      direction={{ xs: "column", sm: "row" }}
    >
      <Box width={{ sm: 0.5 }}>
        <Typography variant="subtitle1" component="label">
          {label}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        {currentDescription && (
          <Box mt={1}>
            <LightbulbCard info={currentDescription} />
          </Box>
        )}
      </Box>
      <Box width={{ sm: 0.4 }}>
        <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
          {value}
        </Typography>
      </Box>
      <Box width={{ sm: 0.1 }} minWidth={64}>
        {children && (
          <ConditionalTooltip
            active={!canEdit}
            title={t("You do not have permission to edit this.")}
          >
            <Button
              variant="text"
              onClick={() => {
                setIsEditOpen(true);
              }}
              disabled={!canEdit}
            >
              {t("Edit")}
            </Button>
          </ConditionalTooltip>
        )}
      </Box>
      {isEditOpen &&
        children &&
        children({ onClose: () => setIsEditOpen(false) })}
    </Stack>
  );
}
