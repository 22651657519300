import {
  Chip,
  MultiSelectChipInputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { StaffTimelineEventObject } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import DateRangePicker from "@src/lib/DateRangePicker";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import PageLoader from "@src/lib/PageLoader";
import ScreenSection from "@src/lib/ScreenSection";
import { formatDate } from "@src/lib/Time";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetStaffTimelineDocument } from "./GetStaffTimeline.generated";
import TimelineEventAffectedParties from "./TimelineEventAffectedParties";
import TimelineEventObjectDescription from "./TimelineEventObjectDescription";

const LIMIT = 100;
interface Props {
  staffId: string;
  systemId: string;
}

type Filters = {
  facilities: string[];
  eventTypes: string[];
};

const StaffTimeline = ({ staffId, systemId }: Props) => {
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);

  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const { loading, data, refetch } = useQuery(GetStaffTimelineDocument, {
    variables: {
      staffId,
      systemId,
      limit: LIMIT,
      createdAfter: startDate,
      createdBefore: endDate,
    },
    skip: !staffId,
    onError: handleApolloError,
  });

  useEffect(() => {
    void refetch();
  }, [refetch, startDate, endDate]);

  const timelineEventTypeLabel = (
    eventType: StaffTimelineEventObject["__typename"],
  ) => {
    if (!eventType) return "";
    return {
      Connection: t("Connection"),
      ConnectionRestriction: t("Connection Restriction"),
      Meeting: t("Meeting"),
      Message: t("Message"),
      MessageKeyword: t("Message Keyword"),
      Kiosk: t("Kiosk"),
      StaffPosition: t("Staff Position"),
      StaffEntitlement: t("Staff Entitlement"),
      FacilityApprovedPhone: t("Facility phone contact"),
      Facility: t("Facility settings"),
      SystemUser: t("Visitor ID"),
      Call: t("Call"),
    }[eventType];
  };

  const eventTypes = [
    { name: t("Connection"), value: "Connection" },
    { name: t("Connection Restriction"), value: "ConnectionRestriction" },
    { name: t("Meeting"), value: "Meeting" },
    { name: t("Message"), value: "Message" },
    { name: t("Message Keyword"), value: "MessageKeyword" },
    { name: t("Kiosk"), value: "Kiosk" },
    { name: t("Staff Position"), value: "StaffPosition" },
    { name: t("Staff Entitlement"), value: "StaffEntitlement" },
    { name: t("Visitor ID"), value: "SystemUser" },
    { name: t("Recording"), value: "Recording" },
  ];

  const [filters, setFilters] = useState<Filters>({
    facilities: [],
    eventTypes: [],
  });

  const filteredTimeline = useMemo(
    () =>
      (data?.staff.timelineEvents.edges.map(({ node }) => node) || [])
        .filter(
          (te) =>
            filters.facilities.length === 0 ||
            !te.facility || // always show system-wide events
            filters.facilities.some((fId) => te.facility?.id === fId),
        )
        .filter(
          (te) =>
            filters.eventTypes.length === 0 ||
            filters.eventTypes.some((et) => et === te.object.__typename),
        )
        .reverse(),
    [filters, data],
  );

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: filteredTimeline,
    resetOnChange: [filters],
  });

  if (loading || !data) return <PageLoader />;

  return (
    <>
      <ScreenSection
        title={t("Activity")}
        titlePosition="inner"
        sx={{ padding: "1em" }}
      >
        <Box display="flex" gap={2} flexWrap="wrap" alignItems="center">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          {data ? (
            <Box sx={{ width: 250, flexShrink: 0 }}>
              <MultiSelectChipInputBase
                size="small"
                sx={{ maxWidth: 250 }}
                label={t("Facilities")}
                value={filters.facilities || []}
                items={data.system.facilities.map((f) => ({
                  name: f.publicId,
                  value: f.id,
                }))}
                onChange={(event) => {
                  setFilters((current) => ({
                    ...current,
                    facilities: event.target.value as string[],
                  }));
                }}
              />
            </Box>
          ) : null}
          {data ? (
            <Box sx={{ width: 250, flexShrink: 0 }}>
              <MultiSelectChipInputBase
                size="small"
                sx={{ maxWidth: 250 }}
                label={t("Categories")}
                value={filters.eventTypes || []}
                items={eventTypes}
                onChange={(event) => {
                  setFilters((current) => ({
                    ...current,
                    eventTypes: event.target.value as string[],
                  }));
                }}
              />
            </Box>
          ) : null}
        </Box>
        <Box sx={{ overflow: "auto", mt: 2 }}>
          <Table aria-label={t("Staff timeline table")}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Created At")}</TableCell>
                <TableCell>{t("Facility")}</TableCell>
                <TableCell>{t("Category")}</TableCell>
                <TableCell>{t("Activity")}</TableCell>
                <TableCell>{t("Affected Parties")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTimeline.length === 0 && <TableNoData colSpan={5} />}
              {filteredTimeline.length > 0 &&
                currentPageData.map((timeline) => {
                  return (
                    <TableRow key={timeline.id}>
                      <TableCell>
                        {formatDate(new Date(timeline.createdAt), "datetime")}
                      </TableCell>
                      <TableCell>
                        {!!timeline.facility && (
                          <Chip
                            color="grey"
                            label={timeline.facility.publicId}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          color="grey"
                          label={timelineEventTypeLabel(
                            timeline.object.__typename,
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TimelineEventObjectDescription event={timeline} />
                      </TableCell>
                      <TableCell>
                        <TimelineEventAffectedParties event={timeline} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            totalCount={filteredTimeline.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      </ScreenSection>
      {data?.staff.timelineEvents.pageInfo.hasPreviousPage && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t(
            "Only the most recent {{limit}} events are shown. Please refine your search to view older events.",
            { limit: LIMIT },
          )}
        </Alert>
      )}
    </>
  );
};

export default StaffTimeline;
