import { useAuthContext } from "@src/AuthProvider";
import { Facility } from "@src/typings/Facility";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { System } from "./api/graphql";
import { storeSelectedFacility } from "./lib/selectedFacility";

export type FacilityContextType = {
  facility?: Facility;
  system?: Pick<System, "id" | "recordingRetention">;
  selectFacility: (facility: Facility) => void;
};

export const FacilityContext = createContext<FacilityContextType>(
  {} as FacilityContextType,
);

export function useFacilityContext() {
  return useContext(FacilityContext);
}

export default function FacilityProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isLoggedIn } = useAuthContext();
  const [facility, setFacility] = useState<Facility | undefined>(undefined);

  // after the user logs out, un-sets
  // the selected facility in state
  useEffect(() => {
    if (!isLoggedIn) setFacility(undefined);
  }, [isLoggedIn]);

  const facilityContext = useMemo(
    (): FacilityContextType => ({
      facility,
      system: facility?.system,
      selectFacility: (facility: Facility) => {
        setFacility(facility);
        // Set in session storage
        storeSelectedFacility(facility.id);
      },
    }),
    [facility],
  );

  return (
    <FacilityContext.Provider value={facilityContext}>
      {children}
    </FacilityContext.Provider>
  );
}
