import { Theme } from "@emotion/react";
import { Paper, Stack, TypographyProps } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { SectionTitle } from "@src/lib/typography";
import React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
  sx?: SystemStyleObject<Theme>;
  titleProps?: TypographyProps<"h2">;
  titlePosition?: "outer" | "inner";
  extra?: React.ReactNode;
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section
 */
export default function ScreenSection({
  title,
  children,
  className,
  sx,
  titlePosition = "outer",
  titleProps,
  extra,
}: Props) {
  // In case of an extra element, right now we only support it in the inner position
  const hasInnerTitle = titlePosition === "inner" || Boolean(extra);

  return (
    <section className={className || ""}>
      {!hasInnerTitle && <SectionTitle {...titleProps}>{title}</SectionTitle>}
      <Paper
        variant="outlined"
        sx={{ ...sx, mt: 1, py: 2, px: 3, borderRadius: 4 }}
      >
        <Stack spacing={2}>
          {hasInnerTitle && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing="1"
              justifyContent="space-between"
            >
              <SectionTitle>{title}</SectionTitle>
              {extra ? extra : null}
            </Stack>
          )}
          {children}
        </Stack>
      </Paper>
    </section>
  );
}
