import { Button } from "@ameelio/ui";
import React, { useState } from "react";
import ExportMeetingsModal, {
  Props as ModalProps,
} from "./ExportMeetingsModal";

type Props = Omit<ModalProps, "onClose"> & { disabled?: boolean };

const ExportMeetingsButton: React.FC<Props> = ({ disabled, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <ExportMeetingsModal {...props} onClose={() => setOpen(false)} />
      )}
      <Button
        variant="outlined"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {props.children}
      </Button>
    </>
  );
};

export default ExportMeetingsButton;
