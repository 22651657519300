import { partition } from "@ameelio/core";
import { useQuery } from "@apollo/client";
import {
  ConnectionStatus,
  FacilityFeature,
  MeetingStatus,
  PrivacyLevel,
  SystemUserStatus,
} from "@src/api/graphql";
import { GetBadgeDataDocument } from "@src/graphql/GetBadgeData.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useMountedPolling from "@src/lib/useMountedPolling";
import { useMemo } from "react";

export default function useBadgeData() {
  const { facility: selectedFacility } = useGuaranteedFacilityContext();
  const facilityFeatures = selectedFacility.features;
  const handleApolloError = useApolloErrorHandler();
  const { data, startPolling, stopPolling } = useQuery(GetBadgeDataDocument, {
    fetchPolicy: "cache-and-network",
    onError: handleApolloError,
    variables: {
      id: selectedFacility.id,
      systemId: selectedFacility.system.id,
    },
  });
  useMountedPolling(30000, startPolling, stopPolling);

  // calculate badge counts for review menu
  const reviewCounts = useMemo(() => {
    // must match ConnectionRequestsPage component
    const pendingConnections =
      data?.facility.pendingConnectionRequests.filter(
        (c) =>
          c.status === ConnectionStatus.Pending &&
          c.visitor.systemRelationship?.status !== SystemUserStatus.Rejected,
      ) || [];
    const [professionalConnections, personalConnections] = partition(
      pendingConnections,
      (c) =>
        Boolean(
          facilityFeatures.includes(FacilityFeature.Providers) &&
            c.confidential,
        ),
    );

    const pendingMeetings =
      data?.facility.meetings.edges
        .map((e) => e.node)
        .filter((m) => m.status === MeetingStatus.PendingApproval) || [];
    const [personalMeetings, confidentialMeetings] = partition(
      pendingMeetings,
      (m) => m.privacyLevel === PrivacyLevel.Monitored,
    );

    return {
      count: {
        personalConnections: personalConnections.length,
        confidentialConnections: professionalConnections.length,
        personalMeetings: personalMeetings.length,
        confidentialMeetings: confidentialMeetings.length,
        messages: data?.facility.pendingMessages.count || 0,
      },
      data: {
        personalConnections: personalConnections,
        confidentialConnections: professionalConnections,
        personalMeetings: personalMeetings,
        confidentialMeetings: confidentialMeetings,
      },
    };
  }, [data, facilityFeatures]);

  return reviewCounts;
}
