import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { endOfMonth, isSameDay, startOfMonth } from "date-fns";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetUpcomingMeetingsDocument,
  GetUpcomingMeetingsQuery,
} from "../UpcomingMeetingsPage/GetUpcomingMeetings.generated";

type Props = {
  selectedDate: Date | null;
  onDateChange: (date: Date) => void;
};

const MEETINGS_LIMIT = 1000;

export default function DailySchedulePicker({
  selectedDate,
  onDateChange,
}: Props) {
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const [currentDate, setCurrentDate] = useState<Date>(
    selectedDate ?? new Date(),
  );
  const handleApolloError = useApolloErrorHandler();

  const { loading, data, refetch } = useQuery<GetUpcomingMeetingsQuery>(
    GetUpcomingMeetingsDocument,
    {
      variables: {
        id: facility.id,
        limit: MEETINGS_LIMIT,
        scheduledStartAfter: startOfMonth(currentDate).getTime(),
        scheduledStartBefore: endOfMonth(currentDate).getTime(),
        meetingStatus: [MeetingStatus.Scheduled, MeetingStatus.Live],
        meetingType: [
          MeetingType.VideoCall,
          MeetingType.InPersonVisit,
          MeetingType.Webinar,
        ],
      },
      fetchPolicy: "cache-and-network",
      onError: handleApolloError,
    },
  );

  const dayHasMeetings = useCallback(
    (day: Date) => {
      return data?.facility.meetings.edges.some((meeting) =>
        isSameDay(day, new Date(meeting.node.interval.startAt)),
      );
    },
    [data],
  );

  const handleMonthChange = (date: Date) => {
    setCurrentDate(date);
    void refetch({
      scheduledStartAfter: startOfMonth(date).getTime(),
      scheduledStartBefore: endOfMonth(date).getTime(),
    });
  };

  return (
    <DatePicker
      value={selectedDate}
      onChange={(newDate) => newDate && onDateChange(newDate)}
      onMonthChange={handleMonthChange}
      loading={loading}
      renderLoading={() => <CircularProgress />}
      disablePast
      slots={{
        day: (pickersDayProps) => {
          const { day } = pickersDayProps;
          return (
            <PickersDay
              {...pickersDayProps}
              sx={{
                backgroundColor: dayHasMeetings(day) ? blue[50] : undefined,
              }}
            />
          );
        },
      }}
      aria-label={t("Select date for meeting schedule")}
    />
  );
}
