import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  Connection,
  Entitlement,
  Inmate,
  Meeting,
  MeetingStatus,
  Visitor,
} from "@src/api/graphql";
import { RejectOrTerminateMeetingDocument } from "@src/graphql/RejectOrTerminateMeeting.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import useEntitlement from "@src/lib/useEntitlement";
import { isRequired } from "@src/lib/validate";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type MeetingToReject = Pick<Meeting, "id" | "meetingType" | "status"> & {
  inmates: Pick<Inmate, "id" | "fullName">[];
  visitors: Pick<Visitor, "id" | "fullName">[];
};

export type ConnectionToReject = Pick<Connection, "id"> & {
  inmate: Pick<Inmate, "id" | "fullName">;
  visitor: Pick<Visitor, "id" | "fullName">;
};

type Props = {
  meeting: MeetingToReject;
  onClose: () => void;
};

type FormData = {
  reason: string;
  otherReason: string;
};

export default function DeclineMeetingModal({ onClose, meeting }: Props) {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      reason: "",
      otherReason: "",
    },
  });
  // we need the reason to reveal the text box for a custom reason
  const reason = watch("reason");

  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const OTHER = t("Other");

  const isPendingApproval = meeting.status === MeetingStatus.PendingApproval;
  const REJECTION_REASONS = isPendingApproval
    ? [
        t("Resident has reached the maximum number of allowed visits."),
        t("Resident is unable to visit at this time."),
        t("The unit is on lockdown. All visitations have been declined."),
        OTHER,
      ]
    : [
        t("The unit is on lockdown. All visitations have been canceled."),
        t("Due to weather, we have to cancel all visitation appointments."),
        OTHER,
      ];
  const handleApolloError = useApolloErrorHandler();

  const [rejectOrTerminateMeeting] = useMutation(
    RejectOrTerminateMeetingDocument,
    {
      onError: handleApolloError,
      onCompleted: () => {
        snackbarContext.alert(
          "success",
          isPendingApproval
            ? t("The meeting has been successfully declined.")
            : t("The meeting has been successfully canceled."),
        );
        // Then hide the dialog
        onClose();
      },
    },
  );
  const doSubmit = async (data: FormData) => {
    await rejectOrTerminateMeeting({
      variables: {
        input: {
          meetingId: meeting.id,
          reason: data.reason === OTHER ? data.otherReason : data.reason,
        },
      },
    });
  };

  return (
    <Dialog
      title={isPendingApproval ? t("Decline meeting") : t("Cancel meeting")}
      onClose={onClose}
    >
      <DialogContent>
        <form
          onSubmit={handleSubmit(doSubmit)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box mb={2}>
            <Typography variant="subtitle2" color="text.primary">
              {isPendingApproval
                ? t(
                    "Please provide a reason for declining the meeting so we can inform the participants.",
                  )
                : t(
                    "Please provide a reason for the cancellation so we can inform the participants.",
                  )}
            </Typography>
          </Box>
          <Controller
            control={control}
            name="reason"
            rules={isRequired(t("Please select a reason."))}
            render={({ field }) => (
              <RadioGroup {...field}>
                {REJECTION_REASONS.map((reason) => (
                  <FormControlLabel
                    key={reason}
                    control={<Radio />}
                    value={reason}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {reason}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            )}
          />
          {reason === OTHER && (
            <TextInput
              control={control}
              name="otherReason"
              rules={isRequired(t("Please provide a reason."))}
              multiline
              rows={2}
              sx={{ ml: 4, flex: 1 }}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          dangerous
          disabled={!isValid || !canManageVisitorsAndMeetings}
          disabledReason={
            !canManageVisitorsAndMeetings
              ? t("You do not have permission to decline meetings.")
              : undefined
          }
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Confirm")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
