import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enMonolith from "./monolith/en.json";
import {
  default as esMonolith,
  default as itMonolith,
} from "./monolith/it.json";

const I18nNamespaceList = ["monolith"];

const resources = {
  en: {
    monolith: enMonolith,
  },
  it: {
    monolith: itMonolith,
  },
  es: {
    monolith: esMonolith,
  },
} as const;

void i18n.use(initReactI18next).init({
  lng: "en",
  ns: I18nNamespaceList,
  resources,

  nsSeparator: false, // Choosing "false" allows using a flat structure with "." in the key
  keySeparator: false, // Choosing "false" allows using a flat structure with "." in the key
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
