import { Button, Media } from "@ameelio/ui";
import { People, PersonAddAltOutlined } from "@mui/icons-material";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import { Box, Stack, Typography } from "@mui/material";
import { FacilityFeature, MeetingType } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { t } from "i18next";
import { useState } from "react";
import EventTypeCard from "./EventTypeCard";
import { Data } from "./types";

type Props = {
  data: Data;
  showBackButton?: boolean;
  onBack: () => void;
  onSubmit: (meetingType: MeetingType) => void;
  stepPosition: number;
  numSteps: number;
};

export default function SelectEventTypeStep({
  data,
  onBack,
  onSubmit,
  showBackButton,
  numSteps,
  stepPosition,
}: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const [meetingType, setMeetingType] = useState<MeetingType | undefined>(
    data?.meetingType,
  );
  const videoCallsDisabled =
    !facility.features.includes(FacilityFeature.VideoCall) &&
    !facility.features.includes(FacilityFeature.ConfidentialVideoCall);
  const ipvDisabled =
    !facility.features.includes(FacilityFeature.InPersonVisit) &&
    !facility.features.includes(FacilityFeature.ConfidentialInPersonVisit);
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2" color="text.primary">
          {t("Step {{stepPosition}} of {{numSteps}}: Select meeting type", {
            stepPosition,
            numSteps,
          })}
        </Typography>
        <EventTypeCard
          title={t("Video call")}
          onClick={
            !videoCallsDisabled
              ? () => setMeetingType(MeetingType.VideoCall)
              : undefined
          }
          selected={meetingType === MeetingType.VideoCall}
          disabled={videoCallsDisabled}
          tooltipTitle={
            videoCallsDisabled
              ? t("{{facilityName}} does not offer video calls", {
                  facilityName: facility.name,
                })
              : undefined
          }
        >
          <Stack spacing={1.5}>
            <Media gap={2} image={<VideocamOutlined />}>
              <Typography
                variant="body2"
                color={
                  videoCallsDisabled ? "rgba(0 0 0 / .23)" : "text.secondary"
                }
              >
                {t("Visitors connect virtually with Ameelio Connect")}
              </Typography>
            </Media>
            <Media gap={2} image={<PersonAddAltOutlined />}>
              <Typography
                variant="body2"
                color={
                  videoCallsDisabled ? "rgba(0 0 0 / .23)" : "text.secondary"
                }
              >
                {t(
                  "For approved visitors with Ameelio Connect accounts, so they can join the call.",
                )}
              </Typography>
            </Media>
          </Stack>
        </EventTypeCard>
        <EventTypeCard
          title={t("In-person visit")}
          onClick={
            !ipvDisabled
              ? () => setMeetingType(MeetingType.InPersonVisit)
              : undefined
          }
          selected={meetingType === MeetingType.InPersonVisit}
          disabled={ipvDisabled}
          tooltipTitle={
            ipvDisabled
              ? t("{{facilityName}} does not offer in-person visits", {
                  facilityName: facility.name,
                })
              : undefined
          }
        >
          <Stack spacing={1.5}>
            <Media gap={2} image={<People />}>
              <Typography
                variant="body2"
                color={ipvDisabled ? "rgba(0 0 0 / .23)" : "text.secondary"}
              >
                {t("Visitors come to the facility in person")}
              </Typography>
            </Media>
            <Media gap={2} image={<PersonAddAltOutlined />}>
              <Typography
                variant="body2"
                color={ipvDisabled ? "rgba(0 0 0 / .23)" : "text.secondary"}
              >
                {t(
                  "For any approved visitors. In-person visitors will show ID on arrival.",
                )}
              </Typography>
            </Media>
          </Stack>
        </EventTypeCard>
      </Stack>
      <Box mt={3} gap={1} display="flex" justifyContent="flex-end">
        {showBackButton && (
          <Button variant="outlined" onClick={onBack}>
            {t("Back")}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            // type narrowing
            if (!meetingType) return;
            onSubmit(meetingType);
          }}
          disabled={!meetingType}
        >
          {t("Next")}
        </Button>
      </Box>
    </>
  );
}
