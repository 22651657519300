import { useQuery } from "@apollo/client";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import {
  countsInInterval,
  ElaboratedRatings,
  elaborateRatings,
} from "@src/pages/ActivityPage/ActivityUtils";
import { GetFacilityMetricsV2Document } from "@src/pages/ActivityPage/GetFacilityMetricsV2.generated";
import { useMemo } from "react";

interface MetricsData {
  total: number;
  adoptionRate: number;
  userRatings?: ElaboratedRatings;
  inmateRatings?: ElaboratedRatings;
  volume: Record<string, number>;
}

interface UseFacilityMetricsArgs {
  reportStart: number;
  reportEnd: number;
  facilityId: string;
}

export function useFacilityMetrics({
  reportStart,
  reportEnd,
  facilityId,
}: UseFacilityMetricsArgs) {
  const handleApolloError = useApolloErrorHandler();
  const { data, loading } = useQuery(GetFacilityMetricsV2Document, {
    fetchPolicy: "cache-first",
    variables: { reportStart, reportEnd, facilityId },
    onError: handleApolloError,
  });

  const interval = useMemo(
    () => ({ start: new Date(reportStart), end: new Date(reportEnd) }),
    [reportStart, reportEnd],
  );

  const videoCalls: MetricsData | null = useMemo(() => {
    if (!data) return null;
    return {
      total: data.facility.videoCallMetrics.count,
      adoptionRate: Math.round(
        (data.facility.videoCallMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      userRatings: elaborateRatings(
        data.facility.videoCallMetrics.ratingsByType.user,
      ),
      inmateRatings: elaborateRatings(
        data.facility.videoCallMetrics.ratingsByType.inmate,
      ),
      volume: countsInInterval(
        interval,
        data.facility.videoCallMetrics.countByDay,
      ),
    };
  }, [data, interval]);

  const voiceCalls: MetricsData | null = useMemo(() => {
    if (!data) return null;
    return {
      total: data.facility.voiceCallMetrics.count,
      adoptionRate: Math.round(
        (data.facility.voiceCallMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      userRatings: elaborateRatings(
        data.facility.voiceCallMetrics.ratingsByType.user,
      ),
      inmateRatings: elaborateRatings(
        data.facility.voiceCallMetrics.ratingsByType.inmate,
      ),
      volume: countsInInterval(
        interval,
        data.facility.voiceCallMetrics.countByDay,
      ),
    };
  }, [data, interval]);

  const inPersonVisits: MetricsData | null = useMemo(() => {
    if (!data) return null;
    return {
      total: data.facility.visitMetrics.count,
      adoptionRate: Math.round(
        (data.facility.visitMetrics.uniqueInmates /
          data.facility.inmateMetrics.count) *
          100,
      ),
      volume: countsInInterval(interval, data.facility.visitMetrics.countByDay),
    };
  }, [data, interval]);

  return { data, loading, videoCalls, voiceCalls, inPersonVisits };
}
