import { MeetingType } from "@src/api/graphql";
import stripInvalid from "@src/lib/stripInvalid";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Filters = {
  residentName: string;
  meetingType: MeetingType[] | undefined;
};

const { history } = window;

export default function useMeetingFiltersState() {
  const location = useLocation();

  const [filters, setFilters] = useState<Filters>(() => {
    const stateSchema = Joi.object<{
      residentName?: string;
      meetingTypes?: MeetingType[];
    }>({
      residentName: Joi.string(),
      meetingTypes: Joi.array().items(
        Joi.string().valid(...Object.values(MeetingType)),
      ),
    });
    const historyState = stripInvalid(stateSchema, history.state);
    const locationState = stripInvalid(stateSchema, location.state);
    const initialState = { ...historyState, ...locationState };

    return {
      residentName: initialState.residentName || "",
      meetingType: initialState.meetingTypes || undefined,
    };
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  return [filters, setFilters] as const;
}
