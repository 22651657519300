import { useQuery } from "@apollo/client";
import { Alert, Box, CircularProgress, Grid, Stack } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Spinner from "@src/lib/Spinner";
import { formatTimeRange } from "@src/lib/Time";
import { theme } from "@src/theme";
import { isSameDay } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AvailabilityTile from "./AvailabilityTile";
import { GetInmateAvailabilityDocument } from "./GetInmateAvailability.generated";
import { Availability, AvailableKiosk, Data } from "./types";

type Props = {
  inmateId: string;
  meetingId?: string;
  data: Data;
  date: Date | null;
  selectedAvailability?: Availability;
  selectingCustomTime: boolean;
  onUpdateSelectCustomTime: () => void;
  onUpdateDate: (value: Date | null) => void;
  onUpdateMeetingInfo: ({
    availability,
    kiosk,
  }: {
    availability?: Availability;
    kiosk?: AvailableKiosk;
  }) => void;
};

export function getMaxVisitorsForCapacity(capacity: number): number {
  // Total number of visitors = total capacity - inmate (1)
  const NUM_INMATES_PER_MEETING = 1;
  return capacity - NUM_INMATES_PER_MEETING;
}

export default function SelectAvailability({
  inmateId,
  meetingId,
  data,
  date,
  selectedAvailability,
  selectingCustomTime,
  onUpdateDate,
  onUpdateSelectCustomTime,
  onUpdateMeetingInfo,
}: Props) {
  // type narrowing
  if (!data.meetingType) throw new Error("invalid meeting type");

  const { t } = useTranslation();
  const { meetingType, registeredGuests, unregisteredGuests } = data;
  const numVisitors =
    (registeredGuests?.length || 0) + (unregisteredGuests?.length || 0);
  const handleApolloError = useApolloErrorHandler();
  const {
    data: availabilityData,
    loading: availabilityLoading,
    error: availabilityError,
  } = useQuery(GetInmateAvailabilityDocument, {
    fetchPolicy: "network-only",
    variables: {
      inmateId,
      meetingType,
      ignoreMeetingId: meetingId,
      visitorIds: registeredGuests?.map((rg) => rg.id) ?? [],
    },
    onError: handleApolloError,
  });

  const availabilitiesOnDate: Availability[] = useMemo(() => {
    if (availabilityData && date) {
      return availabilityData.inmate.meetingAvailability.filter((a) =>
        isSameDay(date, a.interval.startAt),
      );
    }
    return [];
  }, [availabilityData, date]);

  // used to inform schedulers what visitor quantity will reveal availabilities
  const visitorCapacityWithAvailabilities = useMemo(() => {
    const capacities = availabilitiesOnDate.flatMap((avail) =>
      avail.availableKiosks.map((kiosk) =>
        getMaxVisitorsForCapacity(kiosk.capacity),
      ),
    );
    if (capacities.length) return Math.max(...capacities);
    return 0;
  }, [availabilitiesOnDate]);

  const dayHasAvailableMeetings = (day: Date) =>
    availabilityData?.inmate.meetingAvailability.some((a) => {
      return (
        !a.conflict &&
        a.availableKiosks.length > 0 &&
        isSameDay(day, a.interval.startAt)
      );
    });

  const meetingDayIsUnavailable = (day: Date) =>
    availabilityData?.inmate.meetingAvailability
      .filter((a) => isSameDay(day, a.interval.startAt))
      .every((a) => a.conflict || a.availableKiosks.length === 0);

  const findKioskAndUpdate = (availability: Availability) => {
    // Find the smallest kiosk available that can
    // accommodate a potential visitor list size
    const kiosk = availability.availableKiosks
      ? availability.availableKiosks
          .sort((a, b) => a.capacity - b.capacity)
          .find((k) => getMaxVisitorsForCapacity(k.capacity) >= numVisitors)
      : data.kiosk;
    if (!kiosk) return;
    onUpdateMeetingInfo({ availability, kiosk });
  };

  const now = Date.now();

  return (
    <>
      <Stack spacing={3}>
        <DatePicker
          value={date}
          onChange={(d) => onUpdateDate(d)}
          loading={availabilityLoading}
          renderLoading={() => <CircularProgress />}
          disablePast
          disableHighlightToday
          slots={{
            day: (pickersDayProps) => {
              const { day } = pickersDayProps;
              return (
                <PickersDay
                  {...pickersDayProps}
                  sx={{
                    backgroundColor:
                      pickersDayProps.disabled || !dayHasAvailableMeetings(day)
                        ? undefined
                        : dayHasAvailableMeetings(day)
                          ? blue[50]
                          : meetingDayIsUnavailable(day)
                            ? grey[200]
                            : undefined,
                  }}
                />
              );
            },
          }}
          sx={{ backgroundColor: theme.palette.common.white, borderRadius: 2 }}
        />
        {date && (
          <>
            {!(availabilityLoading || availabilityError) &&
              !availabilitiesOnDate.length && (
                <Alert severity="error">
                  {t(
                    "There are no time slots on this day. Please select a different day.",
                  )}
                </Alert>
              )}

            {availabilitiesOnDate.length > 0 &&
              !availabilitiesOnDate.some(
                (a) =>
                  a.availableKiosks.length > 0 &&
                  a.availableKiosks.some(
                    (kiosk) =>
                      getMaxVisitorsForCapacity(kiosk.capacity) >= numVisitors,
                  ),
              ) && (
                <Alert severity="error">
                  {visitorCapacityWithAvailabilities > 0
                    ? t(
                        "Because there are more than {{count}} visitors, you will need to create a custom time.",
                        { count: visitorCapacityWithAvailabilities },
                      )
                    : t(
                        "There are no available time slots on this day. This is based on the number of time slots the facility makes available. Please select a different day.",
                      )}
                </Alert>
              )}

            {/** respect same grid size as below */}
            {availabilityLoading && (
              <Grid container spacing={1}>
                <Grid key="loading-voice" item xs={4}>
                  <Box display="flex" justifyContent="center">
                    <Spinner />
                  </Box>
                </Grid>
              </Grid>
            )}

            {/** the div fixes a Stack vs Grid conflict */}
            {!availabilityLoading && (
              <div>
                <Grid container spacing={1}>
                  {availabilitiesOnDate.map((a) => (
                    <Grid
                      key={`${date.toString()}-${a.interval.startAt}-${a.interval.endAt}`}
                      item
                      xs={4}
                    >
                      <AvailabilityTile
                        disabled={
                          a.availableKiosks.length === 0 ||
                          !a.availableKiosks.some(
                            (avail) =>
                              getMaxVisitorsForCapacity(avail.capacity) >=
                              numVisitors,
                          ) ||
                          a.conflict ||
                          a.interval.endAt < now
                        }
                        unavailableReason={
                          a.availableKiosks.length === 0
                            ? t(
                                "No locations or devices are available at this time",
                              )
                            : a.conflict
                              ? t(
                                  "The resident has a schedule conflict at this time",
                                )
                              : a.interval.endAt < now
                                ? t("This time slot has already passed")
                                : !a.availableKiosks.some(
                                      (avail) =>
                                        getMaxVisitorsForCapacity(
                                          avail.capacity,
                                        ) >= numVisitors,
                                    )
                                  ? t(
                                      "The visitor list is too large for this time slot",
                                    )
                                  : ""
                        }
                        selected={
                          !selectingCustomTime &&
                          selectedAvailability?.interval.startAt ===
                            a.interval.startAt &&
                          selectedAvailability.interval.endAt ===
                            a.interval.endAt
                        }
                        onClick={() =>
                          // Enables un-selecting the
                          // currently selected availability
                          // and kiosk
                          selectedAvailability === a
                            ? onUpdateMeetingInfo({
                                availability: undefined,
                                kiosk: undefined,
                              })
                            : findKioskAndUpdate(a)
                        }
                      >
                        {formatTimeRange(a.interval.startAt, a.interval.endAt)}
                      </AvailabilityTile>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </>
        )}
        {date && (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <AvailabilityTile
                  disabled={false}
                  selected={selectingCustomTime}
                  onClick={onUpdateSelectCustomTime}
                >
                  {t("Custom time")}
                </AvailabilityTile>
              </Grid>
            </Grid>
          </div>
        )}
      </Stack>
    </>
  );
}
