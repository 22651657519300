import { sortByStrings } from "@ameelio/core";
import { Button } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useFetchAll from "@src/lib/useFetchAll";
import { GetFacilityInmatesNamesDocument } from "@src/pages/ScheduleEventPage/GetInmatesList.generated";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Data } from "./types";

type AutocompleteOption = {
  id: string;
  label: string;
};

type Props = {
  data: Data;
  onBack: () => void;
  onSubmit: (inmateId: string) => void;
  stepPosition: number;
  numSteps: number;
};

export default function SelectInmateStep({
  data,
  onSubmit,
  stepPosition,
  numSteps,
}: Props) {
  const [inmateId, setInmateId] = useState<string | undefined>(data?.inmateId);
  const [completed, setCompleted] = useState(false);
  const { facility } = useGuaranteedFacilityContext();
  const handleApolloError = useApolloErrorHandler();
  const {
    data: inmatesListData,
    loading,
    error,
    fetchMore,
  } = useQuery(GetFacilityInmatesNamesDocument, {
    variables: {
      id: facility.id,
      cursor: undefined,
    },
    onError: handleApolloError,
  });
  if (error) throw error;

  useFetchAll({
    pageInfo: inmatesListData?.facility.inmates.pageInfo,
    fetchMore,
    onComplete: () => setCompleted(true),
  });

  const inmates = useMemo(
    () =>
      sortByStrings(
        inmatesListData?.facility.inmates.edges.map((e) => e.node) || [],
        (a) => a.fullName,
      ),
    [inmatesListData],
  );

  const totalCount = inmatesListData?.facility.inmates.count;

  if (loading || !completed)
    return (
      <PageLoader
        title={
          totalCount
            ? t("Loading {{inmateCount}} out of {{totalCount}} residents...", {
                inmateCount: inmates.length,
                totalCount,
              })
            : t("Loading...")
        }
      />
    );

  const autocompleteOptions = inmates.map((a) => ({
    id: a.id,
    label: a.fullName,
  })) as AutocompleteOption[];

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2" color="text.primary">
          {t("Step {{stepPosition}} of {{numSteps}}: Select resident", {
            stepPosition,
            numSteps,
          })}
        </Typography>
        <Autocomplete
          aria-label={t("Resident")}
          loading={loading || !completed}
          renderInput={(params) => (
            <TextField {...params} label={t("Resident")} />
          )}
          options={autocompleteOptions}
          onChange={(_, newValue: AutocompleteOption | null) => {
            setInmateId(newValue?.id);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={
            inmateId ? autocompleteOptions.find((a) => a.id === inmateId) : null
          }
          groupBy={(option) => option.label.charAt(0).toUpperCase()}
          sx={{ width: "auto", backgroundColor: "white", borderRadius: 2 }}
        />
      </Stack>
      <Box mt={3} gap={1} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => {
            // type narrowing
            if (!inmateId) return;
            onSubmit(inmateId);
          }}
          disabled={!inmateId}
        >
          {t("Next")}
        </Button>
      </Box>
    </>
  );
}
