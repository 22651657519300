import {
  DialogProps as BaseProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
} from "@ameelio/ui";
import { Typography } from "@mui/material";
import { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";

type Props = Omit<BaseProps, "open" | "onSubmit"> & {
  title: string;
  description?: string;
  cancelText?: string;
  saveText?: string;
  onCancel: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  formState: { isValid: boolean; isSubmitting: boolean };
  children: React.ReactNode;
};

export default function FormDialog({
  title,
  description,
  cancelText,
  saveText,
  onCancel,
  onSubmit,
  formState,
  children,
  ...rest
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog title={title} {...rest} onClose={onCancel}>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ pt: description ? 0 : 2 }}>
          {description && (
            <Typography variant="body1">{description}</Typography>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={onCancel}>
            {cancelText || t("Cancel")}
          </Button>
          <SubmitButton
            disabled={!formState.isValid}
            submitting={formState.isSubmitting}
          >
            {saveText || t("Save")}
          </SubmitButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
