import { LockOutlined } from "@ant-design/icons";
import { Typography as MUITypography, Stack } from "@mui/material";
import { CallMessage, Meeting, PrivacyLevel } from "@src/api/graphql";
import MessageDisplay from "@src/lib/MessageDisplay";
import ScreenSection from "@src/lib/ScreenSection";
import Spinner from "@src/lib/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export type InCallChatProps = {
  meeting: Pick<Meeting, "id" | "privacyLevel">;
  canReviewRecordings: boolean;
  allowedToAccessRecordings?: boolean;
  loading: boolean;
  messages: (Pick<CallMessage, "id" | "contents" | "createdAt"> & {
    sender:
      | { __typename: "Inmate" | "Visitor"; fullName: string }
      | { __typename: "Staff" };
  })[];
};

export default function InCallChatModule({
  meeting,
  canReviewRecordings,
  allowedToAccessRecordings,
  loading,
  messages,
}: InCallChatProps): ReactElement {
  const { t } = useTranslation();
  return (
    <ScreenSection
      titlePosition="inner"
      title={t("In-Call Chat")}
      sx={{ maxHeight: "75vh", overflow: "auto" }}
    >
      <Stack spacing={2}>
        {meeting.privacyLevel !== PrivacyLevel.Monitored ? (
          <>
            <LockOutlined />{" "}
            {t("Messages are not available on confidential meetings.")}
          </>
        ) : !canReviewRecordings ? (
          <>
            <LockOutlined />{" "}
            {t(
              "Only staff with permission to review recordings can see call messages.",
            )}
          </>
        ) : !allowedToAccessRecordings ? (
          <>
            <LockOutlined />{" "}
            {t(
              "You do not have the required staff position to view this call's messages.",
            )}
          </>
        ) : loading ? (
          <Spinner />
        ) : messages.length ? (
          messages.map((message) => (
            <MessageDisplay
              key={message.id}
              message={{
                callId: meeting.id,
                senderType: message.sender.__typename,
                senderName:
                  message.sender.__typename === "Staff"
                    ? t("Facility Staff")
                    : message.sender.fullName,
                contents: message.contents,
                createdAt: message.createdAt,
              }}
            />
          ))
        ) : (
          <MUITypography variant="body2" color="text.secondary">
            {t("No chat messages were sent")}
          </MUITypography>
        )}
      </Stack>
    </ScreenSection>
  );
}
