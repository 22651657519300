import { Button } from "@ameelio/ui";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Icon404 } from "@src/assets/404.svg";
import { ScreenTitle } from "@src/lib/typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotFoundSplashScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop="15vh"
      gap={4}
    >
      <Icon404 style={{ width: "250px" }} />
      <ScreenTitle>{t("Page not found")}</ScreenTitle>
      <Typography textAlign="center">
        {t("The page you're looking for doesn't exist")}
      </Typography>
      <Button variant="text" onClick={() => navigate("/")}>
        {t("Return home")}
      </Button>
    </Box>
  );
}
