import { TextInputBase } from "@ameelio/ui/src/lib/TextInput";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import DateRangePicker from "@src/lib/DateRangePicker";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import stripInvalid from "@src/lib/stripInvalid";
import useEntitlement from "@src/lib/useEntitlement";
import { endOfDay } from "date-fns";
import Joi from "joi";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetTranscriptsDocument } from "./GetTranscripts.generated";
import TranscriptTable from "./TranscriptTable";

const LIMIT = 20;

type Filters = {
  inmateName?: string;
  query?: string;
  from?: number;
  to?: number;
};

const { history } = window;

// load state from history (back nav)
function parseHistoryState() {
  return stripInvalid(
    Joi.object<{
      inmateName?: string;
      query?: string;
      from?: number;
      to?: number;
    }>({
      inmateName: Joi.string(),
      query: Joi.string(),
      from: Joi.number(),
      to: Joi.number(),
    }),
    history.state,
  );
}

export default function SearchTranscriptsPage() {
  const { t } = useTranslation();
  const { facility } = useGuaranteedFacilityContext();
  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const hasMounted = useRef(false);

  // search filters are restored from page state for back navigation
  const [filters, setFilters] = useState<Filters>(() => {
    const historyState = parseHistoryState();
    return {
      inmateName: historyState.inmateName || "",
      query: historyState.query || "",
      from: historyState.from,
      to: historyState.to,
    };
  });
  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const { data, loading, error, refetch } = useQuery(GetTranscriptsDocument, {
    notifyOnNetworkStatusChange: true, // So that refetch triggers loading: true
    variables: {
      facilityId: facility.id,
      limit: LIMIT,
      inmateName: "",
      query: "",
      from: undefined as number | undefined,
      to: undefined as number | undefined,
    },
    skip: !canReviewRecordings,
    fetchPolicy: "cache-and-network",
  });
  if (error) throw error;

  // execute the restored search query on mount and when the query changes
  useEffect(() => {
    // on the initial mount, execute the query with any restored parameters
    if (!hasMounted.current) {
      hasMounted.current = true;
      void refetch({
        facilityId: facility.id,
        limit: LIMIT,
        ...filters,
      });
      // on subsequent changes, only execute the query if it is empty
      // or has three characters or more (for performance reasons)
    } else if (
      (filters.inmateName?.length === 0 ||
        (filters.inmateName && filters.inmateName.length >= 3)) &&
      (filters.query?.length === 0 ||
        (filters.query && filters.query.length >= 3))
    ) {
      // Only refetch if the user has stopped typing for 700ms (middle
      // ground between responsiveness and slow typers)
      const timeout = setTimeout(() => {
        void refetch({
          facilityId: facility.id,
          limit: LIMIT,
          ...filters,
        });
      }, 700);
      return () => clearTimeout(timeout);
    }
  }, [hasMounted, refetch, filters, facility.id]);

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  if (!canReviewRecordings) {
    return (
      <Box>
        <Header
          title={t("Not permitted")}
          subtitle={t(
            "You do not have permission to search meeting transcripts.",
          )}
        />
        <NotAllowed />
      </Box>
    );
  }

  return (
    <Box>
      <Header
        title={t("Search Call transcripts")}
        subtitle={[
          t(
            "Search previous call transcripts by words, resident, and/or dates. Results are ordered by relevance.",
          ),
          t(
            "Note: Transcripts are created automatically and have not been reviewed for accuracy. They do not include non-verbal sounds, and contain inaccuracies in words, time stamps, and speaker assignment.",
          ),
        ]}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <TextInputBase
                size="small"
                label={t("Resident name")}
                aria-label={t("Resident name")}
                style={{}}
                sx={{
                  width: "auto",
                  minWidth: { sm: 250 },
                }}
                placeholder={t("Search by resident name...")}
                value={filters.inmateName}
                onChange={(event) =>
                  changeFilter({
                    inmateName: event.target.value,
                  })
                }
              />
              <TextInputBase
                size="small"
                label={t("Word or phrase")}
                aria-label={t("Word or phrase")}
                style={{}}
                sx={{
                  width: "auto",
                  minWidth: { sm: 250 },
                }}
                placeholder={t("Search by word or phrase...")}
                value={filters.query}
                onChange={(event) =>
                  changeFilter({
                    query: event.target.value,
                  })
                }
              />
              <DateRangePicker
                onStartDateChange={(date) => {
                  changeFilter({
                    from: date || undefined,
                  });
                }}
                onEndDateChange={(date) => {
                  const newDate = date ? endOfDay(date) : new Date();
                  changeFilter({
                    to: newDate.getTime(),
                  });
                }}
                disabled={false}
                startDate={filters.from || null}
                endDate={filters.to || null}
              />
            </ResponsiveColumns>
          </Box>
        </Box>
      </Header>
      <TranscriptTable
        loading={loading}
        transcripts={data?.facility.meetingTranscripts || []}
        query={filters.query}
      />
      {!loading && !!data?.facility.meetingTranscripts.length && (
        <Alert severity="warning" sx={{ m: 3, mt: 0 }}>
          {t(
            "Only the first {{limit}} results are shown. Please refine your search to view more results.",
            { limit: LIMIT },
          )}
        </Alert>
      )}
    </Box>
  );
}
