import { Button } from "@ameelio/ui";
import { Box, Card, Stack } from "@mui/material";
import { Entitlement, FacilityApprovedPhone } from "@src/api/graphql";
import { SectionTitle } from "@src/lib/typography";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConditionalTooltip from "../ConditionalTooltip";
import AddEditPhoneNumberModal from "./AddEditPhoneNumberModal";
import PhoneNumberCard from "./PhoneNumberCard";

type PhoneNumber = Omit<FacilityApprovedPhone, "facility">;

type Props = {
  phoneNumbers: PhoneNumber[];
};
export default function PhoneNumberTable({ phoneNumbers }: Props) {
  const [isAddingPhoneNumber, setIsAddingPhoneNumber] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="outlined"
        sx={{ paddingX: 2.5, paddingY: 2, borderRadius: "8px" }}
      >
        <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
          <Box>
            <SectionTitle>{t("Phone numbers")}</SectionTitle>
          </Box>
          <ConditionalTooltip
            title={t(
              "You do not have permission to add approved phone numbers.",
            )}
            active={!canEdit}
          >
            <Button
              onClick={() => {
                setIsAddingPhoneNumber(true);
              }}
              variant="outlined"
              disabled={!canEdit}
            >
              {t("Add number")}
            </Button>
          </ConditionalTooltip>
        </Box>
        <Stack spacing={2}>
          {phoneNumbers.map((p) => (
            <PhoneNumberCard key={p.id} phoneNumber={p} />
          ))}
        </Stack>
      </Card>

      {isAddingPhoneNumber && (
        <AddEditPhoneNumberModal
          onClose={() => setIsAddingPhoneNumber(false)}
        />
      )}
    </>
  );
}
