import { useQuery } from "@apollo/client";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Joi from "joi";
import { useCallback } from "react";
import { GetImportDetailsDocument } from "./GetImportDetails.generated";

export type InmateData = {
  inmateIdentification: string;
  pin: string;
  firstName: string;
  lastName: string;
  facilityName: string;
  groupName: string;
  dateOfBirth: Date;
  callQuota?: number;
  visitQuota?: number;
  voiceCallTimeQuota?: number;
  emessageDelay?: number;
};

type ValidatedResult = { inmate: InmateData; error?: undefined };
export const isValidatedResult = (r: ValidationResult): r is ValidatedResult =>
  !!r.inmate;

type ErroredResult = { inmate?: undefined; error: Joi.ValidationError };
export const isErroredResult = (r: ValidationResult): r is ErroredResult =>
  !!r.error;

export type ValidationResult = ValidatedResult | ErroredResult;

type Validator = {
  (json: Record<string, unknown>[]): ValidationResult[];
};

export default function useRosterValidation(
  systemId: string,
): [Validator, { loading: boolean }] {
  const handleApolloError = useApolloErrorHandler();
  // begin loading data that we will need
  const { data, loading } = useQuery(GetImportDetailsDocument, {
    variables: { systemId },
    onError: handleApolloError,
  });

  const validator = useCallback<Validator>(
    (json) => {
      if (!data?.system) throw new Error("facility data has not loaded yet");

      const facilities = data.system?.facilities;
      const facilityNames = facilities.map((f) => f.publicId);

      const schema = Joi.object<InmateData>().keys({
        inmateIdentification: Joi.string().required(),
        pin: Joi.string().min(6).required(),
        firstName: Joi.string().required(),
        lastName: Joi.string().allow(""),
        dateOfBirth: Joi.date().required(),
        facilityName: Joi.string()
          .valid(...facilityNames)
          .required(),
        groupName: Joi.string().required(),
        callQuota: Joi.number().min(0).empty("").optional(),
        visitQuota: Joi.number().min(0).empty("").optional(),
        voiceCallTimeQuota: Joi.number().min(0).empty("").optional(),
        emessageDelay: Joi.number().min(0),
      });

      return json.map((record) => {
        const result = schema.validate(record, { stripUnknown: true });
        if (result.error) return { error: result.error };
        return { inmate: result.value };
      });
    },
    [data],
  );

  return [validator, { loading }];
}
