import { Button, useSnackbarContext } from "@ameelio/ui";
import { FlagOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import ReportArtifactProblemModal from "@src/pages/ArtifactsPage/ReportArtifactProblemModal";
import { ReportProblemWithRecordingDocument } from "@src/pages/ArtifactsPage/ReportProblemWithRecording.generated";
import { ReportProblemWithScreenshotsDocument } from "@src/pages/ArtifactsPage/ReportProblemWithScreenshots.generated";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReportProblemWithArtifactButton({
  meetingId,
  artifactType,
}: {
  meetingId: string;
  artifactType: "recording" | "screenshot";
}) {
  const [showReportProblemModal, setShowReportProblemModal] = useState(false);
  const snackbarContext = useSnackbarContext();
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();
  const [reportProblem, { loading: reportSubmitting }] = useMutation(
    artifactType === "recording"
      ? ReportProblemWithRecordingDocument
      : ReportProblemWithScreenshotsDocument,
    {
      onCompleted: () => {
        snackbarContext.alert("success", t("Your help request was sent"));
        setShowReportProblemModal(false);
      },
      onError: handleApolloError,
    },
  );

  return (
    <>
      <ReportArtifactProblemModal
        artifactType={artifactType}
        visible={showReportProblemModal}
        onCancel={() => setShowReportProblemModal(false)}
        onSubmit={(payload) =>
          reportProblem({
            variables: {
              input: {
                meetingId: meetingId,
                ...payload,
              },
            },
          })
        }
        loading={reportSubmitting}
      />
      <Button
        variant="outlined"
        key="1"
        onClick={() => setShowReportProblemModal(true)}
      >
        <FlagOutlined style={{ verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle", marginLeft: 8 }}>
          {t("Report Problem")}
        </span>
      </Button>
    </>
  );
}
