import { AspectRatio } from "@ameelio/ui";
import { Box, Typography } from "@mui/material";
import { VisitorIdentity } from "@src/api/graphql";
import imageLabelForDocumentType, {
  ImageNumber,
} from "@src/lib/imageLabelForDocumentType";
import { useTranslation } from "react-i18next";
import ZoomableImage from "./ZoomableImage";

export default function IdentityImage({
  identity,
  imageNumber,
}: {
  identity: Pick<
    VisitorIdentity,
    "documentType" | "idImageOneUrl" | "idImageTwoUrl" | "idImageThreeUrl"
  >;
  imageNumber: ImageNumber;
}) {
  const { t } = useTranslation();

  const url =
    imageNumber === ImageNumber.ImageOne
      ? identity.idImageOneUrl
      : imageNumber === ImageNumber.ImageTwo
        ? identity.idImageTwoUrl
        : imageNumber === ImageNumber.ImageThree
          ? identity.idImageThreeUrl
          : null;

  const label = imageLabelForDocumentType(identity.documentType, imageNumber);

  return (
    <div style={{ flex: 1 }}>
      <AspectRatio ratio={1}>
        <Box
          sx={{
            height: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {url ? (
            <ZoomableImage src={url} alt={label} />
          ) : (
            <Typography variant="subtitle2">{t("Unavailable")}</Typography>
          )}
        </Box>
      </AspectRatio>
      <Typography variant="body1" color="text.primary">
        {label}
      </Typography>
    </div>
  );
}
