import { useQuery } from "@apollo/client";
import { Group, NotInterested, Search, Timer } from "@mui/icons-material";
import { Card, Stack } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import Header from "@src/lib/Header";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { NumberSettingField } from "@src/lib/SettingField";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import UnavailableSplashScreen from "@src/lib/UnavailableSplashScreen";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";
import { GetMessageKeywordsDocument } from "./GetMessageKeywords.generated";
import KeywordsTable from "./KeywordsTable";

export default function MessagesSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { updateProperty } = useUpdateFacilityProperty();
  const { t } = useTranslation();

  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const { data } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  const { data: keywordsData } = useQuery(GetMessageKeywordsDocument, {
    variables: { id: facility.id },
  });

  if (!data || !keywordsData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = data;
  const {
    facility: { keywords },
  } = keywordsData;

  if (!loadedFacility.features.includes(FacilityFeature.Emessaging)) {
    return (
      <UnavailableSplashScreen
        header={t("Messages")}
        subHeader={t(
          "Contact us about adding visitor messaging to the facility.",
        )}
        features={[
          {
            icon: <Group />,
            text: t("Allow residents to message with approved visitors"),
          },
          {
            icon: <Search />,
            text: t("Review, approve, and monitor conversations"),
          },
          { icon: <Timer />, text: t("Add a delay to messages") },
          {
            icon: <NotInterested />,
            text: t("Provide a list of banned words"),
          },
        ]}
        learnMoreText={t("Learn more about messaging")}
        learnMoreLink={"https://www.ameelio.org/child-products/e-messaging"}
      />
    );
  }

  return (
    <>
      <Header
        title={t("Messages")}
        subtitle={t(
          "Visitors and residents may exchange messages, subject to review and approval.",
        )}
      />
      <Stack spacing={2} p={2}>
        <Card
          variant="outlined"
          sx={{ paddingX: 2.5, paddingY: 2, borderRadius: "8px" }}
        >
          <Stack spacing={1.5}>
            <SectionTitle>{t("General")}</SectionTitle>
            <NumberSettingField
              label={t("Default delay for auto-approved messages")}
              description={t(
                "Messages that are auto-approved can be sent with a slight delay. This can be customized per resident during roster upload.",
              )}
              value={loadedFacility.defaultEmessageDelay / (60 * 1000)}
              render={(value) =>
                value === 0
                  ? t("No delay")
                  : t("{{count}} minutes", { count: value })
              }
              endAdornment="minutes"
              validate={{
                atLeastZero: (v) => v >= 0,
                atMostThirty: (v) => v <= 30,
              }}
              errorMessages={{
                atLeastZero: t("Cannot be negative."),
                atMostThirty: t("Cannot set a delay of more than 30 minutes."),
              }}
              onChange={(value) =>
                updateProperty(
                  "defaultEmessageDelay",
                  Number(value) * 60 * 1000,
                )
              }
              canEdit={canEdit}
            />
          </Stack>
        </Card>
        <KeywordsTable keywords={keywords} />
      </Stack>
    </>
  );
}
