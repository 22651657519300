import {
  ConnectCall,
  ProducerLabel,
  useConnectCall,
} from "@ameelio/connect-call-client";
import { MeetingType } from "@src/api/graphql";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useLiveCalls from "@src/lib/useLiveCalls";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type Props = {
  children: React.ReactNode;
  meetingTypes?: MeetingType[];
};

type Registry = Record<string, ConnectCall>;

const CallRegistryContext = createContext<{
  registry: Registry;
  visible: Set<string>;
  setVisible: (s: Set<string>) => void;
}>({ registry: {}, setVisible: () => undefined, visible: new Set() });

export function useCallRegistry() {
  return useContext(CallRegistryContext);
}

export default function CallRegistry({ children, meetingTypes }: Props) {
  const { facility } = useGuaranteedFacilityContext();
  const liveCalls = useLiveCalls({
    facility,
    pollInterval: 30000,
    meetingTypes,
  });
  const [registry, setRegistry] = useState<Registry>({});

  const [visibleCalls, setVisibleCalls] = useState<Set<string>>(new Set());

  const register = useCallback((id: string, call: ConnectCall) => {
    setRegistry((current) => {
      return { ...current, [id]: call };
    });
  }, []);

  const drop = useCallback(
    (id: string) =>
      setRegistry((current) => {
        const { [id]: _, ...next } = current;
        return next;
      }),
    [],
  );

  const setVisible = useCallback(
    (s: Set<string>) => {
      if (
        s.size === visibleCalls.size &&
        Array.from(s).every((x) => visibleCalls.has(x))
      )
        return;

      setVisibleCalls(s);
    },
    [visibleCalls],
  );

  return (
    <CallRegistryContext.Provider
      value={{ registry, visible: new Set(visibleCalls), setVisible }}
    >
      {liveCalls.map(
        (c) =>
          c.call &&
          c.call.token &&
          c.call.url && (
            <RegisterCall
              key={c.id}
              callId={c.id}
              callToken={c.call.token}
              callUrl={c.call.url}
              register={register}
              drop={drop}
              visible={visibleCalls.has(c.id)}
            />
          ),
      )}
      {children}
    </CallRegistryContext.Provider>
  );
}

/**
 * RegisterCall is a workaround to call a hook N times and store
 * the results.
 *
 * React.memo is necessary to break useEffect/setState recursion.
 */
const RegisterCall = React.memo(
  ({
    callId,
    callToken,
    callUrl,
    visible,
    register,
    drop,
  }: {
    callId: string;
    callToken: string;
    callUrl: string;
    visible: boolean;
    register: (id: string, call: ConnectCall) => void;
    drop: (id: string) => void;
  }) => {
    // guard against changes to the call data by saving the first
    // copy of it. this fixes a loop where refreshing the list of
    // live calls generates new auth tokens, resulting in a reconnect.
    const [callCache] = useState({
      id: callId,
      token: callToken,
      url: callUrl,
    });

    const user = useCurrentStaff();
    const connectCall = useConnectCall({
      call: callCache,
      user,
    });

    // synchronize connectCall to parent context
    useEffect(() => {
      register(callCache.id, connectCall);
    }, [callCache.id, register, connectCall]);

    // By default, pause all video consumers
    useEffect(() => {
      if (visible) {
        Object.values(connectCall.peers).forEach((peer) => {
          if (peer.manualConsumerPauses.video)
            void connectCall.resumeConsumer(peer.peerId, ProducerLabel.video);
        });
      } else {
        Object.values(connectCall.peers).forEach((peer) => {
          if (!peer.manualConsumerPauses.video)
            void connectCall.pauseConsumer(peer.peerId, ProducerLabel.video);
        });
      }
    }, [visible, connectCall]);

    // cleanup
    useEffect(() => {
      return () => drop(callCache.id);
    }, [callCache.id, drop]);

    return null;
  },
);
RegisterCall.displayName = "RegisterCall";
