import { useMutation } from "@apollo/client";
import { ConnectionStatus, SystemUserStatus } from "@src/api/graphql";
import { ApproveSystemVisitorDocument } from "@src/graphql/ApproveSystemVisitor.generated";
import { UpdateConnectionStatusDocument } from "@src/graphql/UpdateConnectionStatus.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useState } from "react";
import ConnectionReviewModal, {
  ReviewableConnection,
} from "./ConnectionReviewModal";
import IdentityReviewModal from "./IdentityReviewModal";
import { RejectSystemVisitorDocument } from "./RejectSystemVisitor.generated";

type Props = {
  connection: ReviewableConnection;
  onClose: () => void;
};

type Step = "ID" | "Connection";

export default function ReviewModal({ connection, onClose }: Props) {
  const hasPendingID =
    connection.visitor.systemRelationship?.status ===
      SystemUserStatus.Pending && !!connection.visitor.identity;
  const steps: Step[] = hasPendingID ? ["Connection", "ID"] : ["Connection"];
  const [step, setStep] = useState<Step>(steps[0]);
  const lastStep = step === steps[steps.length - 1];
  const handleApolloError = useApolloErrorHandler();

  const [approveSystemVisitor] = useMutation(ApproveSystemVisitorDocument, {
    onError: handleApolloError,
  });
  const approveID = async () => {
    await approveSystemVisitor({
      variables: {
        input: {
          systemVisitorId: connection.visitor.systemRelationship?.id || "",
        },
      },
    });
  };

  const [rejectSystemVisitor] = useMutation(RejectSystemVisitorDocument, {
    onError: handleApolloError,
  });
  const rejectID = async (reason: string) => {
    await rejectSystemVisitor({
      variables: {
        input: {
          systemVisitorId: connection.visitor.systemRelationship?.id || "",
          statusDetails: reason,
        },
      },
    });
    onClose();
  };

  const [updateConnectionStatusMutation] = useMutation(
    UpdateConnectionStatusDocument,
    { onError: handleApolloError },
  );
  const approveConnection = async () => {
    await updateConnectionStatusMutation({
      variables: {
        input: {
          connectionId: connection.id,
          status: ConnectionStatus.Active,
        },
      },
    });
  };
  const rejectConnection = async (reason: string) => {
    await updateConnectionStatusMutation({
      variables: {
        input: {
          connectionId: connection.id,
          status: ConnectionStatus.Rejected,
          statusDetails: reason,
        },
      },
    });
    onClose();
  };

  if (step === "ID") {
    return (
      <IdentityReviewModal
        connection={connection}
        onCancel={onClose}
        onApprove={async () => {
          await approveID();
          await approveConnection();
          onClose();
        }}
        onReject={rejectID}
      />
    );
  }

  return (
    <ConnectionReviewModal
      connection={connection}
      lastStep={!hasPendingID}
      onCancel={onClose}
      onApprove={async () => {
        if (lastStep) {
          await approveConnection();
        } else {
          setStep(steps[steps.indexOf(step) + 1]);
        }
      }}
      onReject={rejectConnection}
    />
  );
}
