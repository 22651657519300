import { t } from "i18next";

enum StatusDetails {
  CALL_IDLE = "call_idle",
  CALL_REACHED_MAX_DURATION = "call_reached_max_duration",
  CALL_REACHED_SCHEDULED_END = "call_reached_scheduled_end",
  NO_SHOW = "no_show",
}

export default function statusDetailsLabel(status: StatusDetails | string) {
  const statuses: Record<StatusDetails | string, string> = {
    [StatusDetails.CALL_IDLE]: t("Call disconnected due to network issues"),
    [StatusDetails.CALL_REACHED_MAX_DURATION]: t(
      "Call reached maximum available duration",
    ),
    [StatusDetails.CALL_REACHED_SCHEDULED_END]: "–",
    [StatusDetails.NO_SHOW]: "–",
  };
  const key = (status || "").toLowerCase();
  return statuses[key] !== undefined ? statuses[key] : status;
}
