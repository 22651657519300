import { Button } from "@ameelio/ui";
import { Link, Stack, Typography } from "@mui/material";
import Alert from "@src/lib/Alert";
import { STAFF_HELP_ARTICLES_URL } from "@src/lib/constants";
import { theme } from "@src/theme";
import { Buffer } from "buffer";
import { parse } from "csv-parse/browser/esm";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import stripBom from "strip-bom";

type Props = {
  onDrop: (csv: Record<string, unknown>[]) => void;
  onError: (e: Error) => void;
};

const readFile = (file: File): Promise<Buffer> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onabort = () => reject(new Error("file read aborted"));
    reader.onerror = () => reject(new Error("failed to read"));
    reader.onload = () =>
      reader.result
        ? resolve(Buffer.from(reader.result as ArrayBuffer))
        : reject(new Error("empty"));
    reader.readAsArrayBuffer(file);
  });
};

const parseAsCSV = async (
  contents: string,
): Promise<Record<string, unknown>[]> => {
  return new Promise((resolve, reject) => {
    parse(
      contents,
      {
        columns: true,
        delimiter: ["\t", ","],
        skipEmptyLines: true,
        skipRecordsWithEmptyValues: true,
      },
      (err, data: Record<string, unknown>[]) => {
        if (data && !err) resolve(data);
        reject(err || new Error("failed to parse"));
      },
    );
  });
};

const parseAsJSON = (contents: string): Record<string, unknown>[] => {
  try {
    const json: unknown = JSON.parse(contents);
    if (typeof json === "object" && json && "inmates" in json)
      return json.inmates as Record<string, unknown>[];
    return [];
  } catch {
    return [];
  }
};

const isJSON = (contents: string) => contents.slice(0, 1) === "{";

const RosterDropzone: React.FC<Props> = ({ onDrop, onError }) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: ["text/csv", "application/json"],
      maxFiles: 1,
      onDropAccepted: ([file]) =>
        void readFile(file)
          .then((data) => {
            const clean = stripBom(data.toString("utf8"));
            return isJSON(clean) ? parseAsJSON(clean) : parseAsCSV(clean);
          })
          .then(onDrop, onError),
    });

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        {t(
          "Roster files may be in a JSON or CSV format and will be fully validated before processing.",
        )}
      </Typography>

      <Button
        variant="outlined"
        {...getRootProps()}
        sx={{
          height: theme.spacing(16),
          w: 1,
          color: "text.primary",
          borderColor: "divider",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive
          ? t("Drop your .json or .csv roster here")
          : t(
              "Drag a roster file here, or click to select it from your computer",
            )}
        {fileRejections.length > 0 && (
          <Alert severity="error">{fileRejections[0].errors[0].message}</Alert>
        )}
      </Button>

      <Link
        href={`${STAFF_HELP_ARTICLES_URL}/hc/en-us/sections/13858030881037-Resident-accounts-in-Ameelio`}
        target="_blank"
        rel="noreferrer"
      >
        {t("Review documentation")}
      </Link>
    </Stack>
  );
};

export default RosterDropzone;
