import { Tooltip } from "@ameelio/ui";
import { Box, Card, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";

type Props = {
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  unavailableReason?: string;
};

export default function AvailabilityTile({
  selected,
  onClick,
  children,
  disabled,
  unavailableReason,
}: Props) {
  return (
    <Tooltip title={unavailableReason || ""}>
      <Card
        component={Box}
        elevation={0}
        tabIndex={0}
        sx={{
          padding: 2,
          textAlign: "center",
          border: 1,
          borderColor: selected ? blue[900] : "rgba(0 0 0 / .12)",
          borderRadius: (theme) => theme.spacing(1),
          cursor: disabled ? undefined : "pointer",
          backgroundColor: selected
            ? blue[100]
            : disabled
              ? "rgba(0 0 0 / .03)"
              : undefined,
          textDecoration: disabled ? "line-through" : undefined,
        }}
        onClick={disabled ? undefined : onClick}
        onKeyDown={(event) => {
          if (!disabled && event.key === "Enter") {
            onClick();
          }
        }}
      >
        <Typography
          variant="button"
          color={selected ? blue[900] : "text.primary"}
        >
          {children}
        </Typography>
      </Card>
    </Tooltip>
  );
}
