import { groupBy, partition } from "@ameelio/core";
import {
  SelectInputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  TextInputBase,
  Tooltip,
  usePagination,
} from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import {
  LockOutlined,
  SearchOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";
import { Box, SelectChangeEvent, Stack, Typography } from "@mui/material";
import {
  Entitlement,
  FacilityFeature,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";
import { alphabetize } from "@src/lib/Common";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import InmateLink from "@src/lib/InmateLink";
import featureForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import NotAllowed from "@src/lib/NotAllowed";
import NotEnabled from "@src/lib/NotEnabled";
import PageLoader from "@src/lib/PageLoader";
import { searchEachBeginningRegex } from "@src/lib/regex";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import { formatDate, formatTimeRange } from "@src/lib/Time";
import useEntitlement from "@src/lib/useEntitlement";
import useFacilityFeature from "@src/lib/useFacilityFeature";
import useMountedPolling from "@src/lib/useMountedPolling";
import VisitorList from "@src/lib/VisitorList";
import VisitTypeIcon from "@src/lib/VisitTypeIcon";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import AcceptMeetingButton from "./AcceptMeetingButton";
import DeclineMeetingButton from "./DeclineMeetingButton";
import {
  GetMeetingRequestsDocument,
  GetMeetingRequestsQuery,
} from "./GetMeetingRequests.generated";
import useMeetingFiltersState from "./useMeetingFiltersState";

type Meeting =
  GetMeetingRequestsQuery["facility"]["meetings"]["edges"][0]["node"];

type Props = {
  showProfessional: boolean;
};

const availableMeetingTypes: MeetingType[] = [
  MeetingType.VideoCall,
  MeetingType.InPersonVisit,
  MeetingType.Webinar,
];

const MeetingRequestsPage = ({ showProfessional }: Props) => {
  const { facility } = useGuaranteedFacilityContext();
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  const { t } = useTranslation();

  const groupedMeetingTypes = groupBy(availableMeetingTypes, (mt) =>
    facility.features.includes(featureForMeetingType(mt))
      ? "enabled"
      : "disabled",
  );
  const sortedMeetingTypes = [
    ...alphabetize(groupedMeetingTypes.enabled || []),
    ...alphabetize(groupedMeetingTypes.disabled || []),
  ];

  const personalTitle = t("Meeting requests");
  const personalSubtitle = t(
    "Review all meeting requests between residents at your facility and their loved ones on the outside.",
  );
  const professionalTitle = t("Confidential meeting requests");
  const professionalSubtitle = t(
    "Review all meeting requests between residents at your facility and their professional contacts on the outside",
  );
  const handleApolloError = useApolloErrorHandler();

  const { data, loading, startPolling, stopPolling } = useQuery(
    GetMeetingRequestsDocument,
    {
      fetchPolicy: "cache-and-network",
      onError: handleApolloError,
      variables: { facilityId: facility.id },
    },
  );
  useMountedPolling(30000, startPolling, stopPolling);

  const [filters, setFilters] = useMeetingFiltersState();

  // only show appropriate meetings, and make sure that new meeting
  // requests appear at the end of the list
  const seenMeetingIds = useRef<string[]>([]);
  const meetings = useMemo(() => {
    if (!data) return [];
    const requirements: ((m: Meeting) => boolean)[] = [
      (m) => m.status === MeetingStatus.PendingApproval,
      (m) => {
        if (showProfessional) {
          return m.privacyLevel === PrivacyLevel.UnmonitoredLogged;
        } else {
          return m.privacyLevel === PrivacyLevel.Monitored;
        }
      },
    ];
    if (filters.residentName) {
      const regex = searchEachBeginningRegex(filters.residentName);
      requirements.push((m) => m.inmates.some((i) => regex.test(i.fullName)));
    }

    if (filters.meetingType) {
      requirements.push((m) =>
        (filters.meetingType || availableMeetingTypes).includes(m.meetingType),
      );
    }

    const meetings = data.facility.meetings.edges
      .map((e) => e.node)
      .reverse()
      .filter((m) => requirements.every((r) => r(m)));

    // sort new requests to the end
    const [seen, unseen] = partition(meetings, (m) =>
      seenMeetingIds.current.includes(m.id),
    );
    return [
      ...seen.sort(
        (a, b) =>
          seenMeetingIds.current.indexOf(a.id) -
          seenMeetingIds.current.indexOf(b.id),
      ),
      ...unseen,
    ];
  }, [data, showProfessional, filters]);

  // track seen meetings so we can identify new ones in a future loop
  useEffect(() => {
    seenMeetingIds.current = meetings.map((m) => m.id);
  }, [meetings]);

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: meetings,
    resetOnChange: [filters],
  });

  const hasProviders = useFacilityFeature(FacilityFeature.Providers);
  if (showProfessional && !hasProviders) return <NotEnabled />;

  if (loading) return <PageLoader />;

  return (
    <Box>
      <Header
        title={showProfessional ? professionalTitle : personalTitle}
        subtitle={showProfessional ? professionalSubtitle : personalSubtitle}
      >
        <ResponsiveColumns>
          <TextInputBase
            size="small"
            label={t("Resident name")}
            value={filters.residentName}
            onChange={(e) =>
              setFilters((current) => ({
                ...current,
                residentName: e.target.value,
              }))
            }
            InputProps={{
              endAdornment: <SearchOutlined sx={{ color: "text.secondary" }} />,
            }}
          />
          <SelectInputBase
            disabled={loading}
            size="small"
            aria-label={t("Filter by meeting type")}
            placeholder={t("Filter by meeting type")}
            label={t("Meeting type")}
            onChange={(event: SelectChangeEvent<unknown>) => {
              const value = event.target.value;
              setFilters((current) => ({
                ...current,
                meetingType:
                  value && value !== "All" ? [value as MeetingType] : undefined,
              }));
            }}
            value={filters.meetingType?.[0] || "All"}
            items={[
              { value: "All", name: t("All") },
              ...sortedMeetingTypes.map((mt) => ({
                value: mt,
                name: meetingTypeTitle([mt]),
                disabled: !facility.features.includes(
                  featureForMeetingType(mt),
                ),
              })),
            ]}
          />
        </ResponsiveColumns>
      </Header>
      {!canManageVisitorsAndMeetings ? (
        <NotAllowed />
      ) : (
        <Box p={3}>
          <TableContainer>
            <Table aria-label={t("Meeting requests table")}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Time")}</TableCell>
                  <TableCell>{t("Visitors")}</TableCell>
                  <TableCell>{t("Resident")}</TableCell>
                  {showProfessional && <TableCell>{t("Privacy")}</TableCell>}
                  <TableCell>{t("Kiosk")}</TableCell>
                  <TableCell>{t("Meeting type")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {meetings.length === 0 && <TableNoData colSpan={6} />}
                {meetings.length > 0 &&
                  currentPageData.map((meeting) => {
                    return (
                      <TableRow key={meeting.id}>
                        <TableCell>
                          <Typography variant="body2" component="span">
                            {formatDate(
                              new Date(meeting.interval.startAt),
                              "dateyear",
                            )}
                            <br />
                            {formatTimeRange(
                              meeting.interval.startAt,
                              meeting.interval.endAt,
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <VisitorList
                            visitors={meeting.visitors}
                            unregisteredGuests={meeting.unregisteredGuests}
                            combineMinorGuests={
                              data?.facility.province === "Iowa"
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Stack spacing={1}>
                            {meeting.inmates.map((inmate) => (
                              <InmateLink key={inmate.id} inmate={inmate} />
                            ))}
                          </Stack>
                        </TableCell>
                        {showProfessional && (
                          <TableCell>
                            <>
                              {meeting.privacyLevel === PrivacyLevel.Hidden && (
                                <Tooltip
                                  title={t(
                                    "This video call cannot be monitored by facility staff and no records will be kept",
                                  )}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <LockOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Confidential")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                              {meeting.privacyLevel ===
                                PrivacyLevel.Monitored && (
                                <Tooltip
                                  title={t("This video call will be monitored")}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <VideoCameraFrontOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Monitored")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                              {meeting.privacyLevel ===
                                PrivacyLevel.UnmonitoredLogged && (
                                <Tooltip
                                  title={t(
                                    "This video call cannot be monitored by facility staff, but some records will be kept",
                                  )}
                                >
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      width: "fit-content",
                                    }}
                                  >
                                    <LockOutlined />{" "}
                                    <span style={{ verticalAlign: "middle" }}>
                                      {t("Unmonitored")}
                                    </span>
                                  </Box>
                                </Tooltip>
                              )}
                            </>
                          </TableCell>
                        )}
                        <TableCell>
                          <span>{meeting.kiosk?.name}</span>
                        </TableCell>
                        <TableCell>
                          <VisitTypeIcon type={meeting.meetingType} />
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <DeclineMeetingButton meeting={meeting} />
                            <AcceptMeetingButton meeting={meeting} />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            totalCount={meetings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default MeetingRequestsPage;
