import { ContextMenu } from "@ameelio/ui";
import { Box, Card, MenuItem, Stack, Typography } from "@mui/material";
import { Entitlement } from "@src/api/graphql";
import { GetFacilitySchedulesQuery } from "@src/graphql/GetFacilitySchedules.generated";
import { getDayLetters } from "@src/lib/Time";
import useEntitlement from "@src/lib/useEntitlement";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditScheduleModal from "./AddEditScheduleModal";
import DeleteScheduleModal from "./DeleteScheduleModal";

type Schedule = GetFacilitySchedulesQuery["facility"]["schedules"][number];

type Props = {
  schedule: Schedule;
};

const DAY_LETTERS = getDayLetters();

export default function ScheduleCard({ schedule }: Props) {
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const canEdit = useEntitlement(Entitlement.ManageFacility);
  const { t } = useTranslation();

  const { name } = schedule;
  const days = schedule.meetingSlots.map((slot) => slot.day);
  const kioskNames = schedule.kiosks.map((k) => k.name);
  const groupNames = schedule.groups.map((g) => g.name);

  const [isDeletingSchedule, setIsDeletingSchedule] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        paddingX: 2,
        paddingY: 1.5,
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle1" component="label">
            {name}
          </Typography>
        </Box>
        <ContextMenu id={`${schedule.id}-actions`}>
          {canEdit
            ? [
                <MenuItem
                  key="edit"
                  onClick={() => {
                    setIsEditingSchedule(true);
                  }}
                >
                  {t("Edit schedule")}
                </MenuItem>,
                <MenuItem
                  key="delete"
                  onClick={() => {
                    setIsDeletingSchedule(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.error.dark,
                  }}
                >
                  {t("Delete schedule")}
                </MenuItem>,
              ]
            : [
                <MenuItem
                  key="details"
                  onClick={() => {
                    setIsEditingSchedule(true);
                  }}
                >
                  {t("Details")}
                </MenuItem>,
              ]}
        </ContextMenu>
      </Box>
      <Box display="flex" gap={4} flexDirection={{ xs: "column", sm: "row" }}>
        <Stack maxWidth={240}>
          <Box>
            <Typography variant="overline" color="text.secondary">
              {t("Days")}
            </Typography>
          </Box>
          <Box display="flex">
            {DAY_LETTERS.map((l, index) => (
              <Box
                key={`${l}_${index}`}
                sx={{
                  margin: 0.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: 24,
                  minHeight: 24,
                  minWidth: 24,
                  maxWidth: 24,
                  borderRadius: 30,
                  backgroundColor: days.includes(index as Day)
                    ? (theme) => theme.palette.primary.main
                    : "#EEEEEE",
                }}
              >
                <Typography
                  variant="body2"
                  color={
                    days.includes(index as Day) ? "#FFFFFF" : "text.disabled"
                  }
                >
                  {l}
                </Typography>
              </Box>
            ))}
          </Box>
        </Stack>
        {!!kioskNames.length && (
          <Stack>
            <Box>
              <Typography variant="overline" color="text.secondary">
                {t("Kiosks")}
              </Typography>
            </Box>
            <Box>
              {(kioskNames.length <= 3
                ? kioskNames
                : kioskNames.slice(0, 2)
              ).map((k, index) => (
                <Typography key={`${k}_${index}`}>{k}</Typography>
              ))}
              {kioskNames.length > 3 &&
                t("+ {{number}} more", { number: kioskNames.length - 2 })}
            </Box>
          </Stack>
        )}
        {!!groupNames.length && (
          <Stack>
            <Box>
              <Typography variant="overline" color="text.secondary">
                {t("Resident locations")}
              </Typography>
            </Box>
            <Box>
              {(groupNames.length <= 3
                ? groupNames
                : groupNames.slice(0, 2)
              ).map((g, index) => (
                <Typography key={`${g}_${index}`}>{g}</Typography>
              ))}
              {groupNames.length > 3 &&
                t("+ {{number}} more", { number: groupNames.length - 2 })}
            </Box>
          </Stack>
        )}
      </Box>
      {isDeletingSchedule && (
        <DeleteScheduleModal
          schedule={schedule}
          onClose={() => setIsDeletingSchedule(false)}
        />
      )}
      {isEditingSchedule && (
        <AddEditScheduleModal
          schedule={schedule}
          meetingType={schedule.meetingType}
          privacyLevel={schedule.privacyLevels[0]}
          onClose={() => setIsEditingSchedule(false)}
        />
      )}
    </Card>
  );
}
