import { useQuery } from "@apollo/client";
import {
  CheckCircleOutlined,
  LockOutlined,
  NumbersOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { FacilityFeature } from "@src/api/graphql";
import Header from "@src/lib/Header";
import PhoneNumberTable from "@src/lib/PhoneNumberTable";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import UnavailableSplashScreen from "@src/lib/UnavailableSplashScreen";
import { useTranslation } from "react-i18next";
import { GetFacilityWithPhonesDocument } from "./GetFacilityWithPhones.generated";

export default function PhoneCallsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { data } = useQuery(GetFacilityWithPhonesDocument, {
    variables: { facilityId: facility.id || "" },
  });

  const { t } = useTranslation();

  if (!data) return <SettingsSkeleton />;

  const { facility: loadedFacility } = data;

  if (!loadedFacility.features.includes(FacilityFeature.PhoneCall)) {
    return (
      <UnavailableSplashScreen
        header={t("Outside services")}
        subHeader={t(
          "Contact us about adding outside calling to the facility.",
        )}
        features={[
          {
            icon: <NumbersOutlined />,
            text: t("Give every resident access to important phone numbers"),
          },
          {
            icon: <LockOutlined />,
            text: t("These calls can be monitored or unmonitored"),
          },
          {
            icon: <CheckCircleOutlined />,
            text: t(
              "Easily works with any service that already has a phone number",
            ),
          },
        ]}
      />
    );
  }

  return (
    <>
      <Header
        title={t("Outside services")}
        subtitle={t(
          "Residents may dial approved telephone numbers from Ameelio Connect.",
        )}
      />
      <Stack spacing={2} p={2}>
        <PhoneNumberTable
          phoneNumbers={loadedFacility.approvedPhones
            .filter((p) => p.active)
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      </Stack>
    </>
  );
}
