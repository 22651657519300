import { GetInmateQuery } from "../InmatePage/GetInmate.generated";
import { GetMeetingDetailsQuery } from "../MeetingDetailsPage/GetMeetingDetails.generated";
import {
  Availability,
  AvailableKiosk,
  Data,
  RegisteredGuest,
  UnregisteredGuest,
} from "./types";

// Normalize the data combing from the inmate and meeting details query
export default function normalizeGuestListData(
  meetingData: GetMeetingDetailsQuery["meeting"],
  inmateData: GetInmateQuery["inmate"],
): Data {
  const availableKiosk: AvailableKiosk | undefined = meetingData.kiosk
    ? {
        id: meetingData.kiosk.id,
        name: meetingData.kiosk.name,
        capacity: meetingData.kiosk.capacity,
        enabled: meetingData.kiosk.enabled,
      }
    : undefined;

  const availability: Availability = {
    interval: {
      startAt: meetingData.interval.startAt,
      endAt: meetingData.interval.endAt,
    },
    availableKiosks: availableKiosk ? [availableKiosk] : [],
    conflict: false,
  };

  const registeredGuests: RegisteredGuest[] = meetingData.visitors.map(
    (visitor) => {
      const connection = inmateData.connections.find(
        (c) => c.visitor.id === visitor.id,
      );
      if (!connection) {
        throw new Error(`Connection not found for visitor ${visitor.id}`);
      }
      return {
        id: visitor.id,
        fullName: visitor.fullName,
        connectionId: connection.id,
        organizationMembership: connection.organizationMembership,
      };
    },
  );

  const unregisteredGuests: UnregisteredGuest[] =
    meetingData.unregisteredGuests.map((name) => ({ name }));

  return {
    kiosk: availableKiosk,
    availability,
    meetingType: meetingData.meetingType,
    registeredGuests,
    unregisteredGuests,
  };
}
