import { ContextMenu } from "@ameelio/ui";
import { Entitlement, MeetingStatus, MeetingType } from "@src/api/graphql";
import EndMeetingButton from "@src/lib/EndMeetingButton";
import mayHaveRecording from "@src/lib/mayHaveRecording";
import mayHaveRecords from "@src/lib/mayHaveRecords";
import NoShowMeetingButton from "@src/lib/NoShowMeetingButton";
import MeetingsTableMeeting from "@src/lib/Profiles/MeetingsTableMeeting";
import RequestRecordingButton from "@src/lib/RequestRecordingButton";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import ViewRecordsButton from "@src/lib/ViewRecordsButton";
import DeclineMeetingButton from "@src/pages/MeetingRequestsPage/DeclineMeetingButton";
import { useTranslation } from "react-i18next";
import useNow from "./useNow";

export default function MeetingContextMenu({
  meeting,
}: {
  meeting: MeetingsTableMeeting;
}) {
  const now = useNow({ updateDelay: 5000 });
  const { staffPositions } = useCurrentStaff();

  const canReviewRecordings = useEntitlement(Entitlement.ReviewRecordings);
  const canReviewScreenshots = useEntitlement(Entitlement.ReviewScreenshots);
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  const hasPositionsInFacility = staffPositions.some(
    (sp) => sp.facility.id === meeting.facility.id,
  );

  const allowedToAccessRecordings =
    canReviewRecordings && hasPositionsInFacility;
  const allowedToAccessScreenshots =
    canReviewScreenshots && hasPositionsInFacility;
  const allowedToAccessRecords =
    allowedToAccessRecordings || allowedToAccessScreenshots;

  const { t } = useTranslation();

  const MenuItems = [
    mayHaveRecording(meeting) && (
      <RequestRecordingButton
        key="action-request-recording"
        disabled={!canReviewRecordings}
        disabledReason={
          !canReviewRecordings
            ? t("You are not allowed to request recordings.")
            : undefined
        }
        displayAsMenuItem
        meeting={meeting}
        showView
      />
    ),
    mayHaveRecords(meeting) && (
      <ViewRecordsButton
        key="action-view-records"
        disabled={!allowedToAccessRecords}
        disabledReason={
          !allowedToAccessRecords
            ? t("You are not allowed to access records")
            : undefined
        }
        displayAsMenuItem
        meeting={meeting}
      />
    ),
    (meeting.status === MeetingStatus.Scheduled ||
      // If the meeting is live but the scheduled start
      // hasn't arrived yet, still show the decline button
      (meeting.status === MeetingStatus.Live &&
        now.getTime() < meeting.interval.startAt)) && (
      <DeclineMeetingButton
        key="action-decline-meeting"
        disabled={!canManageVisitorsAndMeetings}
        displayAsMenuItem
        meeting={meeting}
      />
    ),
    meeting.status === MeetingStatus.Live &&
      // Only show the end meeting button if the meeting
      // is live AND its scheduled start has been reached
      now.getTime() >= meeting.interval.startAt &&
      meeting.meetingType !== MeetingType.InPersonVisit && (
        <EndMeetingButton
          key="action-end-meeting"
          disabled={!canManageVisitorsAndMeetings}
          displayAsMenuItem
          meeting={meeting}
        />
      ),
    meeting.status === MeetingStatus.Ended &&
      meeting.meetingType === MeetingType.InPersonVisit && (
        <NoShowMeetingButton
          key="set-meeting-unattended"
          disabled={!canManageVisitorsAndMeetings}
          displayAsMenuItem
          meeting={meeting}
        />
      ),
  ].filter(Boolean);

  return MenuItems.length > 0 ? (
    <ContextMenu id={`${meeting.id}-actions`}>{MenuItems}</ContextMenu>
  ) : null;
}
