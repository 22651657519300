import { ConnectCall, Role } from "@ameelio/connect-call-client";
import { useQuery } from "@apollo/client";
import {
  Entitlement,
  Facility,
  FacilityFeature,
  MeetingType,
} from "@src/api/graphql";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useCallRegistry } from "@src/lib/LiveCall/CallRegistry";
import {
  GetLiveCallsDocument,
  GetLiveCallsQuery,
} from "@src/pages/LiveCallPage/GetLiveCalls.generated";
import { useMemo } from "react";
import featureForMeetingType from "./meeting";
import useEntitlement from "./useEntitlement";
import useMountedPolling from "./useMountedPolling";

// 5 minutes
export const CALL_STARTUP_LEAD_TIME = 5 * 60 * 1000;

export type Call =
  GetLiveCallsQuery["facility"]["meetings"]["edges"][number]["node"];
type CallFacility = Pick<Facility, "id" | "features">;

// a call is considered starting up if it has no one connected and doesn't start for CALL_STARTUP_LEAD_TIME
export function startup(c: Call, cc: ConnectCall | undefined): boolean {
  const participants = cc
    ? Object.values(cc.peers).filter((p) => p.user.role !== Role.monitor).length
    : 0;
  if (participants > 0) return false;

  return Date.now() < c.interval.startAt - CALL_STARTUP_LEAD_TIME;
}

// a call is considered shutting down if CVH has a finished status that hasn't been reflected
// in the API yet
export function shutdown(cc: ConnectCall | undefined): boolean {
  return ["ended", "terminated"].includes(cc?.callStatus || "");
}

export default function useLiveCalls({
  facility,
  pollInterval,
  meetingTypes,
}: {
  facility: CallFacility | undefined;
  pollInterval?: number;
  meetingTypes?: MeetingType[];
}): Call[] {
  const canMonitorLiveCalls = useEntitlement(Entitlement.MonitorLiveCalls);
  const supportedMeetingTypes = (() => {
    const types = [];
    // the idea below is to include all meeting types that the
    // facility offers IF no argument for meeting types is provided
    if (
      facility?.features.includes(FacilityFeature.VideoCall) ||
      facility?.features.includes(FacilityFeature.ConfidentialVideoCall)
    )
      types.push(MeetingType.VideoCall);
    if (facility?.features.includes(FacilityFeature.Webinar))
      types.push(MeetingType.Webinar);
    if (facility?.features.includes(FacilityFeature.VoiceCall))
      types.push(MeetingType.VoiceCall);
    if (
      facility?.features.includes(FacilityFeature.InPersonVisit) ||
      facility?.features.includes(FacilityFeature.ConfidentialInPersonVisit)
    )
      types.push(MeetingType.InPersonVisit);
    return types;
  })();

  // the reason to validate here is because an arg
  // in `meetingTypes` may include a meeting type
  // that the facility does not offer
  const shouldSkipQuery = (() => {
    // the staff user MUST have the entitlement
    if (!canMonitorLiveCalls) return true;
    // if the facility offers none of video, voice,
    // or webinar calls, we can skip the query
    if (!supportedMeetingTypes.length) return true;
    // if `meetingTypes` was provided, validate that
    // the facility supports at least one of the included
    // types in order to not skip the query
    if (meetingTypes?.length)
      return !meetingTypes.some((mt) =>
        facility?.features.includes(featureForMeetingType(mt)),
      );
    return false;
  })();

  const { registry } = useCallRegistry();
  const handleApolloError = useApolloErrorHandler();
  const { data, startPolling, stopPolling } = useQuery(GetLiveCallsDocument, {
    onError: handleApolloError,
    variables: {
      facilityId: facility?.id || "",
      meetingTypes: supportedMeetingTypes,
    },
    skip: !facility || shouldSkipQuery,
    fetchPolicy: "cache-and-network",
  });
  useMountedPolling(pollInterval, startPolling, stopPolling);

  return useMemo(() => {
    // Default meeting types to filter on
    const filterMeetingTypes = meetingTypes || supportedMeetingTypes;
    return data
      ? data.facility.meetings.edges
          .map((e) => e.node)
          .filter((n) => filterMeetingTypes.includes(n.meetingType))
          .reverse()
          .filter(
            (m) => !startup(m, registry[m.id]) && !shutdown(registry[m.id]),
          )
      : [];
  }, [data, meetingTypes, registry, supportedMeetingTypes]);
}
