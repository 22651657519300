import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextInput,
} from "@ameelio/ui";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Facility, Inmate, Visitor } from "@src/api/graphql";
import { TypographyText } from "@src/lib/typography";
import { isRequired, isRequiredString } from "@src/lib/validate";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Alert from "../../Alert";

export type ConnectionRejectionReason = {
  title: string;
  description: string;
  key: "bad_id" | "not_approved_visitor" | "invalid_info" | "other";
};

interface Props {
  onSubmit: (feedback: string) => void;
  onClose: () => void;
  connection: {
    inmate: Pick<Inmate, "fullName">;
  } & {
    visitor: Pick<Visitor, "fullName" | "dateOfBirth">;
  };
  facility: Pick<Facility, "publicId" | "visitationFormLink">;
}

type FormData = {
  reason: string;
  customReason: string;
};

const DisableConnectionModal = ({
  onSubmit,
  onClose,
  connection,
  facility,
}: Props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      reason: "",
      customReason: "",
    },
  });
  // we need the reason to reveal the text box for a custom reason
  const reason = watch("reason");
  const isCustomReason = reason === "other";

  const doSubmit = (data: FormData) => {
    if (isCustomReason) {
      onSubmit(`${CUSTOM_HEADER} ${data.customReason}`);
    } else {
      const desc =
        DEFAULT_CONNECTION_REJECTION_REASONS.find((r) => r.key === reason)
          ?.description || "";
      onSubmit(desc);
    }
    onClose();
  };

  const CUSTOM_HEADER = t(
    "Unfortunately, we must remove approval for the following reason:",
  );

  const DEFAULT_CONNECTION_REJECTION_REASONS: ConnectionRejectionReason[] = [
    {
      title: "Not an approved visitor",
      description: `In order to visit ${
        connection.inmate.fullName
      }, you need to fill out a visitor application. ${
        facility.publicId
      }’s records indicate that you haven’t completed this application or been approved. Once you are approved as a visitor, you will be able to submit a contact request. ${
        facility.visitationFormLink
          ? `You can find more information on how to do this at this link: ${facility.visitationFormLink}`
          : ""
      }`,
      key: "not_approved_visitor",
    },
    {
      title: "Inaccurate/incomplete information",
      description:
        "You need to fill out all of the information on the visitor application (including fields like your DOB and phone number). Please fill in all of this information and resubmit the contact request.",
      key: "invalid_info",
    },
    {
      title: "Other",
      description: "",
      key: "other",
    },
  ];

  return (
    <Dialog title={t("Remove approval?")} onClose={onClose}>
      <DialogContent sx={{ pt: 0 }}>
        <form onSubmit={handleSubmit(doSubmit)}>
          <Stack spacing={2}>
            <Alert severity="warning">
              {t(
                "This visitor approval will become inactive and may only be restored by a new request from the visitor.",
              )}
            </Alert>
            <TypographyText>
              {t(
                "Please provide a reason that will inform {{inmateFullName}} and {{visitorFullName}} about the change:",
                {
                  inmateFullName: connection.inmate.fullName,
                  visitorFullName: connection.visitor.fullName,
                },
              )}
            </TypographyText>
            <Controller
              control={control}
              name="reason"
              rules={isRequired(t("Please select a reason."))}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {DEFAULT_CONNECTION_REJECTION_REASONS.map((reason) => (
                    <FormControlLabel
                      key={reason.key}
                      value={reason.key}
                      control={<Radio />}
                      label={
                        <Typography variant="body2" color="text.primary">
                          {reason.title}
                        </Typography>
                      }
                      sx={{
                        m: 0,
                      }}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {isCustomReason && (
              <Stack spacing={1}>
                <TypographyText>{CUSTOM_HEADER}</TypographyText>
                <TextInput
                  control={control}
                  name="customReason"
                  multiline
                  rows={3}
                  autoFocus
                  rules={isRequiredString(t("Please provide a reason."))}
                />
                <TypographyText type="secondary">
                  {t(
                    "The message will be shared with {{inmateFullName}} and {{visitorFullName}}.",
                    {
                      inmateFullName: connection.inmate.fullName,
                      visitorFullName: connection.visitor.fullName,
                    },
                  )}
                </TypographyText>
              </Stack>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="outlined"
          disabled={!isValid}
          color="error"
          onClick={handleSubmit(doSubmit)}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableConnectionModal;
