import { Button, SelectInput, TextInput } from "@ameelio/ui";
import { Box, Stack, Typography } from "@mui/material";
import { MessageType } from "@src/api/graphql";
import { Message } from "@src/lib/Message";
import { isRequiredString } from "@src/lib/validate";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  onApprove: () => void;
  onReject: (reason: string, internalComment: string) => void;
  totalFlaggedMessages: number;
  loading?: boolean;
  message: Message;
}

const BASE_REJECTION_OPTIONS = ["violates the terms of service"];

const TEXT_REJECTION_OPTIONS = [
  ...BASE_REJECTION_OPTIONS,
  "contains prohibited language",
];
const PHOTO_REJECTION_OPTIONS = [
  ...BASE_REJECTION_OPTIONS,
  "shows prohibited content",
];

type FormData = {
  publicReason: string;
  internalComments: string;
};

export default function MessageReviewControlPanel({
  onApprove,
  onReject,
  totalFlaggedMessages,
  loading,
  message,
}: Props) {
  const [isDraftingRejection, setIsDraftingRejection] = useState(false);
  const { t } = useTranslation();

  const {
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      publicReason: "",
      internalComments: "",
    },
  });

  const rejectionOptions =
    message.type === MessageType.Document || !message.fileUrl
      ? TEXT_REJECTION_OPTIONS
      : PHOTO_REJECTION_OPTIONS;

  const onRejectConfirm = () => {
    const publicReason = getValues("publicReason");
    const internalComments = getValues("internalComments");
    onReject(publicReason, internalComments);
    setIsDraftingRejection(false);
    reset();
  };

  const RejectionDraft = () => {
    return (
      <Stack spacing={2}>
        <Box>
          <SelectInput
            size="small"
            control={control}
            name="publicReason"
            label={t("Note to sender")}
            rules={isRequiredString(
              t("Provide a note to the person who sent this message"),
            )}
            items={rejectionOptions.map((reason) => {
              return {
                name: reason,
                value: reason,
              };
            })}
          />
        </Box>
        <Box>
          <TextInput
            fullWidth
            size="small"
            control={control}
            name="internalComments"
            label={t("Internal comments")}
            helperText={t(
              "Please provide a note for facility staff about this rejection. Only visible to facility staff",
            )}
            rules={isRequiredString(
              t("Please add a comment describing the need for this decision"),
            )}
          />
        </Box>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setIsDraftingRejection(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onRejectConfirm}
            disabled={!isValid}
          >
            {t("Confirm")}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{
        p: 2,
        borderTopWidth: 1,
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
      }}
    >
      <Typography variant="body2" sx={{ textAlign: "right" }}>
        {t("Pending Message: 1 of {{number}}", {
          number: totalFlaggedMessages,
        })}
      </Typography>
      {isDraftingRejection ? (
        <RejectionDraft />
      ) : (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            size="small"
            onClick={() => setIsDraftingRejection(true)}
            disabled={loading}
            color="error"
            variant={"outlined"}
          >
            {t("Reject")}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={onApprove}
            disabled={loading}
          >
            {t("Approve")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
