import * as Types from '../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RejectOrTerminateMeetingMutationVariables = Types.Exact<{
  input: Types.RejectOrTerminateMeetingInput;
}>;


export type RejectOrTerminateMeetingMutation = { __typename: 'Mutation', rejectOrTerminateMeeting: { __typename: 'RejectOrTerminateMeetingResponse', success: boolean } };


export const RejectOrTerminateMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectOrTerminateMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RejectOrTerminateMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rejectOrTerminateMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<RejectOrTerminateMeetingMutation, RejectOrTerminateMeetingMutationVariables>;