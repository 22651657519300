import { Breadcrumbs as Base, Link } from "@mui/material";
import { TypographyText } from "@src/lib/typography";
import { useAppNavigate } from "@src/lib/useAppNavigate";
import { Path } from "@src/pages/routes";

type Props = {
  onClick?: (path: Path) => void;
  paths: {
    path: Path;
    label: string;
  }[];
};

export default function Breadcrumbs({ paths, onClick }: Props) {
  const navigate = useAppNavigate();

  return (
    <>
      <Base aria-label="Breadcrumb">
        {paths.map((p, idx) =>
          idx === paths.length - 1 ? (
            <TypographyText key={p.path} color="text.primary">
              {p.label}
            </TypographyText>
          ) : (
            <Link
              variant="body2"
              key={p.path}
              color="text.secondary"
              underline="none"
              onClick={() => {
                if (onClick) onClick(p.path);
                navigate(p.path);
              }}
            >
              {p.label}
            </Link>
          ),
        )}
      </Base>
    </>
  );
}
