import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableNoData,
  TablePagination,
  TableRow,
  usePagination,
} from "@ameelio/ui";
import AgeGroupTag from "@src/lib/AgeGroupTag";
import { isMinorWithoutID } from "@src/lib/Common";
import connectionRelationshipLabel from "@src/lib/connectionRelationshipLabel";
import OrganizationCell from "@src/lib/OrganizationCell";
import SystemRelationshipStatusTag from "@src/lib/SystemRelationshipStatusTag";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import InmateLink from "../../lib/InmateLink";
import VisitorLink from "../../lib/VisitorLink";
import { GetFacilityConnectionRequestsQuery } from "./GetFacilityConnectionRequests.generated";

export type ConnectionRequest =
  GetFacilityConnectionRequestsQuery["facility"]["pendingConnectionRequests"][0];

interface Props {
  connections: ConnectionRequest[];
  onReview: (connection: ConnectionRequest) => void;
  showProfessional: boolean;
}

export default function ConnectionRequestsTable({
  connections,
  onReview,
  showProfessional,
}: Props) {
  const { t } = useTranslation();
  const getStatusChangedAtLabel = (statusChangedAt: number) => {
    const daysDiff = differenceInDays(new Date(), new Date(statusChangedAt));
    return daysDiff === 0
      ? t("Less than a day ago")
      : t("{{count}} days ago", {
          count: daysDiff,
        });
  };

  const { page, rowsPerPage, currentPageData, onPageChange } = usePagination({
    data: connections,
  });

  return (
    <TableContainer>
      <Table
        aria-label={
          showProfessional
            ? t("Confidential contact requests table")
            : t("Visitor contact requests table")
        }
      >
        <TableHead>
          <TableRow>
            <TableCell>{t("Visitor name")}</TableCell>
            <TableCell>{t("Resident")}</TableCell>
            {showProfessional ? (
              <TableCell>{t("Organization")}</TableCell>
            ) : (
              <>
                <TableCell>{t("Age group")}</TableCell>
                <TableCell>{t("Relationship")}</TableCell>
              </>
            )}
            <TableCell>{t("ID status")}</TableCell>
            <TableCell>{t("Requested")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {connections.length === 0 && (
            <TableNoData colSpan={showProfessional ? 5 : 6} />
          )}
          {connections.length > 0 &&
            currentPageData.map((request) => (
              <TableRow key={request.id}>
                <TableCell component="th" scope="row">
                  <VisitorLink visitor={request.visitor} />
                </TableCell>
                <TableCell>
                  <InmateLink inmate={request.inmate} />
                </TableCell>
                {showProfessional ? (
                  <TableCell>
                    {request.organizationMembership ? (
                      <OrganizationCell
                        organization={
                          request.organizationMembership.organization
                        }
                      />
                    ) : null}
                  </TableCell>
                ) : (
                  <>
                    <TableCell>
                      <AgeGroupTag dateOfBirth={request.visitor.dateOfBirth} />
                    </TableCell>
                    <TableCell>
                      {connectionRelationshipLabel(request.relationship)}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {isMinorWithoutID(request.visitor) ? (
                    <AgeGroupTag dateOfBirth={request.visitor.dateOfBirth} />
                  ) : request.visitor.systemRelationship ? (
                    <SystemRelationshipStatusTag
                      status={request.visitor.systemRelationship.status}
                    />
                  ) : (
                    "–"
                  )}
                </TableCell>
                <TableCell>
                  {getStatusChangedAtLabel(request.statusChangedAt)}
                </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => onReview(request)}>
                    {t("Review")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        totalCount={connections.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
      />
    </TableContainer>
  );
}
