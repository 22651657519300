import { useQuery } from "@apollo/client";
import Alert from "@src/lib/Alert";
import Header from "@src/lib/Header";
import SelectableFieldStringFilter from "@src/lib/SelectableFieldStringFilter";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import stripInvalid from "@src/lib/stripInvalid";
import Joi from "joi";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetFacilityInmatesDocument } from "./GetFacilityInmates.generated";
import InmateTable from "./InmateTable";

const LIMIT = 100;

const Filters = ["fullName", "inmateIdentification"] as const;
type TFilter = (typeof Filters)[number];

const { history } = window;

// load state from history (back nav)
function parseHistoryState() {
  return stripInvalid(
    Joi.object<{
      searchQuery?: string;
      activeSearchFilter?: TFilter;
    }>({
      activeSearchFilter: Joi.string().valid(...Filters),
      searchQuery: Joi.string(),
    }),
    history.state,
  );
}

export default function InmatesPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const hasMounted = useRef(false);

  // restore search filters from page state for back navigation
  const [searchQuery, setSearchQuery] = useState<string>(
    () => parseHistoryState()?.searchQuery || "",
  );
  const [activeSearchFilter, setActiveSearchFilter] = useState<TFilter>(
    () => parseHistoryState()?.activeSearchFilter || "fullName",
  );
  useEffect(() => {
    history.replaceState({ activeSearchFilter, searchQuery }, "");
  }, [activeSearchFilter, searchQuery]);

  const { data, loading, error, refetch } = useQuery(
    GetFacilityInmatesDocument,
    {
      notifyOnNetworkStatusChange: true, // So that refetch triggers loading: true
      variables: {
        facilityId: facility.id,
        limit: LIMIT,
        fullName: "",
        inmateIdentification: "",
      },
    },
  );
  if (error) throw error;

  const FILTER_TO_LABEL_MAP: Record<TFilter, string> = {
    fullName: t("Name"),
    inmateIdentification: t("Unique ID"),
  };

  // execute the restored search query on mount and when the query changes
  useEffect(() => {
    // on the initial mount, execute the query with any restored parameters
    if (!hasMounted.current) {
      hasMounted.current = true;
      void refetch({
        fullName: activeSearchFilter === "fullName" ? searchQuery : "",
        inmateIdentification:
          activeSearchFilter === "inmateIdentification" ? searchQuery : "",
      });
      // on subsequent changes, only execute the query if it is empty
      // or has three characters or more (for performance reasons)
    } else if (searchQuery.length === 0 || searchQuery.length >= 3) {
      // Only refetch if the user has stopped typing for 700ms (middle
      // ground between responsiveness and slow typers)
      const timeout = setTimeout(() => {
        void refetch({
          fullName: activeSearchFilter === "fullName" ? searchQuery : "",
          inmateIdentification:
            activeSearchFilter === "inmateIdentification" ? searchQuery : "",
        });
      }, 700);
      return () => clearTimeout(timeout);
    }
  }, [refetch, activeSearchFilter, searchQuery]);

  return (
    <>
      <Header
        title={t("Residents")}
        subtitle={t(
          "Manage the residents of your facility, access detailed information, and edit their info as needed.",
        )}
      >
        <SelectableFieldStringFilter
          onSelectChange={(value) => setActiveSearchFilter(value as TFilter)}
          onTextChange={(value) => setSearchQuery(value)}
          selected={activeSearchFilter}
          text={searchQuery}
          filterToLabelMap={FILTER_TO_LABEL_MAP}
          key="selectableStringFilter"
        />
      </Header>
      <InmateTable
        inmates={data?.facility.newInmates.edges.map((e) => e.node) || []}
        loading={loading}
      />
      {!loading && data?.facility.newInmates.pageInfo.hasNextPage && (
        <Alert severity="warning" sx={{ m: 3, mt: 0 }}>
          {t(
            "Only the first {{limit}} results are shown. Please refine your search to view more results.",
            { limit: LIMIT },
          )}
        </Alert>
      )}
    </>
  );
}
