import { AudioHTMLAttributes, useEffect, useRef } from "react";

type PropsType = AudioHTMLAttributes<HTMLAudioElement> & {
  srcObject?: MediaStream;
  muted?: boolean;
};

export default function Audio({ srcObject, muted, ...props }: PropsType) {
  const refAudio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!refAudio.current) return;
    refAudio.current.srcObject = srcObject || null;
  }, [srcObject]);

  useEffect(() => {
    if (!refAudio.current) return;
    refAudio.current.muted = muted || false;
  }, [muted]);

  return <audio ref={refAudio} {...props} />;
}
