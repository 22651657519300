import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AcceptMeetingMutationVariables = Types.Exact<{
  input: Types.AcceptMeetingInput;
}>;


export type AcceptMeetingMutation = { __typename: 'Mutation', acceptMeeting: { __typename: 'AcceptMeetingResponse', meeting: { __typename: 'Meeting', id: Types.Scalars['ID']['input'], status: Types.MeetingStatus, statusDetails: string | null } } };


export const AcceptMeetingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptMeeting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meeting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"statusDetails"}}]}}]}}]}}]} as unknown as DocumentNode<AcceptMeetingMutation, AcceptMeetingMutationVariables>;