import {
  Button,
  ConfirmDialog,
  Dialog,
  DialogActions,
  DialogContent,
  MultiSelectChipInput,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { appendItem } from "@src/api/client";
import {
  Entitlement,
  Facility,
  Staff,
  StaffEntitlement,
} from "@src/api/graphql";
import { GetSystemFacilitiesDocument } from "@src/graphql/GetSystemFacilities.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import isEmail from "@src/lib/isEmail";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateStaffDocument } from "./CreateStaff.generated";
import { DeactivateStaffDocument } from "./DeactivateStaff.generated";
import { GetSystemStaffDocument } from "./GetSystemStaff.generated";
import { UpdateStaffDocument } from "./UpdateStaff.generated";

type Props = {
  onClose: () => void;
  staff?: Pick<
    Staff,
    "id" | "firstName" | "lastName" | "fullName" | "email"
  > & {
    staffEntitlements: Pick<StaffEntitlement, "entitlement">[];
    staffPositions: { facility: Pick<Facility, "id"> }[];
  };
  systemId: string;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  entitlements: Entitlement[];
  facilityIds: string[];
};

export default function AddEditStaffModal({ onClose, staff, systemId }: Props) {
  const { system } = useGuaranteedFacilityContext();
  const user = useCurrentStaff();
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const { data: systemFacilitiesData } = useQuery(GetSystemFacilitiesDocument, {
    variables: {
      systemId: system.id,
    },
    onError: handleApolloError,
  });

  const [createStaff] = useMutation(CreateStaffDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff Added"));
      onClose();
    },
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: cache.identify({ __typename: "System", id: systemId }),
          fields: {
            staff: appendItem(data.createStaff.staff),
          },
        });
      }
    },
  });
  const [updateStaff] = useMutation(UpdateStaffDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff Updated"));
      onClose();
    },
    refetchQueries: [
      { query: GetSystemStaffDocument, variables: { systemId: systemId } },
    ],
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      firstName: staff?.firstName || "",
      lastName: staff?.lastName || "",
      email: staff?.email || "",
      entitlements: staff?.staffEntitlements.map((se) => se.entitlement) || [],
      facilityIds: staff?.staffPositions.map((sp) => sp.facility.id) || [],
    },
  });

  const [deactivateStaff] = useMutation(DeactivateStaffDocument, {
    onError: handleApolloError,
    onCompleted: () => {
      snackbarContext.alert("success", t("Staff deactivated"));
      onClose();
    },
  });

  async function onDelete() {
    if (staff) {
      await deactivateStaff({
        variables: {
          input: {
            systemId,
            staffId: staff.id,
          },
        },
      });
    }
    setDeleting(false);
    onClose();
  }

  const [deleting, setDeleting] = useState<boolean>(false);

  // t("Staff may not edit their own entitlements")

  const submitForm = handleSubmit(async (values) => {
    if (!staff) {
      await createStaff({
        variables: {
          input: {
            systemId,
            ...values,
            entitlements: values.entitlements ?? [],
          },
        },
      });
    } else {
      await updateStaff({
        variables: {
          input: {
            systemId,
            staffId: staff.id,
            ...values,
            entitlements: values.entitlements ?? [],
          },
        },
      });
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      title={
        staff
          ? t("Editing {{name}}", {
              name: staff.fullName,
            })
          : t("Add New Staff")
      }
    >
      <DialogContent>
        {deleting && (
          <ConfirmDialog
            title={t("Deactivate staff")}
            description={t(
              "Are you sure? This will deactivate the staff positions for {{name}} at all facilities.",
              {
                name: staff?.fullName || t("this staff member"),
              },
            )}
            variant="remove"
            onCancel={() => setDeleting(false)}
            onConfirm={onDelete}
            confirmText={t("Confirm deactivation")}
          />
        )}
        <form onSubmit={submitForm}>
          <Stack spacing={2}>
            <TextInput
              control={control}
              name="firstName"
              label={t("First name")}
              rules={{
                required: true,
              }}
            />
            <TextInput
              control={control}
              name="lastName"
              label={t("Last name")}
              rules={{
                required: true,
              }}
            />
            <TextInput
              control={control}
              name="email"
              label={t("Email address")}
              type="email"
              rules={{
                required: true,
                validate: (value) =>
                  isEmail(value) || t("Must be a valid email address."),
              }}
              disabled={!!staff}
            />
            {!staff && (
              <TextInput
                control={control}
                name="password"
                label={t("Password")}
                rules={{
                  required: true,
                }}
              />
            )}
            <MultiSelectChipInput
              control={control}
              name="entitlements"
              label={t("Entitlements")}
              items={Object.values(Entitlement).map((enumValue: string) => ({
                name: enumValue,
                value: enumValue,
              }))}
              disabled={staff?.id === user.id}
            />
            <MultiSelectChipInput
              control={control}
              items={(systemFacilitiesData?.system.facilities || []).map(
                (facility) => ({
                  name: facility.name,
                  value: facility.id,
                }),
              )}
              name="facilityIds"
              label={t("Facilities")}
              rules={{
                required: true,
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" key="back" onClick={onClose} sx={{ mr: 1 }}>
          {t("Cancel")}
        </Button>
        {staff && (
          <Button
            color="error"
            variant="outlined"
            disabled={
              (staff &&
                (staff.staffPositions.length === 0 || staff.id === user.id)) ||
              isSubmitting
            }
            disabledReason={
              staff?.id === user.id
                ? t("Staff may not deactivate their own accounts")
                : ""
            }
            key="delete"
            onClick={() => setDeleting(true)}
            sx={{ mr: 1 }}
          >
            {t("Deactivate")}
          </Button>
        )}
        <Button
          variant="contained"
          key="submit"
          type="submit"
          onClick={submitForm}
          disabled={isSubmitting || !isValid}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
