import { useLazyQuery, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Entitlement, FacilityFeature } from "@src/api/graphql";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import NotAllowed from "@src/lib/NotAllowed";
import PageLoader from "@src/lib/PageLoader";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import SelectInmateStep from "@src/pages/ScheduleEventPage/SelectInmateStep";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetInmateDocument } from "../InmatePage/GetInmate.generated";
import { GetMeetingDetailsDocument } from "../MeetingDetailsPage/GetMeetingDetails.generated";
import AddGuestListStep from "./AddGuestListStep";
import ConfirmStep from "./ConfirmStep";
import normalizeGuestListData from "./normalizeGuestListData";
import SelectEventTypeStep from "./SelectEventTypeStep";
import SelectTimeStep from "./SelectTimeStep";
import { Data, defaultSteps, selectInmateStep, Step } from "./types";

type Props = {
  inmateId?: string; // Inmate can either come preselected or not
  isEdit: boolean;
  meetingId?: string;
};

export default function ScheduleEventPage({
  inmateId,
  isEdit,
  meetingId,
}: Props) {
  const { t } = useTranslation();
  const user = useCurrentStaff();
  const { facility } = useGuaranteedFacilityContext();

  // workflow control
  const steps: readonly Step[] = useMemo(() => {
    // If an inmate is preselected, skip the select resident step
    return inmateId
      ? defaultSteps.filter((r) => r !== selectInmateStep)
      : defaultSteps;
  }, [inmateId]);
  const [step, setStep] = useState<Step>(steps[0]);
  const [data, setData] = useState<Data>({});

  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );

  const stepBack = useCallback(() => {
    const i = steps.indexOf(step);
    // If on the first step, do nothing
    if (i === 0) return;
    setStep(steps[i - 1]);
  }, [steps, step]);

  const stepForward = useCallback(() => {
    const i = steps.indexOf(step);
    setStep(steps[i + 1]);
  }, [steps, step]);
  const handleApolloError = useApolloErrorHandler();
  const { data: inmateData, error } = useQuery(GetInmateDocument, {
    fetchPolicy: "cache-and-network",
    variables: {
      inmateId: inmateId || data.inmateId || "",
    },
    skip: !inmateId && !data.inmateId,
    onError: handleApolloError,
  });

  const [
    getMeetingDetails,
    {
      data: meetingDetailsData,
      error: meetingDetailsError,
      loading: meetingDetailsLoading,
    },
  ] = useLazyQuery(GetMeetingDetailsDocument, {
    fetchPolicy: "cache-and-network",
    onError: handleApolloError,
  });

  // If in edit mode, retrieve meeting details data
  useEffect(() => {
    if (isEdit && meetingId && inmateData) {
      void getMeetingDetails({ variables: { id: meetingId } });
    }
  }, [isEdit, meetingId, getMeetingDetails, inmateData]);

  // If in edit mode, normalize, populate the data and move to the next step
  useEffect(() => {
    if (isEdit && meetingDetailsData && inmateData && !data.meetingType) {
      const meeting = meetingDetailsData.meeting;
      const inmate = inmateData.inmate;
      const normalizedData = normalizeGuestListData(meeting, inmate);
      setData((prevData) => ({ ...prevData, ...normalizedData }));
      stepForward();
    }
  }, [isEdit, meetingDetailsData, inmateData, data.meetingType, stepForward]);

  if (error) throw error;
  if (meetingDetailsError) throw meetingDetailsError;

  if (meetingDetailsLoading) return <PageLoader />;
  if (step !== selectInmateStep && !inmateData?.inmate) return <PageLoader />;

  const facilityHasSupportedMeetingTypes =
    facility.features.includes(FacilityFeature.VideoCall) ||
    facility.features.includes(FacilityFeature.InPersonVisit) ||
    facility.features.includes(FacilityFeature.ConfidentialVideoCall) ||
    facility.features.includes(FacilityFeature.ConfidentialInPersonVisit);

  if (
    step !== selectInmateStep &&
    inmateData?.inmate &&
    !user.staffPositions.some(
      (position) => position.facility.id === inmateData.inmate.facility.id,
    )
  ) {
    return (
      <Box>
        <Header
          title={t("Not permitted")}
          subtitle={t(
            "This resident is at a facility that you do not have access to.",
          )}
        />
        <NotAllowed />
      </Box>
    );
  }

  if (!canManageVisitorsAndMeetings || !facilityHasSupportedMeetingTypes) {
    return (
      <Box>
        <Header
          title={t("Not permitted")}
          subtitle={
            !canManageVisitorsAndMeetings
              ? t(
                  "You do not have permission to schedule meetings on behalf of this resident.",
                )
              : t(
                  "The resident's facility does not offer the type of meetings you can schedule on this page.",
                )
          }
        />
        <NotAllowed />
      </Box>
    );
  }

  const headerTitle = !isEdit ? t("Schedule event") : t("Modify event");
  const stepPosition = steps.indexOf(step) + 1;

  return (
    <Box>
      <Header
        title={headerTitle}
        breadcrumb={
          <Breadcrumbs
            paths={
              inmateData?.inmate
                ? [
                    { path: "/inmates", label: t("Residents") },
                    {
                      path: `/inmates/${inmateData.inmate.id}`,
                      label: inmateData.inmate.fullName || "",
                    },
                    {
                      path: `/inmates/${inmateData.inmate.id}/event/new`,
                      label: headerTitle,
                    },
                  ]
                : [
                    { path: "/inmates", label: t("Residents") },
                    {
                      path: `/inmates/event/new`,
                      label: headerTitle,
                    },
                  ]
            }
          />
        }
        showBack
      />
      <Box p={3}>
        {step === selectInmateStep && (
          <SelectInmateStep
            data={data}
            onBack={stepBack}
            stepPosition={stepPosition}
            numSteps={steps.length}
            onSubmit={(inmateId) => {
              setData({ ...data, inmateId });
              stepForward();
            }}
          />
        )}
        {step === "selectEventType" && (
          <SelectEventTypeStep
            data={data}
            onBack={stepBack}
            stepPosition={stepPosition}
            numSteps={steps.length}
            showBackButton={steps.includes(selectInmateStep)}
            onSubmit={(meetingType) => {
              setData({ ...data, meetingType });
              stepForward();
            }}
          />
        )}
        {inmateData?.inmate && step === "addGuests" && (
          <AddGuestListStep
            inmate={inmateData.inmate}
            data={data}
            stepPosition={stepPosition}
            numSteps={steps.length}
            onBack={stepBack}
            onSubmit={({
              registeredGuests,
              unregisteredGuests,
              confidential,
            }) => {
              setData({
                ...data,
                registeredGuests,
                unregisteredGuests,
                confidential,
              });
              stepForward();
            }}
          />
        )}
        {inmateData?.inmate && step === "selectWhen" && (
          <SelectTimeStep
            inmateId={inmateData.inmate.id}
            meetingId={meetingId}
            data={data}
            stepPosition={stepPosition}
            numSteps={steps.length}
            onBack={stepBack}
            onSubmit={({ availability, kiosk, customTimeChosen }) => {
              setData({
                ...data,
                availability,
                kiosk,
                customTimeChosen,
              });
              stepForward();
            }}
          />
        )}
        {inmateData?.inmate && step === "confirm" && (
          <ConfirmStep
            inmateId={inmateData.inmate.id}
            data={data}
            stepPosition={stepPosition}
            numSteps={steps.length}
            meetingId={meetingId}
            onBack={stepBack}
          />
        )}
      </Box>
    </Box>
  );
}
