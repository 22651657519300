import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateKioskMutationVariables = Types.Exact<{
  input: Types.CreateKioskInput;
}>;


export type CreateKioskMutation = { __typename: 'Mutation', createKiosk: { __typename: 'CreateKioskResponse', kiosk: { __typename: 'Kiosk', id: Types.Scalars['ID']['input'], name: string, enabled: boolean, capacity: number, meetingType: Types.MeetingType } } };


export const CreateKioskDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateKiosk"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateKioskInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createKiosk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"kiosk"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"KioskFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KioskFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Kiosk"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"capacity"}},{"kind":"Field","name":{"kind":"Name","value":"meetingType"}}]}}]} as unknown as DocumentNode<CreateKioskMutation, CreateKioskMutationVariables>;