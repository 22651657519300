const KEY = "token";

export type Storage = {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};

export function memoryStorage(): Storage {
  const data = new Map<string, string>();
  return {
    getItem: (k) => data.get(k) || null,
    setItem: (k, v) => data.set(k, v),
    removeItem: (k) => data.delete(k),
  };
}

// if the browser does not allow access to sessionStorage
// then degrade to javascript memory storage
let storage: Storage;
try {
  sessionStorage.getItem("test");
  storage = sessionStorage;
} catch {
  storage = memoryStorage();
}

export const getToken = (): string => storage.getItem(KEY) || "";

export const storeToken = (value: string | undefined) =>
  value ? storage.setItem(KEY, value) : storage.removeItem(KEY);
