import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TestCallModal from "./TestCallModal";

export default function FacilityNetworkSettingsPage() {
  const [testCallOpen, setTestCallOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" component="label">
        {t("Network")}
      </Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <Typography variant="h3" component="label">
              {t("Network Information")}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box width={0.6}>
                <Typography variant="subtitle1">{t("Network test")}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {t(
                    "Run this periodically in your facility's calling location(s).",
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                width={0.4}
                sx={{ justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => {
                    setTestCallOpen(true);
                  }}
                  variant="outlined"
                >
                  {t("Test network")}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Card>
        {testCallOpen && (
          <TestCallModal
            onClose={() => {
              setTestCallOpen(false);
            }}
          />
        )}
      </Stack>
    </>
  );
}
