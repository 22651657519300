import {
  Meeting,
  MeetingStatus,
  MeetingType,
  PrivacyLevel,
} from "@src/api/graphql";

export default function mayHaveRecording(
  meeting: Pick<Meeting, "meetingType" | "privacyLevel" | "status">,
) {
  return (
    meeting.privacyLevel === PrivacyLevel.Monitored &&
    (meeting.meetingType === MeetingType.VideoCall ||
      meeting.meetingType === MeetingType.VoiceCall) &&
    [
      MeetingStatus.NoShow,
      MeetingStatus.Ended,
      MeetingStatus.Terminated,
    ].includes(meeting.status)
  );
}
