import React from "react";
import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
  Path,
} from "react-hook-form";

// our HOC will add props to the rendered component
type AddedProps = ControllerRenderProps & {
  error?: boolean;
  helperText?: string;
};

// our HOC will need extra fields for the Controller wrapper.
// but we don't support FormProvider so we require "control".
type PropsForController<
  TFieldValues extends FieldValues,
  Name extends Path<TFieldValues>,
> = Pick<
  ControllerProps<TFieldValues, Name>,
  "name" | "rules" | "defaultValue"
> & {
  control: Required<ControllerProps<TFieldValues>>["control"];
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
};

/**
 * withController will create a component that wraps the given Input component
 * with a RHF Controller.
 */
export default function withController<InputProps>(
  Input: React.ComponentType<InputProps & AddedProps>,
) {
  /**
   * The Wrapper component renders Input inside a Controller. It has an unresolved
   * type that will be inferred from the given "control".
   */
  function Wrapper<
    FieldValues extends Record<string, unknown>,
    Name extends Path<FieldValues>,
  >({
    name,
    control,
    rules,
    defaultValue,
    ...rest
  }: PropsForController<FieldValues, Name> &
    Omit<InputProps, keyof AddedProps>) {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => (
          <Input
            // coercion is necessary because of some gap in typescript
            // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as any)}
            {...field}
            error={!!fieldState.error}
            helperText={fieldState.error?.message || rest.helperText}
          />
        )}
      />
    );
  }
  Wrapper.displayName = `withController(${Input.displayName})`;
  return Wrapper;
}
