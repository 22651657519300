import { ReactElement } from "react";
import { Bar } from "react-chartjs-2";

const COLOR = "rgba(0, 137, 255, 1)";
const DARKER_COLOR = "rgba(2, 117, 216, 1)";

interface Props {
  labels: string[];
  data: number[];
  size?: number;
}

export default function HorizontalBarChart({
  labels,
  data,
  size,
}: Props): ReactElement {
  const elSize = size || 350;

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Data",
        data: data,
        backgroundColor: COLOR,
        hoverBackgroundColor: DARKER_COLOR,
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={{
        scales: {
          x: {
            grid: {
              display: false, // Hide the vertical grid lines
            },
            ticks: {
              stepSize: 1,
            },
          },
          y: {
            grid: {
              display: false, // Hide the horizontal grid lines
            },
            beginAtZero: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      style={{
        maxWidth: elSize,
        maxHeight: elSize,
        width: "100%",
        margin: "0 auto",
      }}
    />
  );
}
