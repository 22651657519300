import { useQuery } from "@apollo/client";
import { School, WorkOutline } from "@mui/icons-material";
import { Card, Stack } from "@mui/material";
import { FacilityFeature, MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import { GetFacilitySchedulesDocument } from "@src/graphql/GetFacilitySchedules.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import Header from "@src/lib/Header";
import ScheduleTable from "@src/lib/ScheduleTable";
import SchedulingWindowSettingField from "@src/lib/SchedulingWindowSettingField";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import UnavailableSplashScreen from "@src/lib/UnavailableSplashScreen";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import { useTranslation } from "react-i18next";

export default function ConfidentialVideoCallsSettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { t } = useTranslation();
  const handleApolloError = useApolloErrorHandler();

  const { data } = useQuery(GetFacilitySchedulesDocument, {
    onError: handleApolloError,
    variables: { facilityId: facility.id },
  });

  const { updateProperties } = useUpdateFacilityProperty();

  const { data: facilityData } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id },
  });

  if (!data || !facilityData) return <SettingsSkeleton />;

  const { facility: loadedFacility } = facilityData;

  if (
    !loadedFacility.features.includes(FacilityFeature.ConfidentialVideoCall)
  ) {
    return (
      <UnavailableSplashScreen
        header={t("Confidential video calls")}
        subHeader={t(
          "Contact us about adding confidential video calls to the facility.",
        )}
        features={[
          {
            icon: <WorkOutline />,
            text: t(
              "Make it easy for lawyers to meet and stay in touch with clients",
            ),
          },
          {
            icon: <School />,
            text: t(
              "Support one-on-one sessions between health providers and patients",
            ),
          },
        ]}
        learnMoreText={t("Learn more about confidential video calls")}
        learnMoreLink={
          "https://www.ameelio.org/child-products/privileged-video-calls"
        }
      />
    );
  }

  return (
    <>
      <Header
        title={t("Confidential video calls")}
        subtitle={t(
          "Eligible visitors may request confidential calls, which are unmonitored and scheduled separately from standard video calls.",
        )}
      />
      <Stack spacing={2} p={2}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Scheduling")}</SectionTitle>
            <SchedulingWindowSettingField
              value={{
                cutoffDays: loadedFacility.providerVideoCutoff,
                windowWeeks: loadedFacility.providerVideoWindow,
              }}
              onChange={(data) =>
                updateProperties({
                  providerVideoCutoff: data.cutoffDays,
                  providerVideoWindow: data.windowWeeks,
                })
              }
            />
          </Stack>
        </Card>

        <ScheduleTable
          title={t("Confidential video call schedules")}
          schedules={data.facility.schedules.filter(
            (s) =>
              s.meetingType === MeetingType.VideoCall &&
              s.privacyLevels.includes(PrivacyLevel.UnmonitoredLogged),
          )}
          privacyLevel={PrivacyLevel.UnmonitoredLogged}
          meetingType={MeetingType.VideoCall}
        />
      </Stack>
    </>
  );
}
