import { t } from "i18next";

enum ProfessionalRelationship {
  Caseworker = "CASEWORKER",
  Clergy = "CLERGY",
  Employer = "EMPLOYER",
  LegalAdvisor = "LEGAL_ADVISOR",
  Mentor = "MENTOR",
  Other = "OTHER",
}
enum PersonalRelationship {
  Brother = "BROTHER",
  Cousin = "COUSIN",
  Daughter = "DAUGHTER",
  Father = "FATHER",
  Friend = "FRIEND",
  Grandparent = "GRANDPARENT",
  Mother = "MOTHER",
  SignificantOther = "SIGNIFICANT_OTHER",
  Sister = "SISTER",
  Son = "SON",
  Other = "OTHER",
}

// Here we list those relationship that we manage (e.g. for organization members...) but don't want to show in the list.
enum HiddenRelationship {
  Educator = "EDUCATOR",
}

export type Relationship =
  | PersonalRelationship
  | ProfessionalRelationship
  | HiddenRelationship;

export type ConnectionRelationship = Relationship | string;

export default function connectionRelationshipLabel(
  relationship: Relationship | string,
) {
  const relationshipTypes: Record<ConnectionRelationship, string> = {
    [PersonalRelationship.Brother]: t("Brother"),
    [PersonalRelationship.Cousin]: t("Cousin"),
    [PersonalRelationship.Daughter]: t("Daughter"),
    [PersonalRelationship.Father]: t("Father"),
    [PersonalRelationship.Friend]: t("Friend"),
    [PersonalRelationship.Grandparent]: t("Grandparent"),
    [PersonalRelationship.Mother]: t("Mother"),
    [PersonalRelationship.Other]: t("Other"),
    [PersonalRelationship.SignificantOther]: t("Significant Other"),
    [PersonalRelationship.Sister]: t("Sister"),
    [PersonalRelationship.Son]: t("Son"),
    [ProfessionalRelationship.Caseworker]: t("Caseworker"),
    [ProfessionalRelationship.Clergy]: t("Clergy"),
    [ProfessionalRelationship.Employer]: t("Employer"),
    [ProfessionalRelationship.LegalAdvisor]: t("Legal advisor"),
    [ProfessionalRelationship.Mentor]: t("Mentor"),
    [HiddenRelationship.Educator]: t("Educator"),
  };
  const key = (relationship || "").toUpperCase();
  return relationshipTypes[key] !== undefined
    ? relationshipTypes[key]
    : relationship;
}
