import { useQuery } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import Breadcrumbs from "@src/lib/Breadcrumbs";
import Header from "@src/lib/Header";
import OrganizationProfileCard from "@src/lib/OrganizationProfileCard";
import PageLoader from "@src/lib/PageLoader";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { GetOrganizationDocument } from "./GetOrganization.generated";
import OrganizationMembersTable from "./OrganizationMembersTable";

function OrganizationPage(): ReactElement {
  const { id: organizationId } = useParams<{ id: string }>();
  if (!organizationId) throw new Error("missing organizationId param");
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(GetOrganizationDocument, {
    variables: {
      id: organizationId,
    },
  });

  const memberships = useMemo(
    () => data?.organization.memberships || [],
    [data],
  );

  if (error) throw error;
  if (loading || !data) return <PageLoader />;

  const organization = data?.organization;

  return (
    <>
      <Header
        showBack
        title={organization.name}
        breadcrumb={
          <Breadcrumbs
            paths={[
              {
                path: "/organizations",
                label: t("Organizations"),
              },
              {
                path: `/organizations/${organization.id}`,
                label: organization.name,
              },
            ]}
          />
        }
      />
      <Box mx={3}>
        <Stack mt={3} spacing={3}>
          <OrganizationProfileCard organization={organization} />
          <OrganizationMembersTable memberships={memberships} />
        </Stack>
      </Box>
    </>
  );
}

export default OrganizationPage;
