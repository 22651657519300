import { Dialog } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { MeetingType, PrivacyLevel } from "@src/api/graphql";
import { GetFacilitySchedulesQuery } from "@src/graphql/GetFacilitySchedules.generated";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { useTranslation } from "react-i18next";
import { DeleteScheduleDocument } from "./DeleteSchedule.generated";

type Schedule = GetFacilitySchedulesQuery["facility"]["schedules"][number];

type Props = {
  schedule: Schedule;
  onClose: () => void;
};

export default function DeleteScheduleModal({ schedule, onClose }: Props) {
  const handleApolloError = useApolloErrorHandler();
  const [deleteSchedule] = useMutation(DeleteScheduleDocument, {
    onError: handleApolloError,
    refetchQueries: ["GetFacilitySchedules"],
    variables: {
      input: {
        scheduleId: schedule.id,
      },
    },
    onCompleted: onClose,
  });

  const { t } = useTranslation();

  return (
    <Dialog
      title={t("Delete {{scheduleName}}", { scheduleName: schedule.name })}
      fullWidth
      onClose={onClose}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            {t(
              "Deleting this schedule will not cancel meetings that have already been scheduled. Once this schedule is deleted it cannot be restored.",
            )}
          </Typography>
          <Typography>
            {t("The following meeting types will be impacted by this change:")}
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {schedule.meetingType === MeetingType.VideoCall &&
              schedule.privacyLevels.includes(PrivacyLevel.Monitored) && (
                <ListItem>
                  <Typography>{t("Video calls")}</Typography>
                </ListItem>
              )}
            {schedule.meetingType === MeetingType.VideoCall &&
              schedule.privacyLevels.includes(
                PrivacyLevel.UnmonitoredLogged,
              ) && (
                <ListItem>
                  <Typography>{t("Confidential video calls")}</Typography>
                </ListItem>
              )}
            {schedule.meetingType === MeetingType.InPersonVisit && (
              <ListItem>
                <Typography>{t("In-person visits")}</Typography>
              </ListItem>
            )}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" autoFocus onClick={() => onClose()}>
          {t("Cancel")}
        </Button>
        <Button onClick={() => deleteSchedule()} color="error">
          {t("Yes, delete schedule")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
