import { onlyMobile } from "@ameelio/ui";
import { Typography, TypographyProps, useMediaQuery } from "@mui/material";
import buildPageTitle from "@src/lib/buildPageTitle";
import { Helmet } from "react-helmet";

type ScreenTitleProps = TypographyProps<"h1"> & { skipPageTitle?: boolean };

// eslint-disable-next-line import/prefer-default-export
export function ScreenTitle({ skipPageTitle, ...props }: ScreenTitleProps) {
  const isMobile = onlyMobile(useMediaQuery);
  return (
    <>
      {skipPageTitle ? null : (
        <Helmet>
          <title>{buildPageTitle(props.children as string)}</title>
        </Helmet>
      )}
      <Typography variant={isMobile ? "h2" : "h1"} component="h1" {...props} />
    </>
  );
}
export function ScreenSubtitle(props: TypographyProps) {
  return <Typography {...props} variant="body2" />;
}

export function SectionTitle(props: TypographyProps<"h2">) {
  return (
    <Typography color="text.primary" variant="h3" component="h2" {...props} />
  );
}

export function TypographyText(
  props: TypographyProps<"p"> & { type?: "secondary" },
) {
  switch (props.type) {
    case "secondary":
      return <Typography variant="body2" color="text.secondary" {...props} />;
    default:
      return <Typography variant="body2" {...props} />;
  }
}
