import { TypedDocumentNode, useApolloClient } from "@apollo/client";
import { PageInfo } from "@src/api/graphql";
import { useState } from "react";

type QueryData<T> = {
  pageInfo:
    | Pick<PageInfo, "endCursor" | "hasNextPage">
    | Pick<PageInfo, "startCursor" | "hasPreviousPage">;
  edges: { node: T }[];
};

/**
 * useFetchAllPages returns a function capable of iteratively fetching
 * pages from a compatible query and yielding them as a simple array.
 */
export function useFetchAllPages<Result, Variables, T>({
  query,
  variables,
  getQueryData,
  onComplete,
}: {
  query: TypedDocumentNode<Result, Variables>;
  variables: Variables;
  getQueryData: (result: Result) => QueryData<T>;
  onComplete: (data: T[]) => void;
}) {
  const client = useApolloClient();
  const [working, setWorking] = useState(false);

  const fetchAllPages = async () => {
    setWorking(true);

    const nodes: T[] = [];
    try {
      let cursor: string | undefined | false = undefined;
      while (cursor !== false) {
        const { data, error } = await client.query({
          fetchPolicy: "no-cache",
          query,
          variables: { ...variables, cursor },
        });
        if (error) {
          reportError(error);
          return;
        }
        const { pageInfo, edges } = getQueryData(data);
        edges.forEach((e) => nodes.push(e.node));
        cursor =
          "endCursor" in pageInfo && pageInfo.hasNextPage
            ? pageInfo.endCursor
            : "startCursor" in pageInfo && pageInfo.hasPreviousPage
              ? pageInfo.startCursor
              : false;
      }
    } finally {
      setWorking(false);
    }

    onComplete(nodes);
  };

  return { working, fetchAllPages };
}
