import { Button } from "@ameelio/ui";
import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import Result from "@src/lib/Result";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslateWithSupportLink } from "./FeatureToggleBanner";

const ErrorBoundary: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Sentry.ErrorBoundary
      // I don't understand why this is flagged, but it's the usage
      // recommended by the Sentry docs.
      // eslint-disable-next-line @typescript-eslint/unbound-method
      fallback={({ resetError }) => (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Result
            variant="error"
            title={t("Sorry, something went wrong.")}
            subTitle={
              <TranslateWithSupportLink
                message={t(
                  "We've logged the error and will do what we can to fix it. You may contact <supportLink>{{supportAddress}}</supportLink> about the status or to provide additional information.",
                )}
                emailSubject="Connect dashboard error"
              />
            }
            extra={[
              <Button
                key="reset"
                variant="outlined"
                onClick={() => {
                  window.history.back();
                  resetError();
                }}
              >
                {t("Go back")}
              </Button>,
            ]}
          />
        </Box>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
