import { useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import { Card, Divider, Link, Stack } from "@mui/material";
import { Entitlement, Language } from "@src/api/graphql";
import { GetFacilityDocument } from "@src/graphql/GetFacility.generated";
import featureEnabled from "@src/lib/featureEnabled";
import Header from "@src/lib/Header";
import isEmail from "@src/lib/isEmail";
import isPhoneNumber from "@src/lib/isPhoneNumber";
import { LanguageNames } from "@src/lib/Language";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import SettingField, {
  SelectSettingField,
  TextSettingField,
} from "@src/lib/SettingField";
import SettingsSkeleton from "@src/lib/SettingsSkeleton";
import { SectionTitle } from "@src/lib/typography";
import useCurrentStaff from "@src/lib/useCurrentStaff";
import useEntitlement from "@src/lib/useEntitlement";
import useUpdateFacilityProperty from "@src/lib/useUpdateFacilityProperty";
import USState from "@src/lib/USState.enum";
import { SetLanguagePreferenceDocument } from "@src/pages/FacilitySettingsPage/SetLanguagePreference.generated";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

// Uses native URL class
function isUrl(string: string) {
  try {
    const url = new URL(string);
    return ["http:", "https:"].includes(url.protocol);
  } catch {
    return false;
  }
}

function isUrlOrEmpty(string: string) {
  return string === "" || isUrl(string);
}

export default function FacilitySettingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const { updateProperty } = useUpdateFacilityProperty();
  const { t } = useTranslation();
  const user = useCurrentStaff();
  const snackbarContext = useSnackbarContext();
  const canEdit = useEntitlement(Entitlement.ManageFacility);

  const { data } = useQuery(GetFacilityDocument, {
    variables: { facilityId: facility.id || "" },
  });

  const [mutateLanguagePreference] = useMutation(
    SetLanguagePreferenceDocument,
    {
      onCompleted: () => {
        snackbarContext.alert("success", i18n.t("Setting saved."));
      },
      onError: (e) => alert(e),
    },
  );

  const handleLanguageChange = async (selectedLanguage: Language) => {
    await i18n.changeLanguage(selectedLanguage.toLowerCase());
    await mutateLanguagePreference({
      variables: {
        input: {
          language: selectedLanguage,
        },
      },
    });
  };

  if (!data) return <SettingsSkeleton />;

  const { facility: loadedFacility } = data;

  return (
    <>
      <Header title={t("Facility settings")} />
      <Stack spacing={2} p={2}>
        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("General Information")}</SectionTitle>
            <TextSettingField
              label={t("Facility name")}
              description={t("The formal name of the facility.")}
              value={loadedFacility.name}
              validate={{
                required: (val: string) => !!val,
              }}
              errorMessages={{
                required: t("Name is required"),
              }}
              onChange={(value) => updateProperty("name", value)}
              canEdit={canEdit}
            />
            <Divider />
            <SettingField
              label={t("Facility abbreviation")}
              description={t("The abbreviation people use for the facility.")}
              value={loadedFacility.publicId}
              canEdit={canEdit}
            />
            <Divider />
            <SettingField
              label={t("Location")}
              description={t("The location of the facility.")}
              value={
                <>
                  {loadedFacility.province ||
                    USState[loadedFacility.state] ||
                    loadedFacility.state}
                  , {loadedFacility.country}
                </>
              }
              canEdit={canEdit}
            />
            <Divider />
            <SettingField
              label={t("Timezone")}
              description={t("The time zone for the facility.")}
              value={loadedFacility.timezone}
              canEdit={canEdit}
            />
            {featureEnabled("LANGUAGE_SETTINGS") && (
              <>
                <Divider />
                <SelectSettingField
                  label={t("Language")}
                  description={""}
                  value={user.languagePreference || Language.En}
                  render={(lang) => LanguageNames[lang]}
                  onChange={async (value) => await handleLanguageChange(value)}
                  selectOptions={Object.values(Language).map((lang) => ({
                    value: lang,
                    name: LanguageNames[lang],
                  }))}
                  canEdit={canEdit}
                />
              </>
            )}
          </Stack>
        </Card>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Contact Information")}</SectionTitle>
            <TextSettingField
              label={t("Primary email")}
              description={t("Where visitors can email questions.")}
              value={loadedFacility.email}
              inputType="email"
              onChange={(value) => updateProperty("email", value)}
              validate={{
                isEmail,
              }}
              errorMessages={{
                isEmail: t("Must be a valid email address."),
              }}
              canEdit={canEdit}
            />
            <Divider />
            <TextSettingField
              label={t("Primary phone number")}
              description={t("Where visitors can call with questions.")}
              value={loadedFacility.phone}
              inputType="tel"
              onChange={(value) => updateProperty("phone", value)}
              validate={{
                isPhoneNumber,
              }}
              errorMessages={{
                isPhoneNumber: t("Must be a valid phone number."),
              }}
              canEdit={canEdit}
            />
          </Stack>
        </Card>

        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
          <Stack padding={3} spacing={1.5}>
            <SectionTitle>{t("Website addresses")}</SectionTitle>
            <TextSettingField
              label={t("Policy URL")}
              description={t(
                "Where visitors can find visitation policy documentation.",
              )}
              value={loadedFacility.policyLink}
              render={(value) =>
                value === "" ? (
                  "None"
                ) : (
                  <Link href={value} target="_blank">
                    {value}
                  </Link>
                )
              }
              onChange={(value) => updateProperty("policyLink", value)}
              validate={{
                isUrl,
              }}
              errorMessages={{
                isUrl: t("Must be a valid URL."),
              }}
              canEdit={canEdit}
            />
            <Divider />
            <TextSettingField
              label={t("Visitation URL")}
              description={t(
                "Where visitors can fill out paperwork to become an approved visitor.",
              )}
              value={loadedFacility.visitationFormLink || ""}
              render={(value) =>
                value === "" ? (
                  "None"
                ) : (
                  <Link href={value} target="_blank">
                    {value}
                  </Link>
                )
              }
              onChange={(value) => updateProperty("visitationFormLink", value)}
              validate={{
                isUrlOrEmpty,
              }}
              errorMessages={{
                isUrlOrEmpty: t("Must be a valid URL or empty."),
              }}
              canEdit={canEdit}
            />
          </Stack>
        </Card>
      </Stack>
    </>
  );
}
