import { formatDate, yearMonthDate } from "@src/lib/Time";
import { t } from "i18next";
import { compact } from "lodash";
import { GetFacilityPhoneCallsQuery } from "./GetFacilityPhoneCalls.generated";

export const writePhoneCall = (
  call: GetFacilityPhoneCallsQuery["facility"]["phoneCalls"]["edges"][0]["node"],
) => {
  const row: Record<string, unknown> = {};

  row[t("Date")] = formatDate(new Date(call.start), "date");
  row[t("Start")] = formatDate(new Date(call.start), "time");
  row[t("End")] = call.end ? formatDate(new Date(call.end), "time") : "-";
  row[t("Resident")] =
    `${call.inmate.fullName} (${call.inmate.inmateIdentification})`;
  row[t("Phone")] =
    `${call.facilityApprovedPhone.name} (${call.facilityApprovedPhone.number})`;
  row[t("Status")] = call.status;

  return row;
};

export const exportDateRange = (
  after: number | undefined,
  before: number | undefined,
) => {
  return compact([after, before]).map(yearMonthDate).join("_");
};
