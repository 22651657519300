import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { yellow } from "@mui/material/colors";
import { getMessageSenderType, Message } from "@src/lib/Message";
import { useCallback } from "react";
import MessageCard from "./MessageCard";

interface Props {
  messages: Message[];
  focus?: Message;
}

export default function MessagesList({ messages, focus }: Props) {
  const scrollToFocus = useCallback((node: HTMLElement | null) => {
    if (node)
      node.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, []);

  return (
    <Stack spacing={0} sx={{ overflowY: "auto" }}>
      {messages.map((message) => {
        const senderType = getMessageSenderType(message);
        const isFocused = focus?.id === message.id;
        return (
          <Box
            key={message.id}
            sx={{
              px: 2,
              py: 1,
              backgroundColor: isFocused ? yellow[50] : "",
            }}
            ref={isFocused ? scrollToFocus : undefined}
          >
            <Box
              sx={{
                [senderType === "inmate" ? "pr" : "pl"]: "25%",
              }}
            >
              <MessageCard message={message} />
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
}
