import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import { formatTimeRange } from "@src/lib/Time";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScheduleMeeting } from "./DailySchedulePage";

type Props = {
  meeting: ScheduleMeeting;
};

export default function DailyScheduleCard({ meeting }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getMeetingTypeIcon = (meetingType: MeetingType) => {
    switch (meetingType) {
      case MeetingType.VideoCall:
        return <VideocamIcon sx={{ color: "text.secondary" }} />;
      case MeetingType.InPersonVisit:
        return <PersonIcon sx={{ color: "text.secondary" }} />;
      case MeetingType.Webinar:
        return <GroupIcon sx={{ color: "text.secondary" }} />;
      default:
        return null;
    }
  };

  const handleCardClick = () => {
    navigate(`/meetings/${meeting.id}`);
  };

  const handleMonitorClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(
      meeting.meetingType === MeetingType.VideoCall
        ? "/live/video-calls"
        : "/live/voice-calls",
    );
  };

  return (
    <Card sx={{ minWidth: 240, width: "inherit" }}>
      <CardActionArea onClick={handleCardClick}>
        <CardContent>
          <Stack direction="row" alignItems="start" spacing={1}>
            {getMeetingTypeIcon(meeting.meetingType)}
            <Stack direction="column" alignItems="start">
              <Typography variant="body1" color="text.primary">
                {meeting.kiosk?.name}
              </Typography>
              <Typography variant="body2" color="text.primary" mt={1}>
                {meeting.inmates[0]?.fullName} (
                {meeting.inmates[0]?.inmateIdentification})
              </Typography>
              <Typography variant="body2" color="text.primary">
                {meeting.visitors.map((v) => v.fullName).join(", ") ||
                  t("No visitors")}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {formatTimeRange(
                  meeting.interval.startAt,
                  meeting.interval.endAt,
                )}
              </Typography>
            </Stack>
          </Stack>
          {meeting.status === MeetingStatus.Live && (
            <Box mt={2} ml={4}>
              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={handleMonitorClick}
              >
                {t("Monitor")}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
