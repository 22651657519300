import * as Types from '../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateLocalMessageKeywordMutationVariables = Types.Exact<{
  input: Types.UpdateLocalMessageKeywordInput;
}>;


export type UpdateLocalMessageKeywordMutation = { __typename: 'Mutation', updateLocalMessageKeyword: { __typename: 'UpdateLocalMessageKeywordResponse', messageKeyword: { __typename: 'MessageKeyword', id: Types.Scalars['ID']['input'], description: string, spellings: Array<string>, category: Types.MessageKeywordCategory } } };


export const UpdateLocalMessageKeywordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateLocalMessageKeyword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateLocalMessageKeywordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateLocalMessageKeyword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messageKeyword"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"spellings"}},{"kind":"Field","name":{"kind":"Name","value":"category"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateLocalMessageKeywordMutation, UpdateLocalMessageKeywordMutationVariables>;