import { cacheConfiguration as useSurveyCacheConfig } from "@ameelio/use-survey";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Reference,
  StoreObject,
} from "@apollo/client";
import { Modifier } from "@apollo/client/cache/core/types/common";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { mergeDeep, relayStylePagination } from "@apollo/client/utilities";
import fragmentMatcher from "@src/api/fragment-matcher.json";
import { getToken } from "@src/lib/authToken";

const { possibleTypes } = fragmentMatcher;

type Logout = () => void;

const errorLink = (logout: Logout) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const tokenError = graphQLErrors
        // according to types, `message` should exist. however we've seen
        // sentry reports that clearly demonstrate it can be undefined.
        .filter((e) => e.message)
        .find(
          (err) =>
            err.message.includes("API token is expired or revoked") ||
            err.message.includes("API token is invalid or missing"),
        );

      if (tokenError) {
        logout();
      }
    }

    return forward(operation);
  });

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const enhancedFetch = (_: string, options: RequestInit): Promise<Response> => {
  try {
    if (typeof options.body !== "string")
      throw new Error("Request body is not a string");
    const json: unknown = JSON.parse(options.body);
    if (typeof json !== "object" || !json)
      throw new Error("Request body is not a JSON object");
    if (!("operationName" in json) || typeof json.operationName !== "string")
      throw new Error("Request body missing operationName");

    return fetch(
      `${import.meta.env.VITE_API_ORIGIN}/api/graphql?op=${"operationName" in json ? json.operationName : "unknown"}`,
      options,
    );
  } catch (e) {
    console.error(e);
    return fetch(`${import.meta.env.VITE_API_ORIGIN}/api/graphql`, options);
  }
};

const httpLink = createHttpLink({ fetch: enhancedFetch });

const existingCacheConfiguration = {
  possibleTypes,
  typePolicies: {
    Meeting: {
      fields: {
        interval: {
          merge: true,
        },
      },
    },
    Inmate: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "status",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
      },
    },
    Facility: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "status",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
        inmates: relayStylePagination(["type"]),
        visitors: relayStylePagination(["type"]),
        pendingMessages: {
          merge: true,
        },
      },
    },
    Visitor: {
      fields: {
        meetings: relayStylePagination([
          "meetingType",
          "status",
          "scheduledStartAfter",
          "scheduledStartBefore",
        ]),
      },
    },
  },
};

export const cacheConfiguration = mergeDeep(
  existingCacheConfiguration,
  useSurveyCacheConfig,
);

const cache = new InMemoryCache(cacheConfiguration);

export const client = new ApolloClient({
  connectToDevTools: import.meta.env.DEV,
  link: ApolloLink.from([httpLink]),
  cache,
});

const getAuthenticatedClient = (logout: Logout) =>
  new ApolloClient({
    connectToDevTools: import.meta.env.DEV,
    link: ApolloLink.from([errorLink(logout), authLink, httpLink]),
    cache,
  });

export default getAuthenticatedClient;

/**
 * appendItem will return a cache modifier that adds an object to the end
 * of a list.
 */
export function appendItem(object: { id: string }): Modifier<Reference[]> {
  return (existing, { toReference, readField }) => {
    const objectRef = toReference(object, true);
    if (!objectRef)
      throw new Error(
        "Attempted to insert an object with a faulty or missing id",
      );
    return [
      ...existing.filter((e) => object.id !== readField("id", e)),
      objectRef,
    ];
  };
}

export function evictItem(object: StoreObject & { id: string }) {
  cache.evict({ id: cache.identify(object) });
}
