import { Interval, MeetingStatus, MeetingType } from "@src/api/graphql";
import { formatDate } from "./Time";

export default function getCallEndTime(
  status: MeetingStatus,
  meetingType: MeetingType,
  interval: Pick<Interval, "startAt" | "endAt">,
  duration?: number | null,
  includeSeconds?: boolean,
) {
  const meetingWasLive = status === MeetingStatus.Ended && duration;
  const isVoiceCall = meetingType === MeetingType.VoiceCall;
  return meetingWasLive || !isVoiceCall
    ? formatDate(
        isVoiceCall
          ? new Date(new Date(interval.startAt).getTime() + (duration || 0))
          : new Date(interval.endAt),
        includeSeconds ? "timeseconds" : "time",
      )
    : "";
}
