import { IconButton, onlyMobile } from "@ameelio/ui";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer as BaseDrawer,
  Box,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type DrawerProps = {
  open: boolean;
  onToggle: (value: boolean) => void;
  title: string;
  size?: number;
  contained?: boolean;
  collapsible?: boolean;
  children: React.ReactNode;
};

export default function Drawer({
  open,
  title,
  size,
  onToggle,
  children,
  collapsible,
  contained,
}: DrawerProps) {
  const baseSize = size || 480;
  const isMobile = onlyMobile(measureScreenWidth);
  const { t } = useTranslation();

  /**
   * Collapsible means: always open, but can be collapsed to a small width.
   * So we need to keep track of the open state separately from the drawer's
   */

  const completelyOpen = !collapsible || open; // if not collapsible, it's always open

  return (
    <BaseDrawer
      open={collapsible ? true : open}
      anchor="right"
      variant={collapsible ? "persistent" : "temporary"}
      sx={{
        width: completelyOpen ? (isMobile ? "100%" : baseSize) : 56,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: completelyOpen ? (isMobile ? "100%" : baseSize) : 56,
        },
      }}
      PaperProps={
        contained
          ? {
              style: { position: "absolute" },
            }
          : undefined
      }
      onClose={() => onToggle(false)}
    >
      {title ? (
        <Box
          display="flex"
          alignItems="center"
          p={1}
          borderBottom={1}
          borderColor="divider"
        >
          {collapsible ? (
            <IconButton
              ariaLabel={t("Collapse")}
              onClick={() => onToggle(!open)}
            >
              {open ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          ) : (
            <IconButton ariaLabel={t("Close")} onClick={() => onToggle(false)}>
              <CloseIcon />
            </IconButton>
          )}
          {completelyOpen ? (
            <Typography
              variant="h3"
              component="h2"
              sx={{ position: "sticky", top: 0, backgroundColor: "white" }}
            >
              {title}
            </Typography>
          ) : null}
        </Box>
      ) : null}
      {completelyOpen ? <Box p={2}>{children}</Box> : null}
    </BaseDrawer>
  );
}
