import * as Types from '../../../api/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateConnectionRestrictionsMutationVariables = Types.Exact<{
  input: Types.CreateConnectionRestrictionsInput;
}>;


export type CreateConnectionRestrictionsMutation = { __typename: 'Mutation', createConnectionRestrictions: { __typename: 'CreateConnectionRestrictionsResponse', restrictions: Array<{ __typename: 'ConnectionRestriction', id: Types.Scalars['ID']['input'], service: Types.FacilityService, expiresAt: Types.Scalars['Date']['input'] | null, reason: string, isActive: boolean, connection: { __typename: 'Connection', id: Types.Scalars['ID']['input'] }, createdBy: { __typename: 'StaffPosition', id: Types.Scalars['ID']['input'], staff: { __typename: 'Staff', id: Types.Scalars['ID']['input'], fullName: string } } }> } };


export const CreateConnectionRestrictionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateConnectionRestrictions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateConnectionRestrictionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createConnectionRestrictions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"restrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"service"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}},{"kind":"Field","name":{"kind":"Name","value":"connection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"staff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreateConnectionRestrictionsMutation, CreateConnectionRestrictionsMutationVariables>;