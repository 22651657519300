import { SubmitButton, SubmitButtonProps, TextInput } from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Entitlement, Meeting, PrivacyLevel } from "@src/api/graphql";
import FormDialog from "@src/lib/FormDialog";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import useBoolean from "@src/lib/useBoolean";
import useEntitlement from "@src/lib/useEntitlement";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AcceptMeetingDocument } from "./AcceptMeeting.generated";

type Props = Omit<SubmitButtonProps, "submitting" | "onClick"> & {
  meeting: Pick<Meeting, "id" | "privacyLevel">;
};

type FormData = {
  statusDetails: string;
};

export default function AcceptMeetingButton({ meeting, ...rest }: Props) {
  const canManageVisitorsAndMeetings = useEntitlement(
    Entitlement.ManageVisitorsAndMeetings,
  );
  const { t } = useTranslation();
  const enableApprovalNotes =
    meeting.privacyLevel === PrivacyLevel.UnmonitoredLogged;
  const handleApolloError = useApolloErrorHandler();

  const [acceptMeeting, { loading }] = useMutation(AcceptMeetingDocument, {
    onError: handleApolloError,
  });

  const dialog = useBoolean();

  const { handleSubmit, formState, control } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      statusDetails: "",
    },
  });

  const onAccept = async (extra?: { statusDetails: string }) =>
    acceptMeeting({
      variables: {
        input: {
          meetingId: meeting.id,
          statusDetails: extra?.statusDetails || undefined,
        },
      },
    });

  return (
    <>
      {dialog.current && (
        <FormDialog
          fullWidth
          maxWidth="md"
          formState={formState}
          title={t("Approve meeting")}
          saveText={t("Approve")}
          onCancel={dialog.disable}
          onSubmit={handleSubmit(onAccept)}
        >
          <TextInput
            control={control}
            name="statusDetails"
            label={t("Note to visitor (optional)")}
            sx={{ width: "100%" }}
            multiline
            rows={3}
          />
        </FormDialog>
      )}
      <SubmitButton
        submitting={loading}
        onClick={() => (enableApprovalNotes ? dialog.enable() : onAccept())}
        disabled={!canManageVisitorsAndMeetings}
        disabledReason={t("You do not have permission to approve meetings.")}
        {...rest}
      >
        {t("Accept")}
      </SubmitButton>
    </>
  );
}
