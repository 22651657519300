import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SubmitButton,
  TextInput,
  useSnackbarContext,
} from "@ameelio/ui";
import { useMutation } from "@apollo/client";
import { Box, DialogContentText } from "@mui/material";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import { isRequiredString } from "@src/lib/validate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RejectSystemVisitorDocument } from "./RejectSystemVisitor.generated";

type Props = {
  systemVisitorId: string;
  onClose: () => void;
  onSuccess: () => void;
};

type FormData = {
  reason: string;
};

export default function RejectVisitorIDDialog({
  systemVisitorId,
  onClose,
  onSuccess,
}: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      reason: "",
    },
  });
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const [rejectSystemVisitor] = useMutation(RejectSystemVisitorDocument, {
    onError: handleApolloError,
  });

  const doSubmit = async (data: FormData) => {
    const result = await rejectSystemVisitor({
      variables: {
        input: {
          systemVisitorId: systemVisitorId,
          statusDetails: t("ID rejected because: {{reason}}", {
            reason: data.reason.trim(),
          }),
        },
      },
    });
    if (!result.errors) {
      onSuccess();
      snackbarContext.alert("success", t("The ID has been rejected."));
    }
  };

  return (
    <Dialog
      title={t("Are you sure you want to reject the ID?")}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogContent>
        <DialogContentText sx={{ color: (th) => th.palette.error.main }}>
          {t(
            "This will cancel any upcoming meetings this person has. They will also need to submit a new ID for approval before they can schedule events or talk with residents.",
          )}
        </DialogContentText>
        <form onSubmit={handleSubmit(doSubmit)}>
          <Box mt={4} style={{ display: "flex", flexDirection: "column" }}>
            <TextInput
              control={control}
              name="reason"
              label={t("Your ID is being rejected because:")}
              rules={isRequiredString(t("Please provide a reason."))}
              multiline
              rows={2}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onClose}>
          {t("Cancel")}
        </Button>
        <SubmitButton
          disabled={!isValid}
          submitting={isSubmitting}
          onClick={handleSubmit(doSubmit)}
        >
          {t("Confirm")}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
