import { groupBy, sortByNumbers } from "@ameelio/core";
import { SelectInputBase } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Box, SelectChangeEvent } from "@mui/material";
import { MeetingStatus, MeetingType } from "@src/api/graphql";
import Alert from "@src/lib/Alert";
import { alphabetize, capitalize } from "@src/lib/Common";
import DateRangePicker from "@src/lib/DateRangePicker";
import getMeetingStatusLabelByType from "@src/lib/getMeetingStatusLabelByType";
import useApolloErrorHandler from "@src/lib/handleApolloError";
import featureForMeetingType, { meetingTypeTitle } from "@src/lib/meeting";
import ResponsiveColumns from "@src/lib/ResponsiveColumns";
import { useGuaranteedFacilityContext } from "@src/lib/SessionBoundary";
import stripInvalid from "@src/lib/stripInvalid";
import { endOfDay, startOfDay, startOfToday } from "date-fns";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ExportMeetingsButton from "src/lib/ExportMeetings";
import Header from "../../lib/Header";
import { GetUpcomingMeetingsDocument } from "./GetUpcomingMeetings.generated";
import ScheduledMeetingsTable from "./ScheduledMeetingsTable";

export type Filters = {
  scheduledStartAfter: number;
  scheduledStartBefore: number | undefined;
  meetingStatus: MeetingStatus[];
  meetingType: MeetingType[] | undefined;
};

const scheduledMeetingTypes: MeetingType[] = [
  MeetingType.VideoCall,
  MeetingType.InPersonVisit,
  MeetingType.Webinar,
];

const LIMIT = 100;

const { history } = window;

export default function UpcomingMeetingsPage() {
  const { facility } = useGuaranteedFacilityContext();
  const location = useLocation();
  const { t } = useTranslation();

  const groupedMeetingTypes = groupBy(scheduledMeetingTypes, (mt) =>
    facility.features.includes(featureForMeetingType(mt))
      ? "enabled"
      : "disabled",
  );
  const sortedMeetingTypes = [
    ...alphabetize(groupedMeetingTypes.enabled || []),
    ...alphabetize(groupedMeetingTypes.disabled || []),
  ];

  const ALL_STATUSES_FILTER = [MeetingStatus.Scheduled, MeetingStatus.Live];

  // load state from location (forward nav) and history (back nav)
  const [filters, setFilters] = useState<Filters>(() => {
    const stateSchema = Joi.object<{
      scheduledStartAfter?: number;
      scheduledStartBefore?: number;
      meetingStatus?: MeetingStatus[];
      meetingType?: MeetingType[];
    }>({
      scheduledStartAfter: Joi.number(),
      scheduledStartBefore: Joi.number(),
      meetingStatus: Joi.array().items(
        Joi.string().valid(...Object.values(MeetingStatus)),
      ),
      meetingType: Joi.array().items(
        Joi.string().valid(...Object.values(MeetingType)),
      ),
    });
    const historyState = stripInvalid(stateSchema, history.state);
    const locationState = stripInvalid(stateSchema, location.state);
    const initialState = { ...historyState, ...locationState };
    return {
      meetingStatus: initialState.meetingStatus
        ? initialState.meetingStatus
        : ALL_STATUSES_FILTER,
      scheduledStartAfter:
        initialState.scheduledStartAfter || startOfToday().getTime(),
      scheduledStartBefore: initialState.scheduledStartBefore,
      meetingType: initialState.meetingType
        ? initialState.meetingType
        : undefined,
    };
  });

  useEffect(() => {
    history.replaceState(filters, "");
  }, [filters]);

  const changeFilter = (newFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };
  const handleApolloError = useApolloErrorHandler();

  const { loading, data, refetch } = useQuery(GetUpcomingMeetingsDocument, {
    variables: {
      id: facility.id,
      limit: LIMIT,
      ...filters,
    },
    onError: handleApolloError,
  });
  const meetings = data
    ? sortByNumbers(
        data.facility.meetings.edges,
        (m) => m.node.interval.startAt,
      ).map((e) => e.node)
    : [];

  useEffect(() => {
    void refetch(filters);
  }, [refetch, filters]);

  return (
    <Box>
      <Header
        title={t("Upcoming meetings")}
        subtitle={t(
          "Review the upcoming schedule of visits, video calls, and webinars",
        )}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="flex-start"
        >
          <Box flex={1} sx={{ mb: { xs: 2, md: 0 } }}>
            <ResponsiveColumns>
              <SelectInputBase
                disabled={loading}
                size="small"
                aria-label={t("Filter by meeting type")}
                placeholder={t("Filter by meeting type")}
                label={t("Meeting type")}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const value = event.target.value;
                  changeFilter({
                    meetingType:
                      value && value !== "All"
                        ? [value as MeetingType]
                        : undefined,
                  });
                }}
                value={filters.meetingType?.[0] || "All"}
                items={[
                  { value: "All", name: t("All") },
                  ...sortedMeetingTypes.map((mt) => ({
                    value: mt,
                    name: meetingTypeTitle([mt]),
                    disabled: !facility.features.includes(
                      featureForMeetingType(mt),
                    ),
                  })),
                ]}
              />
              <DateRangePicker
                onStartDateChange={(date) => {
                  const newDate = date
                    ? new Date(date)
                    : startOfDay(new Date());
                  changeFilter({
                    scheduledStartAfter: newDate.getTime(),
                  });
                }}
                onEndDateChange={(date) => {
                  const newDate = date ? endOfDay(date) : null;
                  changeFilter({
                    scheduledStartBefore: newDate?.getTime(),
                  });
                }}
                disabled={loading}
                startDate={filters.scheduledStartAfter || null}
                endDate={filters.scheduledStartBefore || null}
              />
              <SelectInputBase
                size="small"
                disabled={loading}
                label={t("Status")}
                aria-label={t("Filter by status")}
                value={
                  filters.meetingStatus.length === 1
                    ? filters.meetingStatus
                    : "All"
                }
                onChange={(event) => {
                  setFilters((filters) => ({
                    ...filters,
                    meetingStatus:
                      event.target.value === "All"
                        ? ALL_STATUSES_FILTER
                        : ([event.target.value] as MeetingStatus[]),
                  }));
                }}
                items={[
                  {
                    value: "All",
                    name: t("All"),
                  },
                  ...ALL_STATUSES_FILTER.map((status) => ({
                    value: status,
                    key: status,
                    name: capitalize(
                      getMeetingStatusLabelByType(status).toLowerCase(),
                    ),
                  })),
                ]}
              />
            </ResponsiveColumns>
          </Box>
          <ExportMeetingsButton
            facility={facility}
            filters={filters}
            disabled={loading}
          >
            {t("Export data")}
          </ExportMeetingsButton>
        </Box>
      </Header>
      <Box p={3}>
        <ScheduledMeetingsTable meetings={meetings} loading={loading} />
        {data?.facility.meetings.pageInfo.hasNextPage && (
          <Alert severity="warning">
            {t(
              "Only the first {{limit}} results are shown. Please refine your search or export to view all results.",
              { limit: LIMIT },
            )}
          </Alert>
        )}
      </Box>
    </Box>
  );
}
